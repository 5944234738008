import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';
import { INITIAL_VALUE, ALIGN_LEFT, ReactSVGPanZoom, TOOL_NONE, fitToViewer, ALIGN_BOTTOM } from 'react-svg-pan-zoom';
import useContextMenu from './MenuContextHook';
import { updateSvgPartByKey, updateSvgPartByMulitpleKeys } from '../../axios/index'

import SvgCircle from './SvgCircle';
// import SvgZoom from "./SvgZoom"; 
import "./index.css";
function SvgViewer(props) {
  const { configId, moduleName } = useParams();
  const Viewer = useRef(null);
  const dispatch = useDispatch();
  const svgSource = useSelector((state) => state.svgViewerData)
  const { clicked, setClicked, points, setPoints } = useContextMenu();
  const [mousePos, setMousePos] = useState({});
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 })
  const [pathPoints, setPathPoints] = useState('');
  const [tool, setTool] = useState(TOOL_NONE)
  const [value, setValue] = useState(INITIAL_VALUE)
  const [svgWidthHeight, setSvgWidthHeight] = useState({ SVGWidth: window.innerWidth - 280, SVGHeight: window.innerHeight - 150 })
  const [svgConfigWidthHeight, setSvgConfigWidthHeight] = useState({ SVGWidth: window.innerWidth - 280, SVGHeight: window.innerHeight - 150 })
  
  
  const width = window.innerWidth - 280;
  const height = window.innerHeight - 150;
  const categories = useSelector((state) => state.partCategories)
  const selectedlayer = useSelector((state) => state.svgSelectedLayers)
  const parts = useSelector((state) => state.svgViewerPartData.data.configData.partData)
  const partsMetaData = useSelector((state) => state.svgViewerPartData.data.configData.metaData)



  useEffect(() => {

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener(
        'mousemove',
        handleMouseMove
      );
    };
  }, []);

  useEffect(() => {

    if (svgSource?.data?.assemblyData?.data?.ranges?.x?.max !== undefined) {
      let SVGHeight = svgSource.data.assemblyData.data.ranges.y.max - svgSource.data.assemblyData.data.ranges.y.min;
      let SVGWidth = svgSource.data.assemblyData.data.ranges.x.max - svgSource.data.assemblyData.data.ranges.x.min;
      setSvgWidthHeight({ SVGHeight: SVGHeight, SVGWidth: SVGWidth })
      centerAlignSvg('default');
    }    

  }, [svgSource]);

  useEffect(() => {
    if ((props.preview === false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer')){
    
    if (partsMetaData?.meshType !== undefined && partsMetaData.meshType!=='') {
      let tempRanges = JSON.parse(partsMetaData.meshType)
      let SVGHeight = tempRanges.y.max - tempRanges.y.min;
      let SVGWidth = tempRanges.x.max - tempRanges.x.min;
      setSvgConfigWidthHeight({ SVGHeight: SVGHeight, SVGWidth: SVGWidth })
      centerAlignSvg('default');
    }    
  }

  }, [props.preview]);


  


  const centerAlignSvg = (e) => {
    let tempValue;
    if (e === 'default') {
      tempValue = cloneDeep(Viewer.current.getValue());
    }
    else {
      tempValue = cloneDeep(e);
    }
    if ((props.preview === false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer')){
      if (partsMetaData?.meshType !== undefined && partsMetaData.meshType!=='') {
        let tempRanges = JSON.parse(partsMetaData.meshType)
        tempValue.SVGHeight = tempRanges.y.max - tempRanges.y.min;
        tempValue.SVGWidth = tempRanges.x.max - tempRanges.x.min;
        tempValue.e = 0;
        tempValue.f = 0;
        tempValue.a = 0;
        tempValue.d = 0;
        tempValue.SVGMinX = tempRanges.x.min;
        tempValue.SVGMinY = -Math.abs(tempRanges.y.max);
        setValue(fitToViewer(tempValue, ALIGN_LEFT, ALIGN_BOTTOM))
  
      }
    }
    else{
      if (svgSource?.data?.assemblyData?.data?.ranges?.x?.max !== undefined) {
        tempValue.SVGHeight = svgSource.data.assemblyData.data.ranges.y.max - svgSource.data.assemblyData.data.ranges.y.min;
        tempValue.SVGWidth = svgSource.data.assemblyData.data.ranges.x.max - svgSource.data.assemblyData.data.ranges.x.min;
        tempValue.e = 0;
        tempValue.f = 0;
        tempValue.a = 0;
        tempValue.d = 0;
        tempValue.SVGMinX = svgSource.data.assemblyData.data.ranges.x.min;
        tempValue.SVGMinY = -Math.abs(svgSource.data.assemblyData.data.ranges.y.max);
        setValue(fitToViewer(tempValue, ALIGN_LEFT, ALIGN_BOTTOM))
  
      }
    }
    //?svgConfigWidthHeight.SVGWidth:svgWidthHeight.SVGWidth} height={props.preview===false && moduleName === 'config-viewer'?svgConfigWidthHeight.SVGHeight:svgWidthHeight.SVGHeight}

    
  }







  const miniatureProps = { position: 'none' }

  const handelOriginPointRadius = () => {
    //--point-radius
    if (Viewer.current) {
      const viewerInstance = Viewer.current;
      const newRadius = 2 / viewerInstance.getValue().a;
      document.documentElement.style.setProperty('--point-radius', newRadius);
      document.documentElement.style.setProperty('--point-stroke', newRadius + 0.5);

    }
  };


  const calulateArea = (id) => {
    const path = document.getElementById(id);

    // Get the "d" attribute of the path, which contains the path data
    const pathData = path.getAttribute('d');
    // Parse the path data using regex to extract the coordinates
    const coordinatePairs = pathData.match(/[-+]?\d*\.\d+|[-+]?\d+/g);
    const xCoordinates = [];
    const yCoordinates = [];
    for (let i = 0; i < coordinatePairs.length; i += 2) {
      xCoordinates.push(parseFloat(coordinatePairs[i]));
      yCoordinates.push(parseFloat(coordinatePairs[i + 1]));
    }

    // Calculate the area using the Shoelace Algorithm
    let area = 0;
    const n = xCoordinates.length;
    for (let i = 0; i < n; i++) {
      const j = (i + 1) % n;
      area += xCoordinates[i] * yCoordinates[j];
      area -= xCoordinates[j] * yCoordinates[i];
    }
    //area = Math.abs(area) / 2;
    return area;

  }

  const handleMouseMove = (event) => {

  };


  const handleClick = (event) => {
    // layer selection
    event.stopPropagation();
    let keyboardKey = 'none'
    if (event.originalEvent.ctrlKey) {
      keyboardKey = 'ctrlKey';
    }
    else if (event.originalEvent.altKey) {
      keyboardKey = 'altKey';
    }
    if (event.originalEvent.target.nodeName === 'path') {

      props.handlelayerSelection(event.originalEvent.target, 'svgViewer', keyboardKey)


    }

  }
  const renderParts = () => {
    if (parts.length > 0 && props.showPoints === false) {
      let showOrigin = false;
      return parts.map((part, index) => {
        let cls = 'part'
        if (part.data.partStatus) {
          cls = 'part selected'
        }
        if (part.data.partVisibility && part.action.delete === false) {
          let hole = '';
          let color = part.data.partType === '' ? 'rgb(9 109 217 / 63%)' : renderColor(part.data.partType);

          if (part.data.partHoleReference.length > 0) {
            part.data.partHoleReference.map((tempHole, tempHoleIndex) => {
              if ((props.preview === true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer')) {
                hole = tempHoleIndex === 0 ? hole + tempHole.partSourceSvgPath : hole + 'z' + tempHole.partSourceSvgPath;
              }
              else {
                hole = tempHoleIndex === 0 ? hole + tempHole.partTransformedSvgPath : hole + 'z' + tempHole.partTransformedSvgPath;
              }

            })
          }
          if (part.data.partSourceSvgPath.trim() !== '') {
            let tempPath = '';
            if ((props.preview === true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer')) {

              showOrigin = true;
              tempPath = part.data.partSourceSvgPath;
            }
            else {

              tempPath = part.data.partTransformedSvgPath;
            }
            if (hole.trim() !== '') {
              tempPath = tempPath + 'z' + hole + 'z'
            }
            else {
              tempPath = tempPath + 'Z'
            }
            return (<g className={cls} id='uio' fill={color} stroke='blue' cursor="default" pointerEvents="all" >
              <path data-pathType='part' data-parentIndex={index} data-childIndex={-1} vectorEffect="non-scaling-stroke" cursor="pointer" strokeWidth="2" d={tempPath} />
              {showOrigin === true && part.data.partStatus === true &&
                <circle fill="#ff0000" stroke="none" cx={part.data.partCalculatedOrigin.x} cy={part.data.partCalculatedOrigin.y} r="5">
                  <animate attributeName="opacity" dur="2s" values="0.5;1;0.5;" repeatCount="indefinite" begin="0.1" />
                </circle>
              }
            </g>)
          }
        }
      })
    }
  }
  const handelOriginSelection = (e, index, selText, type) => {
    if (type === 'reset') {
      let newdataSet = {
        partOriginMetaData: [],
        partCalculatedOrigin: { x: 0, y: 0 }
      }
      dispatch(updateSvgPartByMulitpleKeys({ index: index, data: newdataSet }))
    }
    else {
      props.setPoints(e, index, selText)
    }
  }
  const renderCategeories = () => {
    if (categories.loading === false && categories.data.length > 0) {
      return categories.data.map((item, index) => {
        return (
          <option key={item.id} value={item.id}>{item.name}</option>
        )
      })
    }

  }
  const handelPartSelections = (part, index, key, val) => {
    if (key === 'partDirection') {
      props.reCalulateTransformation(index, val, key)
    }
    else {
      dispatch(updateSvgPartByKey({ index: index, key: key, data: val }))
    }
  }
  const renderColor = (type) => {
    let color = '(9 109 217 / 63%)'
    if (categories.loading === false && categories.data.length > 0) {
      categories.data.map((item, index) => {
        if (type == item.id) {

          color = item.color
        }
      })
    }
    color = 'rgb' + color;
    return color;
  }

  const renderMetaDataPropertyPane = () => {
    if (parts.length > 0) {
      let selText = props.showPoints === true ? 'Set' : 'Select'
      return parts.map((part, index) => {
        if (part.data.partStatus === true && part.data.partVisibility !== false) {
          let color = renderColor(part.data.partType);
          // let color ='#f9f9f9'

          return (
            <div className='propertyPane'>
              <div>
                <span className='label'>Part ID</span>
                <input type='text' className='form-control' disabled value={'part ' + index} />
              </div>
              <div>
                <span className='label'>Part Type</span>
                <select className='form-control' onChange={(e) => handelPartSelections(part, index, 'partType', e.target.value)} value={part.data.partType}>
                  <option>Select</option>
                  {renderCategeories()}
                </select>
              </div>
              <div>
                <span className='label'>Part Color</span>
                <input style={{ 'background': color }} className='form-control' readOnly={true} />
              </div>

              <div>
                <span className='label' >Part Name</span>
                <input type='text' className='form-control' value={part.data.partName} onChange={(e) => handelPartSelections(part, index, 'partName', e.target.value)} />
              </div>
              <div>
                <span className='label' >Z Value</span>
                <input type='number' className='form-control' value={part.data.partZ} onChange={(e) => handelPartSelections(part, index, 'partZ', e.target.value)} />
              </div>
              {/* <div>
          <span className='label'>Direction</span>
           <select className='form-control' >
             <option>Clockwise</option>
             <option>Anti-Clockwise</option>
           </select>
          </div> */}
              <div>
                <span className='label'>Area</span>
                <input type='text' value={part.data.partArea} className='form-control' readOnly={true} />
              </div>
              <div>
                <span className='label'>Description</span>
                <input type='text' className='form-control' value={part.data.partDescription} onChange={(e) => handelPartSelections(part, index, 'partDescription', e.target.value)} />
              </div>
              <div>
                <span className='label'>Product Front</span>
                <select className='form-control' value={part.data.partDirection} onChange={(e) => handelPartSelections(part, index, 'partDirection', e.target.value)}  >
                  <option value='negativeX' key='negativeX'>Left</option>
                  <option value='positiveX' key='positiveX'>Right</option>
                  <option value='positiveY' key='positiveY'>Up</option>
                  <option value='negativeY' key='negativeY'>Down</option>
                </select>
              </div>

              <div>
                <span className='label'>Origin</span>
                <div className='origin'>
                  <div>X -{part.data.partCalculatedOrigin.x}</div>
                  <div>y -{part.data.partCalculatedOrigin.y}</div>
                  {((props.preview === true && moduleName === 'config-viewer') || (props.preview === false && moduleName === 'assembly-viewer')) &&
                    <div className='resetOrigin'>
                      {(part.data.partOriginMetaData.length !== 0) &&
                        <button onClick={(e) => handelOriginSelection(e, index, selText, 'reset')}>Reset</button>
                      }
                      <button onClick={(e) => handelOriginSelection(e, index, selText, 'select')}>{selText}</button>
                    </div>


                  }
                </div>
              </div>
            </div>
          )
        }
      })

    }
  }

  const handelValChange = (e) => {
    setValue(e)

    if (e.e === 0 && e.f === 0) {
      centerAlignSvg(e)
    }
  }

  return (
    <div className="svgContainer"
      onContextMenu={(e) => {
        e.preventDefault();
        if (Object.keys(selectedlayer.data).length > 0) {
          setClicked(true);
          setPoints({
            x: e.pageX,
            y: e.pageY,
          });
        }
      }}
    >

      {renderMetaDataPropertyPane()}

      {clicked && (
        <div className='menuContextContainer' style={{ top: points.y + 'px', left: points.x + 'px' }}>
          <ul>
            <li onClick={(e) => props.createPart('part', null)}>Create Part</li>
            {
              parts.length > 0 && (
                <li onClick={(e) => props.createPart('hole', null)}>Create a Hole</li>
              )
            }
          </ul>
        </div>
      )}
      {/* (X:{mousePos.x}, Y:{mousePos.y}) */}
      {/* <button className="btn" onClick={(e) => centerAlignSvg(e)}>Fit (mode 2)</button> */}

      <ReactSVGPanZoom
        ref={Viewer}

        width={width} height={height}
        tool={tool} onChangeTool={setTool}
        value={value} onChangeValue={e => handelValChange(e)}
        miniatureProps={miniatureProps}
        onZoom={e => handelOriginPointRadius()}
        // onPan={e => console.log('pan')}
        onMouseMove={e => handleMouseMove(e)}
        background={"#000"}
        SVGBackground={"transparent"}
        className='svgViewer'
        preventPanOutside={false}


        detectAutoPan={false}
        scaleFactorMin={0.0001}
        // miniatureProps={{
        //   position: 'right'
        // }}
        toolbarProps={{
          position: 'right'
        }}
        style={{
          margin: 'auto'
        }}
        onClick={event => handleClick(event)}
        SVGStyle={{ width: '50%', marginRight: 0 }}
      >

        <svg id='mySvg' transform="scale(1, -1)"
          xmlns="http://www.w3.org/2000/svg"
          width={(props.preview===false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer') ?svgConfigWidthHeight.SVGWidth:svgWidthHeight.SVGWidth} height={(props.preview===false && moduleName === 'config-viewer') || (props.preview === true && moduleName === 'assembly-viewer')?svgConfigWidthHeight.SVGHeight:svgWidthHeight.SVGHeight}
        >
          <defs>
            <marker
              id="dot"
              viewBox="0 0 10 10"
              refX="5"
              refY="5"
              markerWidth="5"
              markerHeight="5">
              <circle cx="5" cy="5" r="5" fill="#fff" />
            </marker>
            <marker
              id="arrow"
              viewBox="0 0 10 10"
              refX="5"
              refY="5"
              markerWidth="6"
              markerHeight="6"
              orient="auto-start-reverse">
              <path d="M 0 0 L 10 5 L 0 10 z" fill='#fff' />
            </marker>
          </defs>
          <style>
            {`
          /* Define your CSS rules here */
          g.selected path{
            marker: url(#arrow);
          }
        `}
          </style>
          <g transform="scale(1, -1)">

            {props.paths}
            {renderParts()}
          </g>
        </svg>
      </ReactSVGPanZoom>


    </div>
  );
};

export default SvgViewer;
