import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getCalculatorReferenceData} from '../axios/index'  
import DataGrid from "../components/tables/dataGrid" 
import DefaultBreadCrumb from "../components/breadcrumb"
import PageHeader from "../components/header/pageHeader"



const ToolOverview = () => {
    const dispatch = useDispatch();  
    return (
        <div className="main-panel ps">
            <div className='content padd-top'>
                  <iframe src="https://api.dev.nimblelab.app.kimclark.com/static/help.htm" style={{width:'100%',  height:'80vh'}}/>
             </div>        
     </div>
    );
}

export default ToolOverview;