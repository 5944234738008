import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import layerPic from "../../components/header/layersPic.png";
import SvgComponent from "../../icons/svgComponent";
import "./index.css";

const HomeContent = () => {
  return (
    <div>
      <div className="row details">
        <div
          className="col-6 pt-1"
          // style={{ paddingTop: "25px" }}
        >
          <div
            className="details-col"
            // size={"large"}
            // style={{ paddingLeft: "20px" }}
          >
            <div className="title">
              <div>Product</div>
              <div>Modeling Resources</div>
            </div>
            <div style={{ fontSize: "18px", padding: "0 15px" }}>
              <span>
                Use links below to access the absorbency material database as well as the product geometry database.
                These tools provide access to previously created components for product simulations.
              </span>
              <div style={{ textAlign: "center", paddingTop: "42px" }}>
                <div className="buttons">
                  <Link className="nav-link" to="/geometry" exact>
                    <Button
                      type="primary"
                      shape="round"
                      icon={
                        <SvgComponent
                          type={"geometry"}
                          width={"15px"}
                          customClass={"headerIcon"}
                          height={"22px"}
                          background={"transprent"}
                        />
                      }
                      size={"large"}
                    >
                      Geometry Database
                    </Button>
                  </Link>
                  <Link className="nav-link" to="/material-database" exact>
                    <Button
                      style={{ color: "blue" }}
                      shape="round"
                      icon={
                        <SvgComponent
                          type={"material-db"}
                          width={"15px"}
                          customClass={"headerIcon"}
                          height={"22px"}
                          background={"transprent"}
                        />
                      }
                      size={"large"}
                    >
                      Material Database
                    </Button>
                  </Link>
                  {process.env.REACT_APP_BACKEND_API_URL.includes("api.dev") ||
                  process.env.REACT_APP_BACKEND_API_URL.includes("localhost") ? (
                    <Link className="nav-link" to="/mdm-poc" exact>
                      <Button
                        style={{ color: "blue" }}
                        shape="round"
                        icon={
                          <SvgComponent
                            width={"15px"}
                            customClass={"headerIcon"}
                            height={"22px"}
                            background={"transprent"}
                          />
                        }
                        size={"large"}
                      >
                        MDM-POC
                      </Button>
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <img src={layerPic} alt="Layers Pic" />
        </div>

        {/* <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 0,
            right: 0,
            paddingBottom: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        > */}
      </div>

      <footer class="footer mt-auto py-3 bg-body-tertiary">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SvgComponent
            type={"horn"}
            width={"22px"}
            customClass={"headerIcon"}
            height={"22px"}
            background={"transprent"}
          />

          <span>Mesh Database 1.2 is out! See</span>
          <Button type="link" style={{ paddingLeft: "5px" }}>
            What's new
          </Button>
        </div>
      </footer>
    </div>
  );
};
export default HomeContent;
