import React, { useEffect, useState } from "react";
import { Modal, Tag } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductDBScreenElements,
  getSourceJsonTableData,
  getUploadScreenElements,
  getFileUploadOptions,
  getConfigurationTableData,
} from "../axios/index";
import GenericTable from "../components/tables/genericTable";
import TableHeader from "../components/header/tableHeader";
import SvgComponent from "../icons/svgComponent";
import DefaultBreadCrumb from "../components/breadcrumb";
import { getSearchFilterData } from "../components/utils/tableUtils";
import { formatDate } from "../components/utils/dateUtils";
import { sortColumn } from "../components/utils/tableUtils";
import { getStatusColor } from "../components/utils/statusUtils";
import DeleteConfigModal from "../components/modals/deleteConfigModal";
import DeleteContainerModal from "../components/modals/deleteContainerModal";
import KnowMoreModal from "../components/modals/knowMoreModal";
import UploadFileModal from "../components/modals/uploadFileModal";
import DeleteAssemblyModal from "../components/modals/deleteAssemblyModal";

//Geometry screen
const Geometry = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.userInfo);

  const screenDetails = useSelector((state) => state?.ProductDBScreenData);
  const assemblyJsonTableData = useSelector(
    (state) => state?.AssemblyJsonTableData
  );

  const configTableData = useSelector((state) => state?.ConfigTableData);

  const [assemblyJsonData, setassemblyJsonData] = useState([]);
  const [configData, setConfigData] = useState([]);
  const [configurationTableColumns, setConfigurationTableColumns] = useState(
    []
  );
  const [sourceJsonTableColumns, setSourceJsonTableColumns] = useState([]);
  const [selectedFileType, setSelectedFileType] = useState("config");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteAssemblyModalOpen, setIsDeleteAssemblyModalOpen] =
    useState(false);
  const [isKnowMoreOpen, setIsKnowMoreOpen] = useState(false);
  const [selectedKnowMore, setSelectedKnowMore] = useState({});
  const [isDeleteContainerModalOpen, setIsDeleteContainerModalOpen] =
    useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [selectedDeleteAssemblyItem, setSelectedDeleteAssemblyItem] = useState(
    {}
  );
  const [selectedDeletedContainer, setSelectedDeletedContainer] = useState({});

  const [isMyFiles, setIsMyFiles] = useState(false);

  const genericTerms = {
    config: {
      icon: "GeoConfigIcon",
      checkBoxTitle: "My Configuration Files",
      name: "Configuration Files",
    },
    source: {
      icon: "GeoFileIcon",
      checkBoxTitle: "My Source Files",
      name: "Source Files",
    },
    JSON: {
      icon: "GeoAssyIcon",
      checkBoxTitle: "My Assembly Files",
      name: "Assembly Files",
    },
  };
  //Get api hit for all columns and dropdown details fetch
  useEffect(() => {
    dispatch(getProductDBScreenElements());
  }, []);

  // Table 1 columns set
  useEffect(() => {
    let data = screenDetails?.data?.associatedValues?.table1Headers;

    if (data) {
      let columns = data?.map((value) => {
        if (value?.attributeName === "configId") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              return <Link to={`/geometry/config-viewer/${text}`}>{text}</Link>;
            },
          };
        } else if (value?.attributeName === "assemblyID") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              return (
                <a
                  href={`${process.env.REACT_APP_BACKEND_API_URL}/api/download/assembly?assemblyID=${text}`}
                  target="_blank"
                >
                  {/* <button
                    type="button"
                    className="btn btn-link btn23 link-btn"
                    data-toggle="modal"
                    data-target="#exampleModal2"
                  > */}
                  {text}{" "}
                  <i className="now-ui-icons arrows-1_cloud-download-93"></i>
                  {/* </button> */}
                </a>
              );
            },
          };
        } else if (value?.displayName === "Actions") {
          return {
            key: value?.attributeName,
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (record) => {
              // console.log(
              //   `${process.env.REACT_APP_BACKEND_API_URL}/api/download/config?configFileID=${record?.configFileID}`
              // );
              return (
                <div style={{ display: "flex" }}>
                  <div
                    onClick={() => {
                      setSelectedDeleteItem(record);
                      setIsDeleteModalOpen(true);
                    }}
                    data-toggle="tooltip"
                    title="Delete"
                  >
                    <SvgComponent
                      type={"delete"}
                      width={"20px"}
                      customClass={"headerIcon"}
                      height={"20px"}
                      background={"transprent"}
                    />
                  </div>

                  {record?.status === "draft" ? (
                    <SvgComponent
                      type={"editPencil"}
                      width={"20px"}
                      customClass={"headerIcon"}
                      height={"20px"}
                      background={"transprent"}
                    />
                  ) : (
                    <a
                      href={`${process.env.REACT_APP_BACKEND_API_URL}/api/download/config?configId=${record?.configId}`}
                      target="_blank"
                    >
                      <div data-toggle="tooltip" title="Download">
                        <SvgComponent
                          type={"download"}
                          width={"20px"}
                          customClass={"headerIcon"}
                          height={"20px"}
                          background={"transprent"}
                        />
                      </div>
                    </a>
                  )}
                </div>
              );
            },
          };
        } else if (value?.attributeName === "createdAt") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              let date = formatDate(text);
              return <div>{date}</div>;
            },
          };
        } else if (value?.attributeName === "status") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              return <Tag color={getStatusColor(text)}>{text}</Tag>;
            },
          };
        }

        return {
          key: value?.attributeName,
          type: value?.type,
          sorter: sortColumn(value),
          dataIndex: value?.attributeName,
          title: value?.displayName,
        };
      });
      setConfigurationTableColumns(columns);
    }
  }, [screenDetails?.data]);

  // Table 2 columns set
  useEffect(() => {
    let data = screenDetails?.data?.associatedValues?.table2Headers;
    if (data) {
      if (selectedFileType === "source") {
        data = data[0]["Source Files"];
      } else {
        data = data[1]["Assembly Files"];
      }
      let columns = data?.map((value) => {
        if (value?.attributeName === "sourceContainerID") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => <Link to={`/geometry/${text}`}>{text}</Link>,
          };
        } else if (value?.attributeName === "assemblyID") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text, record) => {
              if (record?.status === "Generated") {
                return (
                  <Link to={`/geometry/assembly-viewer/${text}`}>{text}</Link>
                );
              } else {
                return text;
              }
            },
          };
        } else if (value?.attributeName === "sourceID") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            width: "20px",
            ellipsis: true,
            render: (text) => {
              return (
                <div
                  style={{
                    maxWidth: "170px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {text}
                </div>
              );
            },
          };
        } else if (
          value?.attributeName === "lastModifiedAt" ||
          value?.attributeName === "createdAt"
        ) {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              let date = formatDate(text);
              return <div>{date}</div>;
            },
          };
        } else if (value?.attributeName === "status") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              return <Tag color={getStatusColor(text)}>{text}</Tag>;
            },
          };
        } else if (value?.displayName === "Action") {
          return {
            key: value?.attributeName,
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (record) => {
              return (
                <div>
                  {record?.fileCount === 0 ? (
                    <div
                      // type="button"
                      // className="btn btn-outline-danger p-1 delete-btn"
                      // data-toggle="modal"
                      // data-target="#exampleModal2"
                      onClick={() => {
                        setSelectedDeletedContainer(record);
                        setIsDeleteContainerModalOpen(true);
                      }}
                      data-toggle="tooltip"
                      title="Delete"
                    >
                      {/* <span>Delete</span> */}
                      {/* {}{" "} */}
                      <SvgComponent
                        type={"delete"}
                        width={"20px"}
                        customClass={"headerIcon"}
                        height={"20px"}
                        background={"transprent"}
                      />
                    </div>
                  ) : (
                    "-NA-"
                  )}
                </div>
              );
            },
          };
        } else if (value?.displayName === "Actions") {
          return {
            key: value?.attributeName,
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (record) => {
              switch (record?.status) {
                case "Failed":
                  return (
                    <div className="d-flex">
                      <div
                        onClick={() => {
                          setSelectedDeleteAssemblyItem(record);
                          setIsDeleteAssemblyModalOpen(true);
                        }}
                        data-toggle="tooltip"
                        title="Delete"
                      >
                        <SvgComponent
                          type={"delete"}
                          width={"20px"}
                          customClass={"headerIcon"}
                          height={"20px"}
                          background={"transprent"}
                        />
                      </div>
                      <div
                        onClick={() => {
                          setIsKnowMoreOpen(true);
                          setSelectedKnowMore(record);
                        }}
                      >
                        Know More
                      </div>
                    </div>
                  );
                case "Generated":
                  return (
                    <div className="d-flex">
                      <div
                        onClick={() => {
                          setSelectedDeleteAssemblyItem(record);
                          setIsDeleteAssemblyModalOpen(true);
                        }}
                        data-toggle="tooltip"
                        title="Delete"
                      >
                        <SvgComponent
                          type={"delete"}
                          width={"20px"}
                          customClass={"headerIcon"}
                          height={"20px"}
                          background={"transprent"}
                        />
                      </div>

                      <a
                        href={`${process.env.REACT_APP_BACKEND_API_URL}/api/download/assembly?assemblyID=${record?.assemblyID}`}
                        target="_blank"
                      >
                        <div data-toggle="tooltip" title="Download">
                          <SvgComponent
                            type={"download"}
                            width={"20px"}
                            customClass={"headerIcon"}
                            height={"20px"}
                            background={"transprent"}
                          />
                        </div>
                      </a>
                    </div>
                  );
                case "Generating":
                  return "-NA-";

                default:
                  return null;
              }
            },
          };
        }
        return {
          key: value?.attributeName,
          type: value?.type,
          sorter: sortColumn(value),
          dataIndex: value?.attributeName,
          title: value?.displayName,
        };
      });
      setSourceJsonTableColumns(columns);
    }
  }, [selectedFileType, screenDetails?.data]);

  // Table 1 data set
  useEffect(() => {
    filterConfigTable();
    // setConfigData(configTableData?.data ? configTableData?.data : []);
  }, [configTableData]);

  //Set Data for table 2
  useEffect(() => {
    filterAssemblyJsonTable();
    // setassemblyJsonData(
    //   assemblyJsonTableData?.data ? assemblyJsonTableData?.data : []
    // );
  }, [assemblyJsonTableData]);

  function getTableData() {
    if (selectedFileType === "config") {
      dispatch(getConfigurationTableData());
    } else {
      dispatch(
        getSourceJsonTableData({
          selectedFileType: selectedFileType,
        })
      );
    }
  }
  // Dispatch when dropdown value change on table 2
  useEffect(() => {
    getTableData();
  }, [selectedFileType]);

  function filterConfigTable() {
    let filterData;
    if (isMyFiles) {
      filterData = configData?.filter(
        (value) => value.lastModifiedBy === userInfo?.email
      );
    } else {
      filterData = configTableData?.data ? configTableData?.data : [];
    }

    setConfigData(filterData);
  }

  // Filter my data when my file clicked
  useEffect(() => {
    if (selectedFileType === "config") {
      let filterData;
      if (isMyFiles) {
        filterData = configData?.filter(
          (value) => value.lastModifiedBy === userInfo?.email
        );
      } else {
        filterData = configTableData?.data ? configTableData?.data : [];
      }
      setConfigData(filterData);
      setassemblyJsonData([]);
    } else {
      filterAssemblyJsonTable();
    }
  }, [isMyFiles]);

  // Filter my data when my Config file clicked
  // useEffect(() => {}, [isMyConfigFiles]);

  const renderDropdown = () => {
    return (
      <div class="dropdown">
        <a
          className="nav-link dropdown-toggle"
          //   href="#"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {genericTerms[selectedFileType]?.name}
        </a>

        <ul class="dropdown-menu">
          {screenDetails?.data?.associatedValues?.dropDown2Options?.map(
            (item) => {
              return (
                <li onClick={() => setSelectedFileType(item?.attributeName)}>
                  <p>
                    <span className="d-md-block">{item?.displayName}</span>
                  </p>
                </li>
              );
            }
          )}
        </ul>
      </div>
    );
  };

  function filterAssemblyJsonTable() {
    let filterData;
    if (isMyFiles) {
      filterData =
        Array.isArray(assemblyJsonTableData?.data) &&
        assemblyJsonTableData?.data?.filter(
          (value) =>
            value[
              selectedFileType == "source" ? "lastModifiedBy" : "createdBy"
            ] === userInfo?.email
        );
    } else {
      filterData = assemblyJsonTableData?.data
        ? assemblyJsonTableData?.data
        : [];
    }

    setassemblyJsonData(filterData);
  }

  const handleSearchAssembly = (searchText) => {
    let data = screenDetails?.data?.associatedValues?.table2Headers;

    if (data) {
      if (selectedFileType === "source") {
        data = data[0]["Source Files"];
      } else {
        data = data[1]["Assembly Files"];
      }
    }

    let keysData = data?.filter((item) => {
      if (item?.shouldSearch) {
        return item?.attributeName;
      } else {
        return null;
      }
    });
    let valuesData = assemblyJsonTableData?.data;

    let filterData = getSearchFilterData(valuesData, keysData, searchText);
    setassemblyJsonData(filterData || []);
  };

  const handleSearchConfig = (searchText) => {
    let data = screenDetails?.data?.associatedValues?.table1Headers;
    let keysData = data?.filter((item) => {
      if (item?.shouldSearch) {
        return item?.attributeName;
      } else {
        return null;
      }
    });
    let valuesData = configTableData?.data;

    let filterData = getSearchFilterData(valuesData, keysData, searchText);

    setConfigData(filterData || []);
  };

  const handleMyFiles = (check) => {
    setIsMyFiles(check);
  };

  // const handleMyConfigFiles = (check) => {
  //   setIsMyFiles(check);
  // };

  return (
    <div className="main-panel ps">
      {Object.keys(screenDetails?.data).length > 0 && (
        <div className="content padd-top">
          <div className="row">
            <DefaultBreadCrumb
              data={[
                { title: "Home", path: "/" },
                { title: "Geometry", path: "/geometry", active: true },
              ]}
            />
          </div>
          {/* {selectedFileType === "config" ? (
            <div>
              <div className="row">
                <TableHeader
                  iconType={"GeoConfigIcon"}
                  title={" Product geometries - Configuration Files"}
                  checkBoxTitle={"My Configuration Files"}
                  checkBoxValue={isMyConfigFiles}
                  buttonTitle={"New Configuration File"}
                  onButtonClick={() => {
                    navigate("/svg");
                  }}
                  handleSearch={handleSearchConfig}
                  handleMyFiles={handleMyConfigFiles}
                />
              </div>
              <div className="row">
                <GenericTable
                  columns={configurationTableColumns}
                  isLoading={configTableData?.loading}
                  data={configData}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="row">
                <TableHeader
                  iconType={genericTerms[selectedFileType]?.icon}
                  title={" Product geometries "}
                  path="new-material"
                  checkBoxTitle={
                    selectedFileType === "source"
                      ? "My Source Files"
                      : "My Assembly Files"
                  }
                  checkBoxValue={isMyAssembly}
                  renderElement={renderDropdown}
                  buttonTitle={"Upload New Source File"}
                  onButtonClick={() => {
                    setIsModalOpen(true);
                    dispatch(getFileUploadOptions());
                  }}
                  handleSearch={handleSearchAssembly}
                  handleMyFiles={handleMyFiles}
                />
              </div>
              <div className="row">
                <GenericTable
                  isLoading={assemblyJsonTableData?.loading}
                  columns={sourceJsonTableColumns}
                  data={assemblyJsonData}
                />
              </div>
            </div>
          )} */}

          <div className="row">
            <TableHeader
              iconType={genericTerms[selectedFileType]?.icon}
              title={" Product geometries "}
              checkBoxTitle={genericTerms[selectedFileType]?.checkBoxTitle}
              checkBoxValue={isMyFiles}
              renderElement={renderDropdown}
              buttonTitle={"Upload New Source File"}
              onButtonClick={() => {
                setIsModalOpen(true);
              }}
              handleSearch={
                selectedFileType === "config"
                  ? handleSearchConfig
                  : handleSearchAssembly
              }
              handleMyFiles={handleMyFiles}
            />
          </div>
          <div className="row">
            <GenericTable
              isLoading={
                selectedFileType === "config"
                  ? configTableData?.loading
                  : assemblyJsonTableData?.loading
              }
              columns={
                selectedFileType === "config"
                  ? configurationTableColumns
                  : sourceJsonTableColumns
              }
              data={
                selectedFileType === "config" ? configData : assemblyJsonData
              }
            />
          </div>
        </div>
      )}

      {isDeleteModalOpen && (
        <DeleteConfigModal
          isModalOpen={isDeleteModalOpen}
          setIsModalOpen={setIsDeleteModalOpen}
          selectedDeleteItem={selectedDeleteItem}
          refetchApi={() => {
            getTableData();
          }}
        />
      )}

      {isDeleteAssemblyModalOpen && (
        <DeleteAssemblyModal
          isModalOpen={isDeleteAssemblyModalOpen}
          setIsModalOpen={setIsDeleteAssemblyModalOpen}
          selectedDeleteItem={selectedDeleteAssemblyItem}
          refetchApi={() => {
            getTableData();
          }}
        />
      )}

      {isDeleteContainerModalOpen && (
        <DeleteContainerModal
          isModalOpen={isDeleteContainerModalOpen}
          setIsModalOpen={setIsDeleteContainerModalOpen}
          selectedDeleteItem={selectedDeletedContainer}
          refetchApi={() => {
            getTableData();
          }}
        />
      )}

      <UploadFileModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refetchApi={() => {
          // getTableData();
          setSelectedFileType("source");
          dispatch(
            getSourceJsonTableData({
              selectedFileType: "source",
            })
          );
          dispatch(getFileUploadOptions());
        }}
      />

      {isKnowMoreOpen && (
        <KnowMoreModal
          isModalOpen={isKnowMoreOpen}
          setIsModalOpen={setIsKnowMoreOpen}
          selectedItem={selectedKnowMore}
        />
      )}
    </div>
  );
};

export default Geometry;
