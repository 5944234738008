import {
  DragOutlined,
  ExclamationCircleTwoTone,
  LineChartOutlined,
  RetweetOutlined,
  SaveOutlined,
  ScissorOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  message,
  notification,
  Result,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { initDB, useIndexedDB } from "react-indexed-db-hook";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { calculateAverage, getMaterialCurationById, uploadFinalJSON } from "../../axios";
import { ChartLineIcon } from "../../icons/svgComponent";
import Chart from "./Chart";
import { DBConfig } from "./dbConfig";

const { CheckableTag } = Tag;

initDB(DBConfig);

function Curation() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const {
    add: addAction,
    deleteRecord: deleteAction,
    getAll: getAllActions,
    clear: clearActions,
  } = useIndexedDB("graph_actions");

  const userInfo = useSelector((state) => state.userInfo);

  const smooth = true;
  const clip = false;
  const defaultColor = "070808"; // black
  const loadColor = "#FF0000"; // red
  const unloadColor = "#65B741"; // green
  const excludeColor = "#cccccc"; // grey
  const colors = useMemo(
    () => [
      "#0000FF", // Blue
      "#FFA500", // Orange
      "#800080", // Purple
      "#00FFFF", // Cyan
      "#543310", // brown
    ],
    []
  );
  const axisOptions = [
    { label: "Strain - Stress (kPa)", value: "STRAIN_STRESS" },
    { label: "Crosshead (cm) - Load (gf)", value: "CROSSHEAD_LOAD" },
  ];
  const [loading, setLoading] = useState(false);
  const [isValidID, setIsValidID] = useState(true);
  const [metadata, setMetadata] = useState({});
  const [graphData, setGraphData] = useState({
    STRAIN_STRESS: [],
    CROSSHEAD_LOAD: [],
  });
  const [dataPts, setDataPts] = useState([]);
  const [legendNames, setLegendNames] = useState([]);
  const [exCurves, setExCurves] = useState([]);
  const [axes, setAxes] = useState("STRAIN_STRESS");
  const [clipEnabled, setClipEnabled] = useState(false);
  const [moveOriginEnabled, setMoveOriginEnabled] = useState(false);
  const [disableUndo, setDisableUndo] = useState(true);
  const [isAverageCurvePresent, setIsAverageCurvePresent] = useState(false);
  const [avgCurveData, setAvgCurveData] = useState([]);

  const convertGraphDataToOriginalJSONFormat = (forAverage = false) => {
    const filteredDataPoints = dataPts.filter((item) => legendNames.includes(item.name) && item.data.length > 0);

    if (forAverage) {
      const specimen = filteredDataPoints.map((item) => {
        return {
          Stress_kPa: item.data.map((d) => d[1]),
          Strain: item.data.map((d) => d[0]),
        };
      });

      return specimen;
    }

    const specimen = filteredDataPoints.map((item, index) => {
      return {
        Stress_kPa: item.data.map((d) => d[1]),
        Strain: item.data.map((d) => d[0]),
        Load_gf: graphData.CROSSHEAD_LOAD[index].map((d) => d[1]),
        Crosshead_cm: graphData.CROSSHEAD_LOAD[index].map((d) => d[0]),
      };
    });

    return specimen;
  };

  const convertRawJsonToGraphArray = (rawJson) => {
    const strainStress = rawJson.map((data) => {
      return data.Stress_kPa.map((y, i) => [data.Strain[i], y]);
    });
    const crossheadLoad = rawJson.map((data) => {
      return data.Load_gf.map((y, i) => [data.Crosshead_cm[i], y]);
    });

    return {
      STRAIN_STRESS: strainStress,
      CROSSHEAD_LOAD: crossheadLoad,
    };
  };

  const convertAvgJsonToGraphArray = (data) => {
    const load = data.loadStress.map((y, i) => [data.loadStrain[i], y]);
    const unload = data.unloadStress.map((y, i) => [data.unloadStrain[i], y]);

    return {
      LOAD: load,
      UNLOAD: unload,
    };
  };

  const generateDataPoints = useCallback(
    (dataSource) => {
      const series = dataSource.map((data, index) => {
        return {
          name: "Spec " + (index + 1),
          type: "line",
          smooth,
          clip,
          data: data,
          itemStyle: {
            color: colors[index] || defaultColor,
            opacity: 0.75,
          },
          lineStyle: {
            width: 1,
          },
        };
      });
      setDataPts(series);

      setLegendNames(series.map((s) => s.name));
    },
    [colors]
  );

  const getData = useCallback(() => {
    setLoading(true);
    dispatch(getMaterialCurationById(id))
      .then((response) => {
        setLoading(false);
        if (response?.data?.responseData) {
          setMetadata(response.data.responseData.dataValues);

          const specimenStressStrain = response.data.responseData.rawJSON.specimenStressStrain;
          const graphObject = convertRawJsonToGraphArray(specimenStressStrain);
          setGraphData(graphObject);

          generateDataPoints(graphObject.STRAIN_STRESS);
        } else {
          setIsValidID(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notification.open({
          message: "Invalid ID!",
          type: "error",
          duration: 2,
        });
        setLoading(false);
        setIsValidID(false);
      });
  }, [dispatch, generateDataPoints, id]);

  useEffect(() => {
    getData();

    return () => {
      clearActions();
    };
  }, [clearActions, getData]);

  useEffect(() => {
    if (axes === "STRAIN_STRESS") {
      generateDataPoints(graphData.STRAIN_STRESS);
      clearActions();
      setDisableUndo(true);
      setClipEnabled(false);
      setMoveOriginEnabled(false);
    } else {
      generateDataPoints(graphData.CROSSHEAD_LOAD);
      clearActions();
      setDisableUndo(true);
      setClipEnabled(false);
      setMoveOriginEnabled(false);
    }
  }, [axes, clearActions, generateDataPoints, graphData.CROSSHEAD_LOAD, graphData.STRAIN_STRESS]);

  const handleExCurveSelection = (tag, checked) => {
    const selected = checked ? [...exCurves, tag] : exCurves.filter((t) => t !== tag);
    setExCurves(selected);

    // logic to grey the selected curves
    if (checked) {
      const updatedSeries = dataPts.map((item) => {
        if (item.name === tag) {
          return { ...item, data: [] };
        }
        return item;
      });
      updatedSeries.push({
        name: tag + " - X",
        type: "line",
        smooth,
        clip,
        data: dataPts.find((item) => item.name === tag).data,
        itemStyle: {
          color: excludeColor,
        },
        lineStyle: {
          width: 1,
        },
      });

      setDataPts(updatedSeries);
    } else {
      let updatedSeries = dataPts.map((item) => {
        if (item.name === tag) {
          return { ...item, data: dataPts.find((item) => item.name === tag + " - X").data };
        }
        return item;
      });

      updatedSeries = updatedSeries.filter((item) => item.name !== tag + " - X");

      addAction({ action: "EXCLUDE", data: updatedSeries }).then(() => {
        setDataPts(updatedSeries);
      });
    }
  };

  const moveDataPoints = (a, b) => {
    let result = [];

    a.forEach((e, i) => {
      result.push(e - b[i]);
    });

    return result;
  };

  const handleReset = () => {
    generateDataPoints(graphData.STRAIN_STRESS);

    clearActions().then(() => {
      setDisableUndo(true);
      setIsAverageCurvePresent(false);
      setExCurves([]);
    });
  };

  // logic for undo actions
  const handleUndo = async () => {
    const allActions = await getAllActions();

    if (allActions.length > 0) {
      if (allActions.length === 1) {
        // set data from graphData
        handleReset();
      } else {
        // set latest prev action from iDB
        const latestAction = allActions[allActions.length - 2];
        deleteAction(latestAction.id).then(() => {
          setDataPts(latestAction.data);
        });
      }
    } else {
      message.error("No actions to undo!");
    }
  };

  // logic for move origin
  const moveOrigin = (params) => {
    if (params.seriesName.endsWith(" - MO")) {
      return;
    }

    // check for exisiting MO index
    const exisitingItem = dataPts.find((d) => d.name === params.seriesName + " - MO");

    if (!exisitingItem) {
      // existing MO curve IS NOT PRESENT
      const movedDataPoints = dataPts[params.seriesIndex].data.map((d) => moveDataPoints(d, params.data));
      const zeroValueIndex = movedDataPoints.findIndex((mdp) => mdp[0] === 0 && mdp[1] === 0);
      const negativeDataPts = movedDataPoints.splice(0, zeroValueIndex);
      negativeDataPts.push([0, 0]);

      const updatedSeries = dataPts.map((item, index) => {
        // update the original item with moved points
        if (index === params.seriesIndex) {
          return { ...item, data: movedDataPoints };
        }
        return item;
      });

      updatedSeries.push({
        name: params.seriesName + " - MO",
        type: "line",
        smooth,
        clip,
        data: negativeDataPts,
        itemStyle: {
          color: excludeColor,
        },
        lineStyle: {
          width: 1,
        },
      });

      addAction({ action: "MOVE_ORIGIN", data: updatedSeries }).then(() => {
        setDataPts(updatedSeries);
        setDisableUndo(false);
      });
    } else {
      // existing MO curve IS PRESENT
    }
  };

  // logic for clipping points
  const clipPoints = (params) => {
    if (params.seriesName.endsWith(" - C")) {
      return;
    }

    // check for exisiting C index
    const exisitingItem = dataPts.find((d) => d.name === params.seriesName + " - C");

    if (!exisitingItem) {
      // existing C curve IS NOT PRESENT
      const dataPoints = dataPts[params.seriesIndex].data.map((pt) => pt);
      const clippedPoints = dataPoints.splice(params.dataIndex);

      const updatedSeries = dataPts.map((item, index) => {
        // update the original item with moved points
        if (index === params.seriesIndex) {
          return { ...item, data: dataPoints };
        }
        return item;
      });

      updatedSeries.push({
        name: params.seriesName + " - C",
        type: "line",
        smooth,
        clip,
        data: clippedPoints,
        itemStyle: {
          color: excludeColor,
        },
        lineStyle: {
          width: 1,
        },
      });

      addAction({ action: "CLIP", data: updatedSeries }).then(() => {
        setDataPts(updatedSeries);
        setDisableUndo(false);
      });
    } else {
      // existing MO curve IS PRESENT
    }
  };

  const onChartClick = (params) => {
    if (moveOriginEnabled) {
      moveOrigin(params);
    }

    if (clipEnabled) {
      clipPoints(params);
    }
  };

  const handleAverage = async () => {
    setLoading(true);
    setIsAverageCurvePresent(false);

    const jsonObject = {
      specimenStressStrain: convertGraphDataToOriginalJSONFormat(true),
    };

    dispatch(calculateAverage({ type: metadata.testMethod, data: jsonObject }))
      .then((response) => {
        setLoading(false);
        setIsAverageCurvePresent(true);
        if (response?.data?.responseData?.[0]) {
          setAvgCurveData(response.data.responseData);
          const graphObject = convertAvgJsonToGraphArray(response.data.responseData[0]);
          const loadSeries = {
            name: "Load Average",
            type: "line",
            smooth,
            clip,
            data: graphObject.LOAD,
            itemStyle: {
              color: loadColor,
            },
            lineStyle: {
              width: 8,
            },
            symbolSize: 8,
          };
          const unloadSeries = {
            name: "Unload Average",
            type: "line",
            smooth,
            clip,
            data: graphObject.UNLOAD,
            itemStyle: {
              color: unloadColor,
            },
            lineStyle: {
              width: 8,
            },
            symbolSize: 8,
          };

          const updatedSeries = dataPts.map((dataPt) => dataPt);
          updatedSeries.push(unloadSeries);
          updatedSeries.push(loadSeries);

          addAction({ action: "AVERAGE", data: updatedSeries }).then(() => {
            setDataPts(updatedSeries);
            setDisableUndo(false);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        notification.open({
          message: "Something went wrong!",
          type: "error",
          duration: 2,
        });
        setLoading(false);
        setIsAverageCurvePresent(false);
      });
  };

  const handleSave = () => {
    setLoading(true);
    const finalJSON = { average: avgCurveData, specimen: convertGraphDataToOriginalJSONFormat() };
    const jsonBlob = new Blob([JSON.stringify(finalJSON, null, 2)], {
      type: "application/json",
    });

    const formData = new FormData();
    formData.append("file", jsonBlob);
    formData.append("curationId", id);
    formData.append(
      "fileName",
      `${metadata.csvFilePath.split("/")[0]}/CURATED_${metadata.name}_${metadata.materialID}.json`
    );
    formData.append("lastModifiedBy", userInfo.name);

    dispatch(uploadFinalJSON(formData))
      .then(() => {
        message.success("Final JSON uploaded successfully!");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading data:", error);
        message.error("Failed to upload final JSON.");
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        padding: "96px 1em 1em 2em",
        height: "100vh",
      }}
    >
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/mdm-poc">Curations</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{metadata.name || (loading ? "Loading..." : "-")}</Breadcrumb.Item>
      </Breadcrumb>

      <Spin spinning={loading} size="large">
        <div style={{ height: "calc(100vh - 120px)", overflow: "auto" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Space wrap>
              <span>
                <Typography.Text strong>Test Method: </Typography.Text>
                <Typography.Text>{metadata.testMethod || "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Material ID: </Typography.Text>
                <Typography.Text>{metadata.materialID || "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Gauge Length: </Typography.Text>
                <Typography.Text>{metadata.length ? metadata.length + "mm" : "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Width: </Typography.Text>
                <Typography.Text>{metadata.width ? metadata.width + "mm" : "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Thickness: </Typography.Text>
                <Typography.Text>{metadata.thickness ? metadata.thickness + "mm" : "-"}</Typography.Text>
              </span>
            </Space>

            <Space wrap>
              <Tooltip
                title="Changing the Axes to 'Crosshead - Load' will reset the graph and will not let you perform any actions!"
                color="#8f6b02bf"
                placement="leftBottom"
              >
                <ExclamationCircleTwoTone size={8} twoToneColor="#faae14" />
              </Tooltip>
              <Typography.Text>Axes:</Typography.Text>
              <Select
                options={axisOptions}
                value={axes}
                onChange={(value) => setAxes(value)}
                size="small"
                disabled={isAverageCurvePresent || !isValidID}
              />

              <Tooltip title="Reset">
                <Button size="small" disabled={disableUndo || !isValidID} onClick={handleReset}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <RetweetOutlined />
                  </div>
                </Button>
              </Tooltip>
              <Tooltip title="Undo">
                <Button size="small" disabled={disableUndo || isAverageCurvePresent || !isValidID} onClick={handleUndo}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <UndoOutlined />
                  </div>
                </Button>
              </Tooltip>

              <Tooltip title="Clip">
                <Switch
                  checkedChildren={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ScissorOutlined />
                    </div>
                  }
                  unCheckedChildren={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ScissorOutlined />
                    </div>
                  }
                  checked={clipEnabled}
                  onChange={(checked) => setClipEnabled(checked)}
                  disabled={moveOriginEnabled || axes === "CROSSHEAD_LOAD" || isAverageCurvePresent || !isValidID}
                />
              </Tooltip>
              <Tooltip title="Move Origin">
                <Switch
                  checkedChildren={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DragOutlined />
                    </div>
                  }
                  unCheckedChildren={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DragOutlined />
                    </div>
                  }
                  checked={moveOriginEnabled}
                  onChange={(checked) => setMoveOriginEnabled(checked)}
                  disabled={clipEnabled || axes === "CROSSHEAD_LOAD" || isAverageCurvePresent || !isValidID}
                />
              </Tooltip>

              <Button
                size="small"
                disabled={axes === "CROSSHEAD_LOAD" || isAverageCurvePresent || !isValidID}
                onClick={handleAverage}
              >
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <LineChartOutlined /> <span>Average</span>
                </div>
              </Button>
              <Button type="primary" size="small" disabled={!isAverageCurvePresent || !isValidID} onClick={handleSave}>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <SaveOutlined /> <span>Save</span>
                </div>
              </Button>
            </Space>
          </div>

          <div
            style={{
              backgroundColor: "white",
              height: "calc(100% - 48px)",
              borderTop: "2px solid #06509e",
              marginTop: 8,
            }}
          >
            {isValidID ? (
              <>
                <div className="curation-legends">
                  {legendNames.map((tag, i) => (
                    <CheckableTag
                      key={tag}
                      checked={exCurves.indexOf(tag) > -1}
                      onChange={(checked) => handleExCurveSelection(tag, checked)}
                    >
                      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                        <ChartLineIcon
                          style={{
                            fontSize: 16,
                            color: exCurves.indexOf(tag) > -1 ? "lightgrey" : colors[i] || defaultColor,
                          }}
                        />
                        <b>{tag}</b>
                      </div>
                    </CheckableTag>
                  ))}

                  {isAverageCurvePresent ? (
                    <>
                      <div style={{ display: "flex", alignItems: "center", gap: 4, marginRight: 8 }}>
                        <ChartLineIcon
                          style={{
                            fontSize: 16,
                            color: loadColor,
                          }}
                        />
                        <b>Load Average</b>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                        <ChartLineIcon
                          style={{
                            fontSize: 16,
                            color: unloadColor,
                          }}
                        />
                        <b>Unload Average</b>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <Chart axes={axes} legendNames={legendNames} data={dataPts} onChartClick={onChartClick} />
              </>
            ) : (
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the curation you are looking for does not exist."
                extra={
                  <Link to="/mdm-poc">
                    <Button type="primary">Back Home</Button>
                  </Link>
                }
              />
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default Curation;
