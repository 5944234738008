import { Security, LoginCallback } from "@okta/okta-react";
import {
  OktaAuth,
  OktaAuthOptions,
  TokenManager,
  AccessToken,
  IDToken,
  UserClaims,
  TokenParams,
  toRelativeUrl,
} from "@okta/okta-auth-js";
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import logo from "./logo.svg";
import Header from "./components/header";
import SignInError from "./components/signInError";
import AppRoutes from "./components/routers";
import DefaultHome from "./pages";
import "./App.css";


function App() {
  const oktaAuth = new OktaAuth({
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    redirectUri: `${window.location.origin}/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    responseType: "id_token",
    disableHttpsCheck: true,
  });

  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <div className="wrapper ">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <DefaultHome />
        {/* <SignInError /> */}
      </Security>
    </div>
  );
}

export default App;
