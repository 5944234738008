import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { getMaterialCurationList } from "../axios";
import CurationForm from "../components/materialCuration/CurationForm";
import CurationTable from "../components/materialCuration/CurationTable";

const MaterialCuration = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    setLoading(true);

    dispatch(getMaterialCurationList())
      .then((response) => {
        setData(response?.data.responseData || []);
        setFilteredData(response?.data.responseData || []);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err, "error");
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = (value) => {
    const newValue = value.toLowerCase();
    const newData = data.filter(
      (d) =>
        d.id.toString().toLowerCase().includes(newValue) ||
        d.materialID.toString().toLowerCase().includes(newValue) ||
        d.name.toLowerCase().includes(newValue) ||
        d.testMethod.toLowerCase().includes(newValue) ||
        d.createdBy.toLowerCase().includes(newValue)
    );

    setFilteredData(newData);
  };

  return (
    <div style={{ padding: "7em 1em 1em 1em", height: "100vh", display: "flex", flexDirection: "column", gap: 16 }}>
      <CurationForm onFormSuccess={fetchData} onSearch={handleSearch} />
      <CurationTable data={filteredData} loading={loading} />
    </div>
  );
};

export default MaterialCuration;
