import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link,    useParams, Outlet   } from "react-router-dom";
import {getMaterialData} from '../axios/index'  
import DataGrid from "../components/tables/dataGrid" 
import DefaultBreadCrumb from "../components/breadcrumb"
import PageHeader from "../components/header/pageHeader"
import {userRoles} from '../components/utils/userRoles'

const Materails = () => {
    const dispatch = useDispatch();
   // const materialRows = useSelector((state)=> state.materialsData.rows);
    const data = useSelector((state)=> state.materialsData);
    const userInfo = useSelector((state) => state.userInfo)
    const myModel = useSelector((state) => state.userModel);
    const latestVersion = useSelector((state) => state.latestVersion);

    const [userRole, setUserRole] = useState();
    // const [subRegion, setSubRegion] = React.useState('');
    // const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    // const selectedSubRegion = useSelector((state) => state.updatedSubRegion)
    // const oktaAuth = useSelector((state) => state.oktaAuth);
    useEffect(() => {
         dispatch(getMaterialData({ 
          ...(myModel.isChecked && {
                myModels: userInfo.sAMAccountName
            }),
          ...(latestVersion.isLatestVersionChecked && {
                latestVersion: latestVersion.isLatestVersionChecked
            })
          })
        )
      
        if (userInfo.sAMAccountName) {
        // Roles = ['']
        setUserRole(userRoles(userInfo.Roles))
        }
    }, [userInfo, myModel.isChecked, latestVersion.isLatestVersionChecked]);
    


    return (
       <div className="main-panel ps">
          <div className="content padd-top">
            <div className="row">
            <div className="courses-nav">
            
   
      </div> 
              <DefaultBreadCrumb data={[{'title':'Home',path:'/'}, {'title':'Materials',path:'/materials', active:true }]} />  
            </div> 
            <div className="row">
              <PageHeader  iconType ={"material"} title = {" Manage Materials Models"} path='new-material'  type='Material' userRole={userRole}/>
            </div>
            <div className="row">
              <DataGrid  tableData = {data}  path ='material-details' enableLink={true} />
            </div>
          </div>
       </div>
    );
}

export default Materails;