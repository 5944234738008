
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link,    useParams, createSearchParams, useSearchParams,useNavigate  } from "react-router-dom";
import {getSamlData, getMaterialPropertyDetai, getFiberPropertyDetai, getSamPropertyDetai, resetDataSet, resetMaterailSearchReucer, sendRecordDataToBackEnd, reaetSendRecordDataToBackEndReducer, 
  fetchMaterialTypes, fetchAutorTypes, fetchMaterialColumns, deleteProperty,resetDeleteProperty, fetchQueryIdForSelectedProperty,resetsearchResultsFromLabvantageAPiToInintal,  resetCalulatorApiResultToInintal, setSendRecordDataToBackEndReducerStatus, getGraphData} from '../../axios/index'  
import cloneDeep from 'lodash/cloneDeep';
import DefaultBreadCrumb from "../breadcrumb"
import Header from "./header"
import ActionButtons from "./actionButtons";
import ImputGrid from "./container";
import SaveMaterialPopup from "./savePopupWindow";
import DeletePopupWindow from "./deletePopupWindow"
import AdvanceSearch from "./advancedSearch"
import {runAutoCalculationCheck, autoCalulation} from "../utils/calculatorUtils";

const Details = ({breadCrumbData, moduleName, defaultMaterialDataSet,fields, urlId, urlParams}) => {
    
     const dispatch = useDispatch();
       
     const originalMaterialDataSet = useSelector((state)=> state.MaterailsDetail);
     const recordUpdateApiStatus = useSelector((state)=>state.recordUpdateApi)
     const deleteRecordApiStatus = useSelector((state)=>state.deleteRecordApi)
     const [editStatus, setEditStatus] = React.useState(false);
     const [saveEvent, setSaveEvent] = React.useState(false);
     const [deleteEvent, setDeleteEvent] =React.useState(false);
     const [moduleFieldDataSet, setModuleFieldDataSet] = React.useState({});
     const [componentID, setComponentID] = React.useState(1);
     const [advancedSearchEvent, setAdvancedSearchEvent] = React.useState(false)
     const [graphVisualizer, setGraphVisualizer] = React.useState(false)

     const [advancedSearchEventType, setAdvancedSearchEventType] = React.useState('advancedSearch')
     const [advancedSearchMetaData, setAdvancedSearchMetaData] = React.useState({})
     const userInfo = useSelector((state) => state.userInfo)

     const navigate = useNavigate();   
     useEffect(() => {
      
      setModuleFieldDataSet(defaultMaterialDataSet);
      if(urlParams.id==(-1) || urlParams.id=='-1'){
        setEditStatus(true)
      }
      else{
        setEditStatus(false)
      }     
      },[defaultMaterialDataSet, urlParams])
      useEffect(() => {
       if(recordUpdateApiStatus.responseType==='success' && recordUpdateApiStatus.loading===false){
         
       let  tempPath = '../'+moduleName+'-details/'+recordUpdateApiStatus.data.key 
        // }
        // else if(recordUpdateApiStatus.data.deletedKey!==undefined){
        //   tempPath = '../'+moduleName+'-details/'+Number(recordUpdateApiStatus.data.deletedKey) 
        // }
        console.log("saveRecordEvent-----ModuleName---", moduleName, tempPath,urlParams)
        if(recordUpdateApiStatus.data.key!==Number(urlParams.id)){
                navigate(tempPath, { replace: true });   
        }    
        setEditStatus(false)        
       }
        
        },[recordUpdateApiStatus, ])
   
    const handleVisualizerStatus = (status) => {
      // dispatch(getGraphData())
      setGraphVisualizer(status)
      document.body.style.overflow = "hidden"
      document.body.classList.add("no-scroll")
    }
   
    const handleAdvancedSearchStatus = (type, currentStatus) =>{
       setAdvancedSearchEvent(currentStatus)
       if(type==='graph'){
          setGraphVisualizer(currentStatus)
        }
        setAdvancedSearchEventType('advancedSearch')
        dispatch(resetMaterailSearchReucer({}))
        dispatch(resetCalulatorApiResultToInintal())
        dispatch(resetsearchResultsFromLabvantageAPiToInintal());
        document.body.style.overflow = "hidden"
        document.body.classList.add("no-scroll")
    } 
    const handleLabVantageApiCallPopup = (status, item, key, module, index, fieldLabel) =>{
      setAdvancedSearchEventType('labvantageSearch')
      setAdvancedSearchEvent(status)
      setAdvancedSearchMetaData({item:item,key:key, module:module, index:index, fieldLabel:fieldLabel })
      dispatch(resetMaterailSearchReucer({}))
      dispatch(resetCalulatorApiResultToInintal())
      dispatch(resetsearchResultsFromLabvantageAPiToInintal());
      dispatch(fetchQueryIdForSelectedProperty({propertyName:key, propertyType:module}))
      document.body.style.overflow = "hidden"
      document.body.classList.add("no-scroll")
  }  
    
    const handleEditStatus = (status) =>{
          setEditStatus(status)       
          let newMaterialDataSet = cloneDeep(originalMaterialDataSet);            
          if(status==false){dispatch(resetDataSet(newMaterialDataSet)) }   
          if(status==false && (urlParams.id=='-1' || urlParams.id==-1)){
            let tempPath = '../';
            navigate(tempPath, { replace: true }); 
          }       
         dispatch(reaetSendRecordDataToBackEndReducer({}))     
    }
    const handleSaveEvent = (status) =>{
      setSaveEvent(status)
    }
    const handleDeletEvent=(status)=>{
      setDeleteEvent(status)
    }
    const compareWithOriginalDataSet = (key, moduleFieldDataSet) =>{
      let tempCounter = 0;      
      Object.keys(moduleFieldDataSet.header).map((tempHeaderKey)=>{
        if(originalMaterialDataSet['data'][key]['header'][tempHeaderKey]!= moduleFieldDataSet['header'][tempHeaderKey] && moduleFieldDataSet['header']['edited']===true){
          tempCounter = tempCounter+1;          
        }
      })
      if(tempCounter===0){
        moduleFieldDataSet.propertyData.map((tempProperty, index)=>{
          Object.keys(tempProperty).map((tempPropertyKey)=>{
            if((originalMaterialDataSet['data'][key]['propertyData'][index][tempPropertyKey]['value']!= tempProperty[tempPropertyKey]['value'] && tempProperty[tempPropertyKey]['valueTypeArray']!==true) &&  (tempProperty[tempPropertyKey]['edited']==true)){
              tempCounter = tempCounter+1;          
            }
          })
        })
        
      }
      return(tempCounter===0?false:true); 
    }
    const saveRecordEvent=(data)=>{
        dispatch(reaetSendRecordDataToBackEndReducer({}))
        dispatch(setSendRecordDataToBackEndReducerStatus(true))
        if(data.saveType!==undefined && data.saveType!==undefined){
            
            let statusType = (data.statusType=='inDraft'?'Draft':'In Production') 
            let tempModuleFieldDataSet = cloneDeep(moduleFieldDataSet)
            
            // if(data.saveType==='newVersion'){
            //   Object.keys(tempModuleFieldDataSet).map((key)=>{
            //     //tempModuleFieldDataSet[key]['header']['key'] = '';
               
            //       tempModuleFieldDataSet[key]['header']['status'] = statusType;
               
                
            //   })
            // }
           // else{
              Object.keys(tempModuleFieldDataSet).map((key)=>{                
                //tempModuleFieldDataSet[key]['header']['author'] = userInfo.sAMAccountName 
              tempModuleFieldDataSet['saveType'] = data.saveType
              if(data.module===key && data.saveType==='new'){
                   tempModuleFieldDataSet[key]['header']['key'] = '';
                   tempModuleFieldDataSet[key]['header']['status'] = statusType;
                   tempModuleFieldDataSet[key]['header']['author'] = userInfo.sAMAccountName 
              }
              else if(data.module===key && data.saveType!=='new'){
                   tempModuleFieldDataSet[key]['header']['status'] = statusType;
                   tempModuleFieldDataSet[key]['header']['author'] = userInfo.sAMAccountName 
              }
              else if(data.module!==key){
                  //console.log("ooooooooooooo----224442", key)
                //tempModuleFieldDataSet[key]['header']['status'] = statusType;
                 // tempModuleFieldDataSet[key]['header']['key'] = '';
                 if(compareWithOriginalDataSet(key, tempModuleFieldDataSet[key])){ 
                  //do nothing
                  tempModuleFieldDataSet[key]['header']['key'] = '';
                  tempModuleFieldDataSet[key]['header']['status'] = statusType;
                  tempModuleFieldDataSet[key]['header']['author'] = userInfo.sAMAccountName 
               }
               else{

               }
                  
                 }
              })
           // }
            
            dispatch(sendRecordDataToBackEnd(tempModuleFieldDataSet))
            
           
        }
    }
    const handleHeaderDataUpdate=(data)=>{
      let tempDataSet = {...moduleFieldDataSet};
      tempDataSet[data.module]['header'][data.key]=  data.e;
      let editStatus = false;
      Object.keys(tempDataSet[data.module]['header']).map((key, index)=>{     
        if( key!=='edited' && originalMaterialDataSet.data[data.module]['header'][key]!==tempDataSet[data.module]['header'][key]){          
          editStatus = true;
        }      
      })
     
      tempDataSet[data.module]['header']['edited']=  editStatus;
      setModuleFieldDataSet({...tempDataSet})       
    }
    const closeModal=(e)=>{
      setSaveEvent(false)
      setDeleteEvent(false)
      dispatch(reaetSendRecordDataToBackEndReducer({}))     
    }
    const handleFieldDataUpdate=(data)=>{
      setModuleFieldDataSet(data)   
    }
    const importSearchResultSet=(data)=>{
      let tempModuleFieldDataSet = cloneDeep(moduleFieldDataSet);
      if(data.moduleType=='fiber' && Object.keys(data.dataSet).length>0){         
        tempModuleFieldDataSet['fiber']['header']['description'] = data.dataSet.fiberDescription;
        tempModuleFieldDataSet['fiber']['header']['name'] = data.dataSet.fiberName;
        tempModuleFieldDataSet['fiber']['header']['version'] = data.dataSet.version;
        tempModuleFieldDataSet['fiber']['header']['key'] = '';         
        (moduleFieldDataSet['fiber']['propertyData']).map((item, index)=>{
              return Object.keys(item).map((currentItemKey)=>{
                    if(data.dataSet[currentItemKey]!==-1){
                      tempModuleFieldDataSet['fiber']['propertyData'][index][currentItemKey]['edited']=false;
                      tempModuleFieldDataSet['fiber']['propertyData'][index][currentItemKey]['value'] = data.dataSet[currentItemKey]
                      tempModuleFieldDataSet['fiber']['propertyData'][index][currentItemKey]['dataSource']=data.dataSource
                    }
                  
              })
        })  
        tempModuleFieldDataSet = autoCalulation('fiber', tempModuleFieldDataSet, fields) 
        setModuleFieldDataSet(tempModuleFieldDataSet)  
            
      }
      else if(data.moduleType=='fiberDefault' && Object.keys(data.dataSet).length>0){
        tempModuleFieldDataSet['fiber'] = data.dataSet.fiber
        tempModuleFieldDataSet = autoCalulation('fiber', tempModuleFieldDataSet, fields) 
        setModuleFieldDataSet(tempModuleFieldDataSet)

      }
      else if(data.moduleType=='sam' && Object.keys(data.dataSet).length>0){
        tempModuleFieldDataSet[data.moduleType] = data.dataSet.sam
        tempModuleFieldDataSet = autoCalulation('sam', tempModuleFieldDataSet, fields) 
        setModuleFieldDataSet(tempModuleFieldDataSet)
      }
      else if(data.moduleType=='labvantage' && Object.keys(data.dataSet).length>0){
        let tempDataSet = tempModuleFieldDataSet[advancedSearchMetaData.module]['propertyData'][advancedSearchMetaData.index][advancedSearchMetaData.key];
         tempDataSet['value'] = data.dataSet.avgvalue;
        tempDataSet['edited'] = false;
        tempDataSet['virtual'] = false;
        tempDataSet.dataSource= data.dataSource;       
        tempModuleFieldDataSet[advancedSearchMetaData.module]['propertyData'][advancedSearchMetaData.index][advancedSearchMetaData.key] = tempDataSet;
        tempModuleFieldDataSet = autoCalulation(advancedSearchMetaData.module, tempModuleFieldDataSet, fields) 
        setModuleFieldDataSet(tempModuleFieldDataSet)
        closeAdvancedSearchModal(false);
      }
      else if(data.moduleType=='labvantageCalcResults' && Object.keys(data.dataSet).length>0 && data.dataSet.data.outputData.length>0){
        let tempDataSet = tempModuleFieldDataSet[advancedSearchMetaData.module]['propertyData'];
        //let tempNewDataSet = [];
        // console.log("ooooop", tempDataSet)
        // tempDataSet.map((propertyRow, index)=>{
        //   tempNewDataSet.push(propertyRow)
        //   data.dataSet.data.outputData.map((calulatorRow, calCRow)=>{
        //     if(propertyRow[calulatorRow.dbColumnName]!==undefined){
        //       if(Array.isArray(propertyRow[calulatorRow.dbColumnName]['value'])){
        //         propertyRow[calulatorRow.dbColumnName]['value'].map((item, itemIndex)=>{
                  
        //         })
        //       }
        //       tempNewDataSet[index][calulatorRow.dbColumnName]['value'] = calulatorRow.value;
        //       tempNewDataSet[index][calulatorRow.dbColumnName]['dataSource'] = data.dataSet.data.dataSource;
        //     }
        //   }) 
          
          



        // })
        let tempNewDataSet = loopCalulatorResults(tempDataSet, data)
        tempModuleFieldDataSet[advancedSearchMetaData.module]['propertyData']= tempNewDataSet;
        tempModuleFieldDataSet = autoCalulation(advancedSearchMetaData.module, tempModuleFieldDataSet, fields) 
        setModuleFieldDataSet(tempModuleFieldDataSet)
        closeAdvancedSearchModal(false);

      }
      
    }

    const loopCalulatorResults=(tempDataSet, data)=>{
      let tempNewDataSet = [];
       tempDataSet.map((propertyRow, index)=>{
        tempNewDataSet.push(propertyRow)
         
        Object.keys(propertyRow).map((key, keyIndex)=>{
          if(propertyRow[key]!==undefined){
            if(Array.isArray(propertyRow[key]['value'])){
              data.dataSet.data.outputData.map((calulatorRow, calCRow)=>{
                propertyRow[key]['value'].map((propItem, propIndex)=>{
                  if(propItem[calulatorRow.dbColumnName]!==undefined){
                    tempNewDataSet[index][key]['dataSource'] = data.dataSet.data.dataSource;
                    tempNewDataSet[index][key]['virtual'] = false;
                  }
                })
                
              })              
              loopCalulatorResults(propertyRow[key]['value'],data)    
              
            }
            else{
              data.dataSet.data.outputData.map((calulatorRow, calCRow)=>{
                if(propertyRow[calulatorRow.dbColumnName]!==undefined){
                  tempNewDataSet[index][calulatorRow.dbColumnName]['edited'] = false;
                  tempNewDataSet[index][calulatorRow.dbColumnName]['value'] = calulatorRow.value;
                  tempNewDataSet[index][calulatorRow.dbColumnName]['dataSource'] = data.dataSet.data.dataSource;
                  tempNewDataSet[index][calulatorRow.dbColumnName]['virtual'] = false;

                }                
              })
            }
          } 
        })
        // data.dataSet.data.outputData.map((calulatorRow, calCRow)=>{
        //   if(propertyRow[calulatorRow.dbColumnName]!==undefined){
        //     if(Array.isArray(propertyRow[calulatorRow.dbColumnName]['value'])){
        //       tempNewDataSet[index][calulatorRow.dbColumnName]['value'] = loopCalulatorResults(propertyRow[calulatorRow.dbColumnName]['value'],data)              
        //     }
        //   else{
        //     tempNewDataSet[index][calulatorRow.dbColumnName]['value'] = calulatorRow.value;
        //     tempNewDataSet[index][calulatorRow.dbColumnName]['dataSource'] = data.dataSet.data.dataSource;
        //    }
        //   }
        // }) 
        
      })
      return tempNewDataSet
    }

const handleDeleteEvent =()=>{
  //dispatch(deleteProperty({id: urlId, type: moduleName}))
} 


    const closeAdvancedSearchModal=(e)=>{
      setAdvancedSearchEvent(false)
      setGraphVisualizer(false)
      document.body.style.overflow = "auto"
      document.body.classList.remove("no-scroll")
      //dispatch(reaetSendRecordDataToBackEndReducer({}))     
    }
    const renderDeletePopup =()=>{
      if(deleteEvent && urlParams.id!='-1'){
        //let modeldata =  {module:moduleName, title: `Delete ${moduleName}`,  urlId:urlId}
        return(<><div className="modal-backdrop fade show"></div><DeletePopupWindow  recordUpdateApiStatus = {recordUpdateApiStatus} handleDeleteEvent = {(e)=>handleDeleteEvent()} moduleName = {moduleName} moduleFieldDataSet={moduleFieldDataSet}   urlId={urlParams.id} closeModal={(e)=>closeModal(e)}/></>)
      }
    }
    const renderSavePopup =() =>{
        if(saveEvent){
          let saveType = 'newVersion';

          if(urlId==="-1"){
            saveType = 'new';
          }
          else if(urlId!=="-1"  && moduleFieldDataSet[moduleName]!==undefined && moduleFieldDataSet[moduleName]['header'] !== undefined && moduleFieldDataSet[moduleName]['header']['status'] !==undefined && moduleFieldDataSet[moduleName]['header']['status']==='Draft'){
            saveType = 'update';
          }
          let modeldata =  {module:moduleName, title: `Save ${moduleName} Details`, icon:'now-ui-icons arrows-1_cloud-download-93', info:`You are about to save all the changes. Please select from the given below options.`, urlId:urlId, saveType:saveType}
          return(<><div className="modal-backdrop fade show"></div><SaveMaterialPopup moduleDefaultFieldDataSet={moduleFieldDataSet}   moduleName = {moduleName} modal = {modeldata} saveRecordEvent = {(data)=>saveRecordEvent(data)} recordUpdateApiStatus = {recordUpdateApiStatus} urlId={urlId} closeModal={(e)=>closeModal(e)}/></>)
        }
    }
    const renderAdvancedSearchPopup =()=>{
      console.log("MM34", moduleFieldDataSet)
      if(advancedSearchEvent){     
        let modeldata = {}
        let tempAdvancedSearchEventType = advancedSearchEventType;
        if(graphVisualizer){
          modeldata =  {module:'graphVisualizer', calulatorForm:false, title: `Visualizer`, icon:'now-ui-icons  ui-1_zoom-bold'} 
        }
        else{       
        modeldata =  {module:moduleName, calulatorForm:false, title: `Search & Import`, icon:'now-ui-icons  ui-1_zoom-bold', info:`You are about to save all the changes. Please select from the given below options.`} 
        if(advancedSearchEventType=='labvantageSearch'){
          let tempKey = advancedSearchMetaData.key;
          let calulatorForm  =advancedSearchMetaData.item[tempKey]['propertyMetaData']['calculationForm']
          let compressionForm = advancedSearchMetaData.item[tempKey]['propertyMetaData']['compressionForm']
          modeldata =  {module: !compressionForm  ? 'labvantage' : 'compression',  calulatorForm:calulatorForm, title: `${!compressionForm ? 'Labvantage' : 'Compression' } Search | ${advancedSearchMetaData.fieldLabel}`, icon:'now-ui-icons  ui-1_zoom-bold', info:`You are about to save all the changes. Please select from the given below options.`}
        }      
      }   
           return(<AdvanceSearch 
                      modal = {modeldata}   
                      recordUpdateApiStatus = {recordUpdateApiStatus} 
                      urlId={urlId} 
                      closeAdvancedSearchModal={(e)=>closeAdvancedSearchModal(e)}
                      importSearchResultSet = {(data)=>importSearchResultSet(data)}
                      advancedSearchEventType={advancedSearchEventType}
                      advancedSearchMetaData = {advancedSearchMetaData}
                      moduleDefaultFieldDataSet = {moduleFieldDataSet}
                      fields ={fields}
                  />
                )
        } 
    
      }
    return(
      <div className="content padd-top">                  
                <div className="row">
                  <DefaultBreadCrumb  data={breadCrumbData}/>  
                </div> 
                
                <div className="row">
                  <div className="col-12 header-bar">
                    <div className="col-12 content" data-module={moduleName}>
                        <Header moduleName = {moduleName} moduleFieldDataSet={moduleFieldDataSet} editStatus = {editStatus}  handleHeaderDataUpdate={(data)=>handleHeaderDataUpdate(data)} />
                        <ActionButtons  moduleName = {moduleName} moduleFieldDataSet={moduleFieldDataSet} handleEditStatus = {handleEditStatus} handleDeletEvent={(e)=>handleDeletEvent(e)} editStatus = {editStatus} handleSaveEvent = {handleSaveEvent}   handleAdvancedSearchStatus = {(type, currentStatus)=>handleAdvancedSearchStatus(type, currentStatus)} urlId={urlId}/> 
                    </div>	
                  </div>
                </div>                
                <ImputGrid 
                    moduleName = {moduleName} 
                    moduleDefaultFieldDataSet={moduleFieldDataSet} 
                    moduleFieldDataSet={defaultMaterialDataSet}
                    editStatus = {editStatus} 
                    saveEvent={saveEvent}
                    handleEditStatus = {handleEditStatus} 
                    handleSaveEvent = {handleSaveEvent}
                    handleLabVantageApiCallPopup = {handleLabVantageApiCallPopup}
                    handleFieldDataUpdate={(data)=>handleFieldDataUpdate(data)}  
                    handleHeaderDataUpdate = {(data)=>handleHeaderDataUpdate(data)}
                    fields ={fields}
                    />
                {renderSavePopup()}  
                {renderDeletePopup()}  
                {renderAdvancedSearchPopup()}   
                
            </div>
    )
}

export default Details;