
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getSamlData} from '../axios/index'  
import DataGrid from "../components/tables/dataGrid" 
import DefaultBreadCrumb from "../components/breadcrumb"
import PageHeader from "../components/header/pageHeader"
import {userRoles} from '../components/utils/userRoles'

const Sam = () => {
    const dispatch = useDispatch();
    const materialRows = useSelector((state)=> state.materialsData.rows);
    const data = useSelector((state)=> state.sam);
    const userInfo = useSelector((state) => state.userInfo)
    const myModel = useSelector((state) => state.userModel);
    const latestVersion = useSelector((state) => state.latestVersion);

    const [userRole, setUserRole] = useState();

    // const [subRegion, setSubRegion] = React.useState('');
    // const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    // const selectedSubRegion = useSelector((state) => state.updatedSubRegion)
    // const oktaAuth = useSelector((state) => state.oktaAuth);


    useEffect(() => {
       
         dispatch(getSamlData({ 
          ...(myModel.isChecked  && {
            myModels: userInfo.sAMAccountName
          }),
          ...(latestVersion.isLatestVersionChecked && {
            latestVersion: latestVersion.isLatestVersionChecked
          })
      }))
      
         if (userInfo.sAMAccountName) {
           
          // Roles = ['']
          setUserRole(userRoles(userInfo.Roles))
         }
    }, [userInfo, myModel.isChecked, latestVersion.isLatestVersionChecked]);
    

    return (
       <div className="main-panel ps">
          <div className="content padd-top">
            <div className="row">
              <DefaultBreadCrumb  data={[{'title':'Home',path:'/'}, {'title':'SAM',path:'/sam', active:true }]}/>  
            </div> 
            <div className="row">
              <PageHeader  iconType ={"sam"} title = {" Manage SAM Properties"}  path='new-sam' type='SAM' userRole={userRole}/>
            </div>
            <div className="row">
              <DataGrid  tableData = {data}  icon ={"now-ui-icons gestures_tap-01"} title = {" Manage SAM Properties"} path ='sam-details' enableLink={true}/>
            </div>
          </div>
       </div>
    );
}

export default Sam;
