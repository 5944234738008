import { DownloadOutlined, LineChartOutlined } from "@ant-design/icons";
import { Button, notification, Space, Table } from "antd";
import { saveAs } from "file-saver";
import { PropTypes } from "prop-types";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { downloadSpecimenFile } from "../../axios";

CurationTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

function CurationTable({ data, loading }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const downloadFile = async (filePath) => {
    const key = `open${Date.now()}`;

    notification.open({
      message: "File downdload is in progress!",
      type: "info",
      key,
    });

    dispatch(downloadSpecimenFile(filePath))
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        saveAs(blob, filePath.split("/").pop()); // Uses file-saver to download file, could use the last part of the path as the filename
        notification.close(key);
      })
      .catch((error) => {
        console.error("Download error:", error);
        notification.close(key);
        notification.open({
          message: "File downdload failed!",
          type: "error",
          duration: 2,
        });
      });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 48,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 320,
    },
    {
      title: "Test Method",
      dataIndex: "testMethod",
      key: "testMethod",
      sorter: (a, b) => a.testMethod.localeCompare(b.testMethod),
    },
    {
      title: "Material ID",
      dataIndex: "materialID",
      key: "materialID",
      width: 140,
      sorter: (a, b) => a.materialID - b.materialID,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 124,
      sorter: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 120,
      render: (text) => new Date(text).toLocaleDateString("en-US"),
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (text, record) => {
        return (
          <Space>
            <Button
              type="ghost"
              size="small"
              disabled={record?.csvFilePath?.length === 0}
              onClick={() => downloadFile(record?.csvFilePath)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <DownloadOutlined /> <span>CSV</span>
              </div>
            </Button>
            <Button
              type="ghost"
              size="small"
              disabled={record?.rawJSONPath?.length === 0}
              onClick={() => downloadFile(record?.rawJSONPath)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <DownloadOutlined /> <span>Raw JSON</span>
              </div>
            </Button>
            <Button
              type="primary"
              size="small"
              disabled={record?.rawJSONPath?.length === 0}
              onClick={() => navigate(`${record.id}`)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <LineChartOutlined /> <span>Curate</span>
              </div>
            </Button>
            <Button
              type="ghost"
              size="small"
              disabled={record?.finalJSONPath == null}
              onClick={() => downloadFile(record?.finalJSONPath)}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                <DownloadOutlined /> <span>Finalized JSON</span>
              </div>
            </Button>
          </Space>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      loading={loading}
      rowKey="key"
      scroll={{ x: "max-content", y: "calc(100vh - 236px)" }}
      pagination={{ hideOnSinglePage: true }}
      style={{ borderTop: "2px solid #06509e" }}
    />
  );
}

export default CurationTable;
