
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from 'lodash/cloneDeep';
import {reaetSendRecordDataToBackEndReduce, saveConfig} from '../../axios/index'  
import {Link} from "react-router-dom";
import loader from '../../loader.gif';
import './index.css';
 
function SaveConfigPopup(props) {  
  const dispatch = useDispatch();
  const partData = useSelector((state)=>state.svgViewerPartData);
  const [saveOptions, setSaveOptions] = React.useState(props.modal.saveType);
  const [error, setError] = React.useState(false);
  const [statusType, setStatusType] = React.useState('draft');
  const parts = useSelector((state) => state.svgViewerPartData)
  // const handleEvent = (status)=>{
  //   return props.handleEditStatus(status)
    
  // }
  // const handleSave = (e) =>{
  //   return props.handleSaveEvent(true)
  // }
const handleSaveEvent=(e)=>{
          let tempParts  = cloneDeep(partData);         
          
          
  //if(parts.data.configData.partData.length>0){
    //  if(parts.data.configData.)
    if(parts.data.configData.metaData.assemblyID!=='' && parts.data.configData.metaData.configName!==''){
      if(saveOptions==='new'){
         tempParts.data.configData.metaData.configId='';  
         tempParts.data.configData.type ='new';       
         tempParts.data.configData.metaData.configStatus =statusType;   
         dispatch(saveConfig({configData:tempParts.data.configData}))
      }
      else if(saveOptions === 'update'){
        tempParts.data.configData.type ='existing';   
        tempParts.data.configData.metaData.configStatus =statusType;   
        dispatch(saveConfig({configData:tempParts.data.configData}))

      }
    // props.closeModal(e)
    }
    else{
      setError(true)
    }
  // }
  // else {

  // }   
  
 // return props.saveRecordEvent({saveType:saveOptions, statusType:statusType, module:props.modal.module})
}

const renderLoader = ()=>{
  if(partData.loading===true){
    return(
      <div className='fixedLoader'><img src={loader} className='loaderr' /></div>
    )
  }
}
const renderCardBody = () =>{
  
 
  if(partData.loading===false && partData.responseType==''){
     
    let currentModuleeditStatus = '';
    if(partData.data.configData?.type==="new"){
      currentModuleeditStatus= 'draft'
    }
    else if(partData.data.configData.metaData?.configStatus!==undefined){
      currentModuleeditStatus= partData.data.configData.metaData.configStatus;
    }
    let configId = partData.data.configData.metaData?.configId;
    
    return(
      <div className="card-body">
          <p>{props.modal.info}</p>
          <form>
            <div className="row">
              <div className="col-6 pr-1">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="saveOptions" id="radio1" value="new" checked = {saveOptions=='new'} onChange={(e)=>setSaveOptions(e.target.value)} />
                    <label class="form-check-label" for="radio1">
                    Save as New <span>{props.modal.module}</span>
                    </label>
                  </div>
                  {/* {configId!==undefined && configId!==''?(
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="saveOptions" id="radio2" value="newVersion"  checked = {saveOptions=='newVersion'} onChange={(e)=>setSaveOptions(e.target.value)} />
                    <label class="form-check-label" for="radio2">
                    Save as New <span>{props.modal.module}</span> Version
                    </label>
                  </div>
                  ):null} */}
                  {configId!=='' && currentModuleeditStatus==='draft'?(
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="saveOptions" id="radio2" value="update"  checked = {saveOptions=='update'} onChange={(e)=>setSaveOptions(e.target.value)} />
                        <label class="form-check-label" for="radio2">
                        Update current <span>{props.modal.module}</span> Version
                        </label>
                      </div>
                    ):null
                  }
                  
              </div>
              <div className="col-6 pr-1">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="statusType" id="radio3" value="inProduction"   checked = {statusType=='inProduction'} onChange={(e)=>setStatusType(e.target.value)} />
                    <label class="form-check-label" for="radio3">
                    Publish this <span>{props.modal.module} to <b>Production</b></span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="statusType" id="radio4" value="draft"    checked = {statusType=='draft'} onChange={(e)=>setStatusType(e.target.value)}/>
                    <label class="form-check-label" for="radio4">
                    Save this <span>{props.modal.module} to <b>Drafts</b></span>
                    </label>
                  </div>
              </div>
              
    
    
              
            </div> 
          </form>
        
        </div>
    )
  }
  else if(partData.loading===false &&  partData.responseType!==''){
    if(partData.responseType==='Failed'){
      return(<div className="card-body">
             <div ClassName='failedMsg'>
                  <div className='failed'><i className='now-ui-icons ui-1_simple-remove'></i>&nbsp; {partData.responseType}</div>
                  <div className='failedTxt'>{partData.responseDesc}</div>
             </div>
      </div>)

    }
    else if(partData.responseType==='success'){
      return(<div className="card-body">
             <div ClassName='failedMsg'>
                  <div className='successMsg'><i className='now-ui-icons ui-1_check'></i>&nbsp; {partData.responseType}</div>
                  <div className='successTxt'>{partData.responseDesc}</div>
             </div>
      </div>)

    }
     
  }
  else if(partData.loading){
    return(<div className="card-body">
    {renderLoader()}
</div>)
  }
}
const renderingTxt=()=>{
  let errtxt = 'Error';
  if(partData.responseDesc.length>0){    
    Object.keys(partData.responseDesc[0]).map((key)=>{
      errtxt = partData.responseDesc[0][key]
    })
  }
 
  return(
    errtxt
  )
}
const renderingTxt2=()=>{
  let errtxt = 'Success';
  if(partData.responseDesc!==''){
    
     
      errtxt = partData.responseDesc
    }
  
 
  return(
    errtxt
  )
}

const renderFooterBtn =() =>{
  // saveConfig({configData:svgViewerPartData.data.configData}
    // return(<> <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Cancel</button>
    //    <button type="button" className="btn btn-primary" disabled={error} onClick={(e)=>handleSaveEvent(e)} ><i className='now-ui-icons arrows-1_cloud-upload-94' ></i> &nbsp;Continue </button></>
    //    )
  if(partData.responseType==='' && partData.loading===false){
    return(<> <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Cancel</button>
    <button type="button" className="btn btn-primary" onClick={(e)=>handleSaveEvent(e)} ><i className='now-ui-icons arrows-1_cloud-upload-94' ></i> &nbsp;Continue </button></>)
  }
  else if(partData.responseType==='Failed' && partData.loading===false){
    return(
      <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Close</button>
    )
  }
  else if(partData.responseType==='success' && partData.loading===false){
    return(
      <button type="button" className="btn btn-primary"   onClick={(e)=>{props.closeModal(e)}} > &nbsp;Ok</button>
    )
  }
  else if(partData.loading===true){
    return(<> <button type="button" className="btn btn-secondary"  disabled><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Cancel</button>
    <button type="button" className="btn btn-primary" disabled><i className='now-ui-icons arrows-1_cloud-upload-94' ></i> &nbsp;Continue </button></>)
  }
}
  return (
    <div className="modal fade show" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"><i className={props.modal.icon}></i> {props.modal.title}</h5>
            <button type="button" className="close"   aria-label="Close" onClick={(e)=>{props.closeModal(e)}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="card downloadFile" style={{'boxShadow':'none'}}>
                  {renderCardBody()}                  
                </div>
              {error && 
                <p style={{'color':'red',background: "#f0eeee", padding:'10px'}}>Configuration file name is mandatory,<br /> 
                <small style={{color:'#000', textTransform:'none', }}>Close this popup window and enter a name for your configuration file</small>
                </p>
              }  
          </div>
          <div className="modal-footer">
           {renderFooterBtn()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveConfigPopup;
