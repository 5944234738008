import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateSvgSelectedLayers, deleteSvgSelectedLayer, resetLayerVisibility, updateLayerVisibility } from '../../axios/index'
import fullscreen from './fullscreen.svg'
import eyeClosed from './eye-closed.svg';
import eyeOpen from './eye-open.svg';
import "./index.css";
function Layers(props) {
  const dispatch = useDispatch();
  const selectedlayer = useSelector((state) => state.svgSelectedLayers)   
  const svgSource = useSelector((state) => state.svgViewerData)
  const svgLayerVisibility = useSelector((state)=>state.svgLayerVisibility.data)

  const selectedlayerData = [] 


  const handlelayerSelections = (path, eventType, event) => {
    event.stopPropagation();
    let keyboardKey = 'none'
    if (event.nativeEvent.ctrlKey) {
      keyboardKey = 'ctrlKey';
  }
  else if(event.nativeEvent.altKey){
    keyboardKey = 'altKey';
  }
    if(eventType==='toggleVisibility'){

    }  
      let part, parentIndex,childIndex, pathType; 
      part = path.g.part;
      parentIndex=  path.index;
      childIndex = -1;
      pathType = path.g.part     
      let visible  = selectedlayer.data[part] !== undefined?!selectedlayer.data[part]['visible']:true; 
     
    if (selectedlayer.data[part] !== undefined && eventType==='toggleSelect') {       
           dispatch(deleteSvgSelectedLayer({key:part, keyboardKey:keyboardKey}))  
    }
    else{  
            
       dispatch(updateSvgSelectedLayers({key:part, data:{ status: true,visible:visible, parentIndex: parentIndex, childIndex: childIndex }, keyboardKey:keyboardKey}))
       
    }
    
     
 }


  const toggleLayerVisibility = (key) => {

    dispatch(updateLayerVisibility({type:'single', key:key}))
  
 }

 const toggleVisibilityIcon = (assembly) => {
  
}
 

const togglePartVisibility = (g, index) => {
  

}

  const renderLayers = () => {
    if (svgSource.loading===false && svgSource.error===false  && Object.keys(svgSource.data.assemblyData.data).length > 0) {
       return svgSource.data.assemblyData.data.assembly.map((g, index) => {
          return (
             <li>
                <img onClick={(e) => toggleLayerVisibility(g.part)} className='vivbilityIcon' src={((svgLayerVisibility.all===false) ||  (svgLayerVisibility.all===true && svgLayerVisibility.others[g.part]!==undefined)) ? eyeClosed:eyeOpen} alt="Visible" />
                <span>
                   <input type="checkbox"
                      checked={selectedlayer.data?.[g.part] !== undefined ? selectedlayer.data[g.part]['status'] : false} onChange={(e) => handlelayerSelections({g:g,index:index}, 'toggleSelect', e)} />
                </span>
                <span>{g.part}</span>
             </li>)
       })
    }
 }

  return (<>
    {/* <ul className='title'>
      <li>
        <img className='vivbilityIcon' src={eyeOpen} alt="Visible" />
        <span>
          <input type="checkbox" />
        </span>
        <span>All Layers</span>
      </li>

    </ul>

    <ul className='paths'>
      {renderLayers()}      
     
    </ul> */}
     {renderLayers()}    
  </>
  );
};

export default Layers;
