
import React, { useEffect, useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import Multiselect from 'multiselect-react-dropdown';
import { CSSTransition } from 'react-transition-group';

import cloneDeep from 'lodash/cloneDeep';
import {reaetSendRecordDataToBackEndReducer, fetchsearchResultsFromMaterialDB, 
  getMaterialPropertyDetailById, fetchsearchResultsFromSamDB,
  resetMaterailSearchReucer, fetchAutorTypes,fetchMaterialTypes, fetchMaterialColumns,setSamData, 
  getSamPropertyDetailById, fetchsearchResultsFromFiberDB, fetchAverageFiberResultsFromCalculator, 
  getFiberPropertyDetailById, fetchsearchResultsFromLabvantageAPi, fetchCalulatorApiResult, resetCalulatorApiResult, getCompressionData, updateSearchKey, resetCalulatorApiResultToInintal} from '../../axios/index'  
import {Link, useParams} from "react-router-dom";
import DataGrid from "../tables/dataGrid";
import BarChart from "../charts/lineChart"
import loader from '../../loader.gif';
import './index.css';
import { ExceptionMap } from "antd/lib/result";
import Graphs from "./graphs";
 
const expectionColumns = ['SamRadius']
const options= ['manoj', 'ramesh'] 
function AdvanceSearch(props) {  
  const dispatch = useDispatch();
  let urlParams = useParams();
  const userInfo = useSelector((state) => state.userInfo)
  const tableData = useSelector((state)=> state.materialSearch);  
  const queryIdForSelectedPropertyRow=useSelector((state)=> state.QueryIdForSelectedProperty);
  const calulatorApiResultData = useSelector((state)=> state.CalulatorApiResult);
  const defaultDropdownOptions = useSelector((state)=> state.materialSearch.metaData);
  const columnsList = useSelector((state)=> state.materialSearch.metaData.materialColumnTypes);
  const defaultMetaDataFieldsList = useSelector((state)=> state.defaultMetaDataFields);  
  //const materialTypes = useSelector((state)=> state.materialSearch.metaData.materialTypes);
  const others = useSelector((state)=> state.materialSearch.others);
  //const authors = useSelector((state)=> state.materialSearch.metaData.author);
  
  const [saveOptions, setSaveOptions] = React.useState('newVersion');
  const [statusType, setStatusType] = React.useState('inDraft');
  const [selectedColumns, setSelectedColumns] = React.useState({})
  const [advanceSearchFieldValues, setAdvanceSearchFieldValues] =  React.useState({})
  const [dropDownVisibility, setDropDownVisibility] = React.useState(false)
  const [selectedRowKeys, setSelectedRowKeys] =React.useState([])
  const [searchType, setSearchType] =React.useState(props.modal.module)
  const [fiberAvgError, setFiberAvgError] =React.useState({code:'', response:'', type:''})
  const [showLabvantageInputs, setShowLabvantageInputs] =React.useState(false)
  const [labVantageActiveTab , setLabVantageActiveTab] =React.useState({})
  const [labVantageInputs, setLabVantageInputs]=React.useState({}) 
  const [labvantageSelectedRowForCalc, setLabvantageSelectedRowForCalc] = React.useState(queryIdForSelectedPropertyRow.data)
  const [showLabVantageCalculatorButton, setShowLabVantageCalculatorButton] = React.useState(false);
  const [autoCallCalulator, setAutoCallCalulator] = React.useState(false);
  const [otherSourceType, setOtherSourceType] = React.useState('labvantage');
  const [detectedDateChangeEvent, setDetectedDateChangeEvent] = React.useState([]);
  const graphDetails = useSelector((state)=>state.graphData)
  const graphInitialEquations = useSelector((state)=>state.graphData.data)

  

  const [dataSet, setMaterialDataSet] = React.useState(props.moduleDefaultFieldDataSet)
  console.log('inside detai', graphDetails)
  // const handleEvent = (status)=>{
  //   return props.handleEditStatus(status)
  
  // }
  // const handleSave = (e) =>{
  //   return props.handleSaveEvent(true)
  // }
  useEffect(() => {
    dispatch(updateSearchKey(''))

    if(searchType==='material'){     
      fetchMaterialMetaData();
    }
    }, [])
    
    useEffect(() => {
      if(queryIdForSelectedPropertyRow.data.labVantageDetail!==undefined && queryIdForSelectedPropertyRow.data.labVantageDetail.data.length>0 ){     
        let tempDefaultAdvanceSearchFieldValues={}
        setLabvantageSelectedRowForCalc(queryIdForSelectedPropertyRow.data.labVantageDetail.data[0])
        queryIdForSelectedPropertyRow.data.labVantageDetail.data.map((item, index)=>{
          if(index === 0  && Object.keys(labVantageActiveTab).length===0){
            setLabVantageActiveTab(item)
          }
          tempDefaultAdvanceSearchFieldValues[item.dbColumnName]= null;
        })
        setLabVantageInputs(tempDefaultAdvanceSearchFieldValues)
      }
      if(queryIdForSelectedPropertyRow.data.calculatorDetail!==undefined){
         let tempDefaultAdvanceSearchFieldValues={}
         queryIdForSelectedPropertyRow.data.calculatorDetail.data.map((item, index)=>{
          tempDefaultAdvanceSearchFieldValues[item.dbColumnName] = item.fieldDefaultValue;
         })
         setAdvanceSearchFieldValues(tempDefaultAdvanceSearchFieldValues) 
      }
      if(queryIdForSelectedPropertyRow.data.calculatorDetail!==undefined && queryIdForSelectedPropertyRow.data.labVantageDetail!==undefined &&  queryIdForSelectedPropertyRow.data.calculatorDetail.totalCount!==undefined && queryIdForSelectedPropertyRow.data.calculatorDetail.totalCount === 0 && queryIdForSelectedPropertyRow.data.labVantageDetail.totalCount===0){
        let calcParams = {}
        setAutoCallCalulator(true)
        dispatch(resetCalulatorApiResult())
        calcParams.calcName = queryIdForSelectedPropertyRow.data.calculatorDetail.calculatoraName;
        dispatch(fetchCalulatorApiResult(calcParams))
        setSelectedRowKeys([-1])
         
      }  
      // if(queryIdForSelectedPropertyRow.data.calculatorDetail!==undefined && queryIdForSelectedPropertyRow.data.labVantageDetail!==undefined &&  queryIdForSelectedPropertyRow.data.calculatorDetail.totalCount!==undefined && queryIdForSelectedPropertyRow.data.calculatorDetail.totalCount !=0 && queryIdForSelectedPropertyRow.data.labVantageDetail.totalCount===0){
      //   setShowLabvantageInputs(false)    
      //   dispatch(resetMaterailSearchReucer({})) 
      //   setShowLabVantageCalculatorButton(true)
      //   setSelectedRowKeys([-1])
      // } 
      }, [queryIdForSelectedPropertyRow])

    
  const fetchMaterialMetaData =()=>{
    dispatch(fetchMaterialTypes());
    dispatch(fetchAutorTypes());
    dispatch(fetchMaterialColumns()); 
  }  
  const handleSearchTypeChangeEvent=(e)=>{
     dispatch(resetMaterailSearchReucer({}))  
     setSelectedColumns({})
     setSelectedRowKeys([])
     setAdvanceSearchFieldValues({})
     dispatch(fetchAutorTypes());
     setFiberAvgError({code:'', response:'', type:''})
     if(e.target.value==='material'){      
      fetchMaterialMetaData();
     }
     
     setSearchType(e.target.value)
  }
  const handleFiberMassPercentChangeEvent=(e, key) =>{
    setFiberAvgError({code:'', response:'', type:''})
    let tempCol = cloneDeep(selectedColumns);
    tempCol[key] =  {id:key, percentage:Number(e.target.value)}
    setSelectedColumns(tempCol);
  }
  const handleRunCalcEvent =()=>{
   if((searchType==='labvantage' || searchType==='compression') && queryIdForSelectedPropertyRow.data.calculatorDetail!==undefined && props.modal.calulatorForm===true && showLabVantageCalculatorButton===true){
      let tempLabVantageInputs = cloneDeep(labVantageInputs);
      let tempAdvanceSearchFieldValues = cloneDeep(advanceSearchFieldValues);
      let calcParams = {}
    //     "calcName": "SamRadius",
    //     "params": {
    //          "Screen020":"1.12",
    // "Screen030":"26.2",
    // "Screen050":"44.38",
    // "Screen170":"28.1",
    // "Screen325":"0.18",
    // "ScreenPan":"0.02"
        
    // }
    
    calcParams.calcName = queryIdForSelectedPropertyRow.data.calculatorDetail.calculatoraName;
    //calcParams.params= tempLabVantageInputs
    calcParams.params= tempAdvanceSearchFieldValues
    Object.assign(calcParams.params, tempLabVantageInputs);
    if(searchType==='compression' && queryIdForSelectedPropertyRow.data.calculatorDetail.data.length>0){
      calcParams.params.equationType = queryIdForSelectedPropertyRow.data.calculatorDetail.data[0]['equationType']
    }
    
    dispatch(resetCalulatorApiResult())
    dispatch(fetchCalulatorApiResult(calcParams))
   
    
    }  
  }
  const handleUseSectionEvent = ()=>{
    let tempKey = cloneDeep(selectedRowKeys);
    tempKey=Number(tempKey[0]);
    if(searchType=='material'){
        dispatch(getMaterialPropertyDetailById({"id" : tempKey})).then((response)=>{
          if(response.data.responseType=='success'){       
            props.closeAdvancedSearchModal(false)
          }
          
        })
      }  
      else if(searchType=='sam'){      
        
        dispatch(getSamPropertyDetailById({"id" : tempKey})).then((response)=>{
          if(response.data.responseType=='success'){     
            props.importSearchResultSet({moduleType:searchType, dataSet:response.data.responseData})
            //dispatch(setSamData(response.data.responseData.sam))
           props.closeAdvancedSearchModal(false)
          }
          
        }) 
      }
      else if(searchType=='fiber'){
        dispatch(getFiberPropertyDetailById({"id" : tempKey})).then((response)=>{
          if(response.data.responseType=='success'){     
            props.importSearchResultSet({moduleType:'fiberDefault', dataSet:response.data.responseData})
            //dispatch(setSamData(response.data.responseData.sam))
           props.closeAdvancedSearchModal(false)
          }
          
        })
      }
      else if(searchType=='labvantage'){
        tableData.data.rows.map((row, index)=>{
          if(row.key == tempKey){
            tempKey = index;
          }
        })
        let tempDataSource = cloneDeep(tableData.data.dataSource);
        tempDataSource.metaData.author = userInfo.sAMAccountName;
        tempDataSource.metaData.creationDate = tableData.data.rows[tempKey]['completeddt']; 
        tempDataSource.metaData.repCount = tableData.data.rows[tempKey]['replicateid']; 
        tempDataSource.metaData.requestDate = tableData.data.rows[tempKey]['completeddt']; 
        tempDataSource.metaData.sampleId = tableData.data.rows[tempKey]['keyid1'];
        
        
        props.importSearchResultSet({moduleType:'labvantage', dataSet:tableData.data.rows[tempKey],dataSource:tempDataSource })
        //props.closeAdvancedSearchModal(false)
      }  
  }
  const handleUseSectionEventForCalcResults=()=>{
    if(searchType=='labvantage' || searchType==='compression'){
       
      let tempCalulatorApiResultData = cloneDeep(calulatorApiResultData);
      // tempDataSource.metaData.author = userInfo.sAMAccountName;
      // tempDataSource.metaData.creationDate = tableData.data.rows[tempKey]['completeddt']; 
      // tempDataSource.metaData.repCount = tableData.data.rows[tempKey]['replicateid']; 
      // tempDataSource.metaData.requestDate = tableData.data.rows[tempKey]['completeddt']; 
      // tempDataSource.metaData.sampleId = tableData.data.rows[tempKey]['keyid1'];
      
      
      props.importSearchResultSet({moduleType:'labvantageCalcResults', dataSet:tempCalulatorApiResultData})
      //props.closeAdvancedSearchModal(false)
    }  
  }
  const handleUseAvgFiberEvent = ()=>{
    if(searchType=='fiber' && fiberAvgError.type==='success' && fiberAvgError.response!='' & fiberAvgError.response.rows.length>0){
      props.importSearchResultSet({moduleType:searchType, dataSet:fiberAvgError.response.rows[0], dataSource:fiberAvgError.response.dataSource})
      props.closeAdvancedSearchModal(false)
    }
  }
  const onSelectChange = (newSelectedRowKeys) => {
    if(searchType==='fiber'&& newSelectedRowKeys.length<=3){   
       
      setSelectedRowKeys(newSelectedRowKeys)
    }
    else if(searchType!=='fiber' && searchType!=='labvantage'){
      setSelectedRowKeys(newSelectedRowKeys)
    }
    else if(searchType!=='fiber' && searchType==='labvantage'){       
      setSelectedRowKeys(newSelectedRowKeys)
    }  
    dispatch(resetCalulatorApiResultToInintal())  
  };
  const handleManualInput=(e, currentManulaInput, itemKey)=>{
    let tempKey = cloneDeep(labVantageInputs);
    if(tempKey[labVantageActiveTab.dbColumnName]===null){
      tempKey[labVantageActiveTab.dbColumnName] = {}    }
    
    tempKey[labVantageActiveTab.dbColumnName][itemKey]=e.target.value
    setLabVantageInputs(tempKey)
    setSelectedRowKeys([-1])
  }
  const onSelectRowEvent = (record) => {
    //let tempList = cloneDeep(selectedRowKeys);
    //tempList.push(record.key);
    // let tempList = cloneDeep(selectedRowKeys);
    // if (tempList.indexOf(record.key) >= 0) {
    //   tempList.splice(tempList.indexOf(record.key), 1);
    // } else {
    //   tempList.push(record.key);
    // }
    if(searchType!=='fiber' && searchType!=='labvantage' && searchType!=='compression'){       
      setSelectedRowKeys([record.key])
    }
    else if( searchType==='labvantage' && otherSourceType!=='others'){
      if(showLabvantageInputs ===true){
        //record.avgvalue
        let tempKey = cloneDeep(labVantageInputs);
        if(labVantageActiveTab.dbColumnName === 'SamRadius' || labVantageActiveTab.dbColumnName === 'Times/TimedAUL') {
          tempKey[labVantageActiveTab.dbColumnName] = record.keyid1
        } else tempKey[labVantageActiveTab.dbColumnName] = record.avgvalue
        setLabVantageInputs(tempKey)
                
      }
      setSelectedRowKeys([record.key])
    }
    else if( searchType==='labvantage' && otherSourceType==='others'){
      if(showLabvantageInputs ===true){
        //record.avgvalue
        let tempKey = cloneDeep(labVantageInputs);
        if(labVantageActiveTab.dbColumnName === 'SamRadius' || labVantageActiveTab.dbColumnName === 'Times/TimedAUL') {
          tempKey[labVantageActiveTab.dbColumnName] = record.keyid1
        } else tempKey[labVantageActiveTab.dbColumnName] = record.target.value
        setLabVantageInputs(tempKey)
        
      }
      setSelectedRowKeys([record.key])
      
    }
    else if(searchType==='compression'){
      if(showLabvantageInputs ===true){
        //record.avgvalue
        let tempKey = cloneDeep(labVantageInputs);
        tempKey['sampleId'] = record.sampleId
        setLabVantageInputs(tempKey)
      }
     
      setSelectedRowKeys([record.key])
    }
    else if(searchType==='fiber'&& selectedRowKeys.length<3){
      let tempList = cloneDeep(selectedRowKeys);
      let itemIndex = tempList.indexOf(record.key);
      if (itemIndex === -1) {
        tempList.push(record.key);
      }
      else{
        tempList.splice(itemIndex, 1)
      }
      
      setSelectedRowKeys(tempList)
    }
    dispatch(resetCalulatorApiResultToInintal())    
    
  };
  const handleRowSelection = {
    selectedRowKeys,
    type:'radio',
    onChange: onSelectChange
  };
    
const updateAdvancedSearchFieldValues=(e, item)=>{
   let tempList = cloneDeep(advanceSearchFieldValues);   
  if(e.target.value == '' || e.target.value==null ){
     if(tempList[item.key]!==undefined){
      delete tempList[item.key]
     }
     
  }
  else{
    tempList[item.key] = e.target.value;
  }
  
  setAdvanceSearchFieldValues(tempList)
  dispatch(resetCalulatorApiResultToInintal())

} 

const renderGraphOptions = () => {
  if(graphDetails && graphDetails.data) {
    return graphDetails.data.graphData.map(option => {
      return <option value={option['graphId']}>{option['graphName']}</option>
    })
  }
}


const renderSelectOptions=(item)=>{
    if((defaultDropdownOptions[item.key]).length>0){
      return  (defaultDropdownOptions[item.key]).map((option)=>{
        return(<option value={option}>{option}</option>)
      })
  }
 
} 
const renderAuthors=()=>{
  // if(authors.length>0){
  //     return  authors.map((item)=>{
  //       return(<option value={item}>{item}</option>)
  //     })
  // } 
} 
const handleSearchActionForSelectedRow = ()=>{
  let reqParams = cloneDeep(advanceSearchFieldValues)  
  let tempKey = cloneDeep(selectedRowKeys);
  
   
  if(searchType=='material'){
    tempKey=Number(tempKey[0]);
    reqParams.ranges = cloneDeep(selectedColumns)
    let tempColumns = cloneDeep(columnsList);
    tableData.data.rows.map((filteredRow)=>{
      if(filteredRow.key==tempKey){
        return tempColumns.map((item)=>{      
          if(filteredRow[item.dbColumnName]!==undefined){
            reqParams.ranges[item.dbColumnName] =item;
            reqParams.ranges[item.dbColumnName]['target'] = filteredRow[item.dbColumnName]
          }
      })
      }
    })
    dispatch(fetchsearchResultsFromMaterialDB({advanceSearch:reqParams}))
    setSelectedColumns(reqParams.ranges)
    setSelectedRowKeys([])
  }
  else if(searchType=='fiber'){
    setFiberAvgError({code:'', response:'', type:''})
    let tempSelectedColumns = cloneDeep(selectedColumns);
    let req = [];
    let sum = 0;
    tempKey.map((item)=>{
      req.push(tempSelectedColumns[item])
      sum = sum+tempSelectedColumns[item]['percentage']
    })
    if(sum>100 || sum<100){
      setFiberAvgError({code:'300', response:'Sum of percentages of Mass should be equal to 100', type:'error'})
    }
    else{
      dispatch(fetchAverageFiberResultsFromCalculator({fiber:req})).then((req)=>{
        if(req.request.status===200){
          setFiberAvgError({code:req.request.status, response:req.data.responseData, type:'success'})
        }
        else{        
          setFiberAvgError({code:req.request.status, response:req.request.response, type:'error'})
        }
        
      })
    }
    

  } 
}
const handleSearchAction=()=>{
  //props.modal.module
  
  if(searchType==='material'){
      let reqParams = cloneDeep(advanceSearchFieldValues)
      reqParams.ranges = cloneDeep(selectedColumns)      
      dispatch(fetchsearchResultsFromMaterialDB({advanceSearch:reqParams}))
      
  }
  else if(searchType==='sam'){
    let reqParams = cloneDeep(advanceSearchFieldValues)
    //reqParams.ranges = cloneDeep(selectedColumns)
     dispatch(fetchsearchResultsFromSamDB({advanceSearch:reqParams}))
  }
  else if(searchType==='fiber'){
    let reqParams = cloneDeep(advanceSearchFieldValues)
    //reqParams.ranges = cloneDeep(selectedColumns)
     dispatch(fetchsearchResultsFromFiberDB({advanceSearch:reqParams}))
  }
  else if(searchType==='labvantage'){
    let reqParams = cloneDeep(advanceSearchFieldValues)
    let queryId = '';
    reqParams.queryId = queryId
    // if(showLabvantageInputs==true && queryIdForSelectedPropertyRow.data.labVantageDetail!==undefined && queryIdForSelectedPropertyRow.data.labVantageDetail.data.length>0)
    // {
      queryId = labVantageActiveTab.queryId;
      reqParams.queryId = queryId
      
    // }
    // else{
    //   queryId = queryIdForSelectedPropertyRow.data.labVantageDetail.data[0]['queryId']
    // }
    dispatch(fetchsearchResultsFromLabvantageAPi(reqParams)).then((res)=>{
    
  
      //special case to handle palain JSON response
      // if(res.status===200 && res.data.responseType==='success'){
      //   if(res.data.responseData.rows===undefined && res.data.responseData.columns===undefined && labVantageActiveTab.dbColumnName === 'SamRadius'){
      //     setLabVantageInputs({SamRadius:res.data.responseData})
      //     setSelectedRowKeys([-1])
      //   }
      //   else{
      //     //do mothing
      //   }
      // }
    
    })
  
  
  } else if(searchType==='compression') {
    let reqParams = cloneDeep(advanceSearchFieldValues)
    let tempParams = {}
    
    for(let tempKey in reqParams ) {
        let index =  others[searchType].findIndex(i => {
          return i.key == tempKey
        })
        if(index>-1) tempParams[tempKey] = reqParams[tempKey]
    }
    // others[searchType].map(data => {
    //   console.log('dataa', data)
    // })
    dispatch(getCompressionData({advanceSearch:tempParams}))
  }
  
}
const handleColumnSelection = (item)=>{
  let tempList = cloneDeep(selectedColumns);
   
  if( tempList.hasOwnProperty(item.dbColumnName)){
    delete tempList[item.dbColumnName];
    setSelectedColumns(tempList)
  } 
  else{
   
    tempList[item.dbColumnName] = item
    tempList[item.dbColumnName]['min']=null;
    tempList[item.dbColumnName]['max']=null;
    tempList[item.dbColumnName]['target']=null;
    setSelectedColumns(tempList)
  }
}
const renderColumnNames = ()=>{
    if(columnsList.length>0){
      return columnsList.map((item, index)=>{
        return(
          <li className='customMultiSelectListItem' onClick={(e)=>handleColumnSelection(item)}>
            <input type='checkbox' className=''  checked={selectedColumns.hasOwnProperty(item.dbColumnName)?'checked':''} /> <span>{item.fieldLabel}</span>
          </li>
        )
      })
    }
}
const renderMultiselectDropdown = () =>{
  return(
    <div className='col-12'>  
      <div className="customMultiSelectContainer"> 
          <div className="form-group customMultiSelectGroup">
              <label>Select target columns</label>
              <div className={dropDownVisibility?'customMultiSelectButton active':'customMultiSelectButton'} onClick={(e)=>setDropDownVisibility(!dropDownVisibility)}>
               {Object.keys(selectedColumns).length>0?Object.keys(selectedColumns).length + ' Columns selected':'Choose...'}
               </div>
            </div>
            <CSSTransition
                in={dropDownVisibility}
                timeout={300}
                classNames="animation-basic"
                unmountOnExit        
            >  
              <div className='customMultiSelectList'>
                  <div className='customMultiSelectLabel'>Select Columns to set Minimum and maximum ranges</div>
                  <ul>{renderColumnNames()}</ul>
              </div>
            </CSSTransition>
        
      </div>
    </div>
  )
}



const updateValue = (e, key, type)=>{
  let tempList = cloneDeep(selectedColumns);
  tempList[key][type] = e.target.value;
  setSelectedColumns(tempList)}
const renderMinMaxSelection = () =>{
  if(Object.keys(selectedColumns).length>0){
    return Object.keys(selectedColumns).map((key)=>{
      let column = selectedColumns[key]
      return(
        <div className="col-md-12">					
          <div className="form-group minMaxInputFormGroup" key={column.dbColumnName}>
            <label>{column.fieldLabel}</label>
            <div className='minMaxInputGroup'>
                  <div className='minMax'>
                      <span>Min</span> 
                      <input type="number" className="form-control" value={column.min} onChange={(e)=>updateValue(e, column.dbColumnName, 'min')}/>
                  </div>
                  <div className='minMax'>
                      <span>Max</span> 
                      <input type="number" className="form-control" value={column.max} onChange={(e)=>updateValue(e, column.dbColumnName, 'max')}/>
                  </div>  
                  <div className='minMax'>
                      <span>Target</span> 
                      <input type="number" className="form-control" value={column.target} onChange={(e)=>updateValue(e, column.dbColumnName, 'target')}/>
                  </div>                             
            </div>
          </div>
        </div>
      )
    })
  }
}
const renderInputField=(item)=>{
  if(item.type=='dropdown'){
    return(
      <select className="custom-select" id={item.key} key={item.key} value={advanceSearchFieldValues[item.key]!==undefined?advanceSearchFieldValues[item.key]:null} onChange={(e)=>updateAdvancedSearchFieldValues(e, item)} >
          <option selected="" disabled="" value="">Choose...</option>
          {renderSelectOptions(item)}
      </select>
    )
  }
  if(item.type == 'date') {
    if(advanceSearchFieldValues[item.key] == undefined && item.value !== undefined && detectedDateChangeEvent.length<=2) {
      updateAdvancedSearchFieldValues( 
        {
      target: {
        value: item.value
      }
    
      }, item)
      let tempVar = cloneDeep(detectedDateChangeEvent);
      tempVar.push(item);
      setDetectedDateChangeEvent(tempVar)

    }
    // let value = advanceSearchFieldValues[item.key]!==undefined?advanceSearchFieldValues[item.key]: item.value
    // if(item.value) advanceSearchFieldValues[item.key] = item.value
    return( <input disabled = {item.editable ? true : false} type={item.type} id={item.key} key={item.key} className="form-control" defaultValue = {item.value} value={advanceSearchFieldValues[item.key]!==undefined?advanceSearchFieldValues[item.key]:null}  onChange={(e)=>updateAdvancedSearchFieldValues(e, item)} />)
  }
  else{

    let value = advanceSearchFieldValues[item.key]!==undefined?advanceSearchFieldValues[item.key]: (props.advancedSearchMetaData.key && props.advancedSearchMetaData.key.toLowerCase().includes('wet') && item.key == 'testState') ? 'wet' : (props.advancedSearchMetaData.key && props.advancedSearchMetaData.key.toLowerCase().includes('dry') && item.key == 'testState') ? 'dry' :null
    if(item.key == 'testState') advanceSearchFieldValues.testState = value
    // advanceSearchFieldValues[item.key] 
    return( <input disabled = {item.editable ? true : false} type={item.type}  id={item.key} key={item.key} className="form-control" value={value}  onChange={(e)=>updateAdvancedSearchFieldValues(e, item)} />)
  }
}

 const renderSearchFields =()=>{
   let classValue  = (searchType=='material'?'col-12 pr-3':'col-6 pr-1');
   if(otherSourceType==='labvantage'){
    if(others[searchType]) {
    return (others[searchType]).map((item)=>{
      return(
        <div className={classValue}>
          <div className="form-group">
              <label>{item.label}</label>
              {renderInputField(item)}              
          </div>
        </div>
      )
    })}
  }
  else if(otherSourceType==='others'){
    let val = '';

    
    if(labVantageInputs[labVantageActiveTab.dbColumnName]!==null &&  labVantageInputs[labVantageActiveTab.dbColumnName]!==undefined){
      val = labVantageInputs[labVantageActiveTab.dbColumnName]
    }

    // <table className="table table-bordered table-sm labVantageFieldsTable">
    //       {rowColumnHeader.length>0?(
    //         <thead>
    //         <tr>    
    //           <th scope="col">{rowColumnHeader[0]}</th>
    //           <th scope="col">{rowColumnHeader[1]}</th>
    //         </tr>
    //       </thead>
    //       ):null}          
    //       <tbody>
    //         {renderLabvantageRows()}
    //       </tbody>
    //     </table>

    if(queryIdForSelectedPropertyRow.data.labVantageDetail.showManualInput===true){
      return Object.keys(queryIdForSelectedPropertyRow.data.labVantageDetail.manualInput).map((manualInputKey, index)=>{
        let currentManulaInput  = queryIdForSelectedPropertyRow.data.labVantageDetail.manualInput[manualInputKey];
        let  tableColumns = manualInputKey.split("/");
        if(tableColumns[1]===undefined){
          tableColumns[1] = 'Value'
        }
        return(
          <table className="table table-borderless table-sm labVantageFieldsTable" style={{'maxWidth':'700px', marginLeft: "20px"}}>
            <thead>
                <tr>    
                  <th scope="col">{tableColumns[0]}</th>
                  <th scope="col">{tableColumns[1]}</th>
                </tr>
            </thead>
            <tbody>
               {renderManualInputFields(currentManulaInput, val)}
           </tbody>

          </table>
        )
      
      })
    }
    else{
      return(
        <div className={classValue}>
          <div className="form-group">
                <label>{labVantageActiveTab.fieldLabel}</label>
                <input type='number'  className="form-control" onChange={(e)=>onSelectRowEvent(e)} value={val}/>
            </div>
        </div>    
      )
    }
  }
 }
 const renderManualInputFields=(currentManulaInput, val)=>{    
  
    return Object.keys(currentManulaInput).map((itemKey)=>{
      let tempVal = '';
      if(val !== null && typeof val === 'object' &&  val[itemKey]!==undefined){
        tempVal = val[itemKey] 
      }
     
      return(
        <tr className="form-group">
            <td><label>{itemKey}</label></td>
            <td><input type='number'  className="form-control" onChange={(e)=>handleManualInput(e, currentManulaInput, itemKey)} value={tempVal}/></td>
        </tr>
      )
    })
 }

const renderMaterialColumnContainer=()=>{
  if(searchType=='material'){
    return(
      <div className='col-7 columnOuterContainer'>                          
          {renderMultiselectDropdown()}
          <div className='row fixedHeight'>
          {renderMinMaxSelection()}     
          </div>           
      </div>
    )
  }
}
const renderLabvantageUserInputs =(item)=>{
  if(labVantageInputs[item.dbColumnName]!==null && typeof(labVantageInputs[item.dbColumnName])=='object' &&   Object.keys(labVantageInputs[item.dbColumnName]).length>0){
    let rows =  Object.keys(labVantageInputs[item.dbColumnName]).map((itemKey)=>{
      return(<tr style={{'borderBottom':'1px solid #dee2e6', 'textAlign':'center'}}>
        <td >{itemKey}</td>
        <td>{labVantageInputs[item.dbColumnName][itemKey]}</td>
      </tr>)
    })
    let  tableColumnsHeader = item.dbColumnName.split("/");
    return(<td>
            <table className="table table-borderless table-sm labVantageFieldsTable">
              <thead>
                <tr style={{'borderBottom':'1px solid #dee2e6'}}>
                  <th>{tableColumnsHeader[0]}</th>
                  <th>{tableColumnsHeader[1]}</th>
                </tr>
              </thead>
              <tbody>
                   {rows}
              </tbody>    
            </table>
          </td>)
  }
  else{
    return(
      <td>&nbsp; {labVantageInputs[item.dbColumnName]!==null?labVantageInputs[item.dbColumnName]:'-'} </td> 
    )
  }
}
const renderLabvantageRows = () =>{
  if(searchType!=='compression'){
    
      return queryIdForSelectedPropertyRow.data.labVantageDetail.data.map((item, index)=>{
        // if(item.dbColumnName!=='SamRadius'){
     
        return(
          <tr>
            {/* <td><div className="btnDiv" onClick={(e)=>setShowLabvantageInputs(item)}>Get</div> </td> */}
            <td>{item.fieldLabel}</td>
            {renderLabvantageUserInputs(item)}
          </tr>
        )
      })
       
  }   
  else if(searchType==='compression'){
    return(
      <tr>
        {/* <td><div className="btnDiv" onClick={(e)=>setShowLabvantageInputs(item)}>Get</div> </td> */}
        <td>Sample ID</td>
        <td>&nbsp; {labVantageInputs.sampleId!==null?labVantageInputs.sampleId:'-'}</td>
      </tr>
    )
  }    
}
const renderProperties=(item)=>{
  if(labVantageInputs[item.dbColumnName]!==undefined && labVantageInputs[item.dbColumnName]!==null && Object.keys(labVantageInputs[item.dbColumnName]).length>0){
    return Object.keys(labVantageInputs[item.dbColumnName]).map((key, index)=>{                             
      return(
        <tr>
          <td>{key}</td>
          <td>&nbsp; {labVantageInputs[item.dbColumnName][key]}</td>
        </tr>
      )                           
    })
    
  }
}
const renderCalculatorFields = () =>{
  if(queryIdForSelectedPropertyRow.loading===false && queryIdForSelectedPropertyRow.responseType==='success'){
    if(queryIdForSelectedPropertyRow.data.calculatorDetail!==undefined && queryIdForSelectedPropertyRow.data.calculatorDetail.data.length>0){
       
     return(
      <div className='col-6 columnOuterContainer' style={{backgroundColor:'#fff'}}>                    
          <p className='pTitle'>Others</p>    
          {
            queryIdForSelectedPropertyRow.data.calculatorDetail.data.map((item, index)=>{
              let tempItem = {};
              tempItem.key =item.dbColumnName 
              return(
                <div className='col-12'>          
                <div className="form-group">
                    <label>{item.fieldLabel}</label>
                    <input type='number' id={item.dbColumnName} key={item.dbColumnName} className="form-control"    value={advanceSearchFieldValues[item.dbColumnName]!==undefined?advanceSearchFieldValues[item.dbColumnName]:null}  onChange={(e)=>updateAdvancedSearchFieldValues(e,tempItem)}/>
                            </div>
              </div>
              )
            })
          }

      </div>
     )
       
    }
  }  
}
const renderLabvantageFields =(rowColumnHeader)=>{
  if(queryIdForSelectedPropertyRow.loading===false && queryIdForSelectedPropertyRow.responseType==='success'){
    if(queryIdForSelectedPropertyRow.data.labVantageDetail!==undefined){
      return(
        <table className="table table-bordered table-sm labVantageFieldsTable">
          {rowColumnHeader.length>0?(
            <thead>
            <tr>    
              <th scope="col">{rowColumnHeader[0]}</th>
              <th scope="col">{rowColumnHeader[1]}</th>
            </tr>
          </thead>
          ):null}          
          <tbody>
            {renderLabvantageRows()}
          </tbody>
        </table>
      )
    }
  }
  
}
const handleLabVantageTabClick=(item)=>{
   setLabVantageActiveTab(item);   
   setSelectedColumns({})
   setSelectedRowKeys([])   
   dispatch(resetMaterailSearchReucer({})) 
}   
const showLabvantageInputsWinow=()=>{
  dispatch(resetMaterailSearchReucer({})) 
  setShowLabvantageInputs(true)  
  setShowLabVantageCalculatorButton(false)
}
const continueWithLabVantageSelections=(type)=>{
  let tempContinue = true;

  if(queryIdForSelectedPropertyRow.data.labVantageDetail.showManualInput===true  && otherSourceType ==='others'){     
     Object.keys(queryIdForSelectedPropertyRow.data.labVantageDetail.manualInput).map((itemKey, index)=>{
      Object.keys(queryIdForSelectedPropertyRow.data.labVantageDetail.manualInput[itemKey]).map((key, keyIndex)=>{
      let tempKey = cloneDeep(labVantageInputs);
     
      if(tempKey[itemKey] === undefined || tempKey[itemKey][key]===undefined || tempKey[itemKey][key]==null || tempKey[itemKey][key]==''){
        tempContinue = false;
      }

        })
      })
    } 
     
    if(tempContinue===true){
      setShowLabvantageInputs(false)    
      dispatch(resetMaterailSearchReucer({})) 
    
      setShowLabVantageCalculatorButton(true)
    }
}
const  renderLabvantageFieldsAsTabs = () =>{
  if(queryIdForSelectedPropertyRow.loading===false && queryIdForSelectedPropertyRow.responseType==='success'){
    if(queryIdForSelectedPropertyRow.data.labVantageDetail!==undefined){
      
      return queryIdForSelectedPropertyRow.data.labVantageDetail.data.map((item, index)=>{
        let tempClass ="lvRow"
        let checkBox = false;
        
        if(item.dbColumnName === labVantageActiveTab.dbColumnName){
          tempClass = 'lvRow activeItem'
        }
        if(labVantageInputs[item.dbColumnName]!==null){
          checkBox = true;
        }
        return(
          <div className={tempClass} onClick ={(e)=>handleLabVantageTabClick(item)} >
            <div className='lvCol'>{item.fieldLabel}</div>
            <div className='lvCol'> {checkBox===true?(<i class="now-ui-icons ui-1_check"></i>):'-'}</div>
          </div>
        )
      })
    }
  }
}
const renderMetaData = ()=>{
  return Object.keys(defaultMetaDataFieldsList.data.fields).map((itemKey, index)=>{
    let currentField = defaultMetaDataFieldsList.data.fields[itemKey]
    if(currentField.render===true && currentField.editable===false){
      return(
      <tr>
        <td><label>{currentField.label}</label></td>
        <td><input type={currentField.type} class="form-control"   value='' /></td>
      </tr>) 
    }
    else if(currentField.render===true && currentField.editable===true && currentField.type!=='textarea' ){
    return(
    <tr>
      <td><label>{currentField.label}</label></td>
      <td><input type={currentField.type} class="form-control"  style={{background:'#fff'}}  value='' /></td>            
    </tr>)
    }
    else if(currentField.render===true && currentField.editable===true && currentField.type==='textarea' ){
      return(
      <tr>
        <td><label>{currentField.label}</label></td>
        <td><textarea  class="form-control"  style={{background:'#fff', minWidth:'100%'}}  value=''  />    </td>        
         
      </tr>) 
      }
  })
}






const handleOtherSourceEvent =(e)=>{
  setOtherSourceType(e.target.value)
  setSelectedColumns({})
  setSelectedRowKeys([])   
  dispatch(resetMaterailSearchReucer({})) 
  let tempKey = cloneDeep(labVantageInputs);
  tempKey[labVantageActiveTab.dbColumnName] = null
  setLabVantageInputs(tempKey)
  dispatch(resetCalulatorApiResultToInintal())  
}

const renderLabvantageFieldsForCalc = () =>{
  if(props.modal.calulatorForm===true && showLabvantageInputs===true){
      
  
  return(
    <div className="col-12 labv">
      <div className='row'>
          <div className='col-12 labvantageContainer'>
            {renderLabvantageFieldsAsTabs()}
          </div>
          
          <div className='col-12 bordered-container'>

              <div className="row"> 
                  <div className='col-12 labvantageContainer'>
                      <div className="form-group select-flex">
                          <label><b>Source</b></label>
                          <select className="custom-select"  value={otherSourceType}  onChange={(e)=>handleOtherSourceEvent(e)} >
                            <option value="labvantage"> {props.modal.module!=='compression' ? 'Labvantage' : 'Compression' }</option>
                            {props.modal.module!=='compression' ?<option value="others">Others</option>:null}                          
                        </select>
                          
                      </div>
                  </div>
                  {renderSearchFields()} 
                  {otherSourceType==='others2'?
                  (<div className='col-12'> 
                    <br/>
                    <p><b>Meta Data for Input </b></p>
                    <table className='table table-bordered table-sm labVantageFieldsTable'>                              
                      {renderMetaData()}
                    </table>
                    </div>
                  )
                  :null}
                  
                 
              </div>

          </div> 
           
      </div>
  </div>
  )
  }
}
const renderSearchContent=()=>{
  if(props.modal.calulatorForm===false){
   let classValue  = (searchType=='material'?'col-5':'col-12');
    return(
      <div className="col-12">
        <div className='row'>
            <div className={classValue}>
                <div className="row"> 
                    {renderSearchFields()} 
                </div>
            </div> 
            {renderMaterialColumnContainer()}
        </div>    

    </div>
    )
    }
    
    else if(props.modal.calulatorForm===true){
      let tempClassName = 'col-6 columnOuterContainer';
      if(showLabvantageInputs===true){
        tempClassName = 'col-12 columnOuterContainer'
      }
      else if(showLabvantageInputs===false){
            if(queryIdForSelectedPropertyRow.data.calculatorDetail!==undefined && queryIdForSelectedPropertyRow.data.calculatorDetail.data.length==0){
              tempClassName = 'col-8 columnOuterContainer'
            }
            else{
              tempClassName = 'col-6 columnOuterContainer'
            }
      }
      
      return(
        <div className='col-12'>
          <div className='row'>
            {autoCallCalulator===false?(
              <div className='col-12'>
              <div className='calulcationParameters'>
                 <b>Calculation Parameters </b>                   
              </div>
           </div>
            ):null}
            
              <div className='col-12'>
                <div className='row'> 
                {/* { 
                  renderCompressionContainer(tempClassName)

                } */}
                { 
                  renderLabvantageFiledContainer(tempClassName)

                }
                  
                  {showLabvantageInputs===false?(                                  
                    renderCalculatorFields()                
                  ):null
                  }
                </div>

            </div>    
          </div>
          
        </div>
      )
    }  
}

const renderLabvantageFiledContainer=(tempClass)=>{
  if(queryIdForSelectedPropertyRow.loading===false && queryIdForSelectedPropertyRow.responseType==='success'){
    if(queryIdForSelectedPropertyRow.data.labVantageDetail!==undefined){
      let titletext = 'other sources';
      let tableColumnHeader = ['API', 'Value']
      // If copression is true
       // consolelog("gooo -4", )
        if(props.modal.module==='compression' || queryIdForSelectedPropertyRow.data.labVantageDetail.totalCount!==0)  {
          if(props.modal.module==='compression'){titletext  = 'Compression'; tableColumnHeader = []}
          
        return(
          <div className={tempClass} style={{backgroundColor:'#fff'}}>
                      
                      <p className='pTitle'>
                          <span>Inputs from {titletext}</span>
                          {showLabvantageInputs===false?(<span className='btnDiv' onClick={(e)=>showLabvantageInputsWinow()}>Search {titletext} </span>):null}
                          {showLabvantageInputs===true?(<span className='btnDiv' onClick={(e)=>continueWithLabVantageSelections(titletext)}>Continue </span>):null}
                      </p>
                        {showLabvantageInputs===false?renderLabvantageFields(tableColumnHeader):null}  
                        {showLabvantageInputs===true?renderLabvantageFieldsForCalc():null}            
                    </div>
        )
      }  
    
  }
    
   }
}
 

const renderSearchType=()=>{
  if(searchType!=='labvantage' && searchType!=='compression' && searchType !== 'graphVisualizer'){
    if(urlParams.moduleName==='material-details' || urlParams.moduleName=== "new-material"){
      return(
        <div className="col-12 ">
              <div className="form-group searchType">                 
                  <select className="custom-select" id="searchType" value={searchType} onChange={(e)=>{handleSearchTypeChangeEvent(e)}}>
                    <option disabled>Choose search type...</option>
                    <option value="material">Material</option>
                    <option value="sam">SAM</option>
                    <option value="fiber">Fiber</option>
                  </select>
                </div>
        </div> 
      )
    }
    else{
      return(
        <div className="col-12 ">
              <div class="form-group searchType">                 
                  <select className="custom-select" id="searchType" disabled value={searchType} >
                    <option value="">Choose search type...</option>
                    <option value="material">Material</option>
                    <option value="sam">SAM</option>
                    <option value="fiber">Fiber</option>
                  </select>
                </div>
        </div> 
      )
    }
  }
  else if(searchType==='labvantage'){
    
    if(queryIdForSelectedPropertyRow.data.labVantageDetail!==undefined && queryIdForSelectedPropertyRow.data.labVantageDetail.data.length>0 && queryIdForSelectedPropertyRow.data.labVantageDetail.totalCount ===1)
    {
      return(  <div className="col-12 ">
            <div class="form-group searchType">
                      {queryIdForSelectedPropertyRow.data.labVantageDetail.data[0]['fieldLabel']}
                  </div>
            </div>
        )  
     }
     else{
         return(<div className="col-12 "><br/></div>)
     }
     
  }  else if (searchType == 'graphVisualizer') {
    return(<Graphs dataSet = {props.moduleDefaultFieldDataSet} fields={props.fields}/>)
     
  }
  
}
const renderSearchActionButtonContainer = ()=>{
  if( props.modal.calulatorForm===false && Object.keys(advanceSearchFieldValues).length>=0 && otherSourceType==='labvantage'){
      return(
      <div className="col-md-12">
        {searchType !== 'graphVisualizer' &&
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e)=>{handleSearchAction()}}>Search {searchType}</button>
      }
                <hr />
      </div>)
  }  
  else if(props.modal.calulatorForm===true && Object.keys(advanceSearchFieldValues).length>0 && showLabvantageInputs===true && otherSourceType==='labvantage') {
     return(
        <div className="col-md-12">
                  <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e)=>{handleSearchAction()}}>Search {searchType}</button>
                <hr />
        </div>)


  }  
  else if((props.modal.calulatorForm===true  && showLabvantageInputs===true && otherSourceType==='labvantage') ){
    return(
      <div className="col-md-12">
                <button type="button" className="btn btn-primary" disabled data-dismiss="modal" >Search {searchType}</button>
              <hr />
      </div>)
  }
  else if(props.modal.calulatorForm===false && showLabvantageInputs===false && Object.keys(advanceSearchFieldValues).length==0 ){
    return(
      <div className="col-md-12">
                <button type="button" className="btn btn-primary" disabled data-dismiss="modal" >Search {searchType}</button>
              <hr />
      </div>)
  }
  
  
  //   let labVantageFieldValueupdated = false;
  //   Object.keys(labVantageInputs).map((item)=>{
  //     if(labVantageInputs[item]===null){
  //       labVantageFieldValueupdated =false;
  //     }
  //   })
  //   if(labVantageFieldValueupdated===true){
  //     return(
  //       <div className="col-md-12">
  //                 <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e)=>{handleSearchAction()}}>Search {searchType}</button>
  //               <hr />
  //       </div>)
  //   }
  // }  
}
const renderGridContent = ()=>{
 
  handleRowSelection.type=(searchType==='fiber'?'checkbox':'radio');
  if(tableData.loading==false && tableData.responseType=='success'){
    if(tableData.data.rows!=undefined && tableData.data.columns!==undefined){
      return(
        <div className='row sideBarContent'>        
          {<DataGrid  tableData = {tableData}  path ='material-details' enableRowSelection={true} handleRowSelection ={handleRowSelection} onSelectRowEvent={(record)=>onSelectRowEvent(record)}/>}    
        </div>	
      )
    }
    else{
      return(
        <div className='row sideBarContent'> 
           <div className='col-12'>
                <div className='row'>
                      <div className="col-12">
                        <table className="table table-bordered table-sm labVantageFieldsTable">
                          <thead>
                            <tr>    
                                    
                              <th scope="col">Property </th>
                              <th scope="col">Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.keys(tableData.data).map((key, index)=>{                             
                              return(
                                <tr>
                                  <td>{key}</td>
                                  <td>&nbsp; {tableData.data[key]}</td>
                                </tr>
                              )                           
                            })
                            }
                          </tbody>
                      </table>
                      </div>
                  </div>   
            </div>
        </div>	
      )
    }
 
  }
}
const renderFooterButtons=()=>{  
    if(selectedRowKeys.length>=1){
      return(
        <div className="row sideBarContent">	
            <div className="fixedbottom sl1" style={{position: 'fixed', bottom: '5px', display: 'block'}}>
              {searchType=='fiber' && selectedRowKeys.length>=2 ?(<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e)=>{handleSearchActionForSelectedRow()}}>Calculate Average</button>):null}
              {searchType=='fiber' && selectedRowKeys.length<2 ?(<button type="button" className="btn btn-primary" data-dismiss="modal" disabled>Calculate Average</button>):null}
              {searchType=='fiber' && selectedRowKeys.length>=2?(<button type="button" className="btn btn-success" data-dismiss="modal" disabled={fiberAvgError.type!=='success'?'disabled':null} onClick={(e)=>handleUseAvgFiberEvent()}>Use Calulated Average</button>):null}
              {searchType=='fiber' && selectedRowKeys.length==1 ?(<button type="button" className="btn btn-success" data-dismiss="modal" onClick={(e)=>{handleUseSectionEvent()}}>Use Selection</button>):null}
              {searchType=='fiber' && selectedRowKeys.length>1 ?(<button type="button" className="btn btn-success" data-dismiss="modal" disabled>Use Selection</button>):null}
              {searchType=='material'?(<button type="button" className="btn btn-primary" data-dismiss="modal" onClick={(e)=>{handleSearchActionForSelectedRow()}}>Set as Target</button>):null}
              
              {searchType!=='fiber' && showLabvantageInputs===false && showLabVantageCalculatorButton===false && autoCallCalulator===false?(<button type="button" className="btn btn-success" onClick={(e)=>handleUseSectionEvent()}>Use Selection</button>):null}
              {(searchType==='labvantage' || searchType==='compression') && showLabvantageInputs===false && calulatorApiResultData.responseType==='success' && calulatorApiResultData.loading===false ?(<button type="button" className="btn btn-success" onClick={(e)=>handleUseSectionEventForCalcResults()}>Use Calculator Output</button>):null}
              {((searchType==='labvantage' || searchType==='compression') && props.modal.calulatorForm===true && showLabVantageCalculatorButton===true)?(<button type="button" className="btn btn-success" onClick={(e)=>handleRunCalcEvent()}>Run Calulator</button>):null}
              
              <button type="button" className="btn btn-secondary" id="cancelALL" onClick={(e)=>props.closeAdvancedSearchModal(false)}>Cancel</button>
            </div>
        </div>
      )
    }
  else{
    return(
      <div className="row sideBarContent">	
            <div className="fixedbottom sl1" style={{position: 'fixed', bottom: '5px', display: 'block'}}>
              <button type="button" className="btn btn-secondary" id="cancelALL" onClick={(e)=>props.closeAdvancedSearchModal(false)}>Cancel & Close</button>
            </div>
        </div>
    )
  }
     
  
}
//for LabVantage API calls
const renderHelpContentForLabvantage = () =>{
  if(calulatorApiResultData.loading==false){
    return(  
      <div className='col-12'>
          <div class="calulcationParameters"><b>Calculation Result </b></div>  
          <p>
            <small className='textNormal'>The below calulations are performed based on the inputs provided under 'Calculation Parameters' using <i>{queryIdForSelectedPropertyRow.data.calculatorDetail.calculatoraName}</i> calulator. </small>
          </p>
      </div>
      
      )
  }
  else if(calulatorApiResultData.loading===true){
    return(<div className='col-12'>
      <div class="calulcationParameters"><b>Calculation Result </b></div> 
      <div>
          <span id="totalM"><img src={loader} className='loaderr' /></span>
      </div>      
    </div>)
  }
  else if(calulatorApiResultData.loading===null){
    return(
    <div className='col-12'>      
      <p>
        <small className='textNormal'>Provide the Calculation Parameters to perform the calulations</small>
      </p>
    </div>
    )
  }
}
const renderTableRows=(data)=>{
  return data.map((item, index)=>{
    return(
      <tr>
        <td>{item.fieldLabel}</td>
        <td>{item.value}</td>
      </tr>
    )
  })

}
const renderCalculatorRsult=()=>{
  if(calulatorApiResultData.loading==false && calulatorApiResultData.responseType=='success'){
    if(calulatorApiResultData.data.outputData!==undefined  && calulatorApiResultData.data.outputData.length>0){       
        return(<>
            <p>Calulated Properties</p>
            <table className="table table-bordered table-sm labVantageFieldsTable">
              <thead>
                <tr>    
                  <th scope="col">Property  </th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows(calulatorApiResultData.data.outputData)}
              </tbody>
            </table>    
          </>       
        )
      
    }
  }
   
}
const rendererrorMessage=()=>{
  if(calulatorApiResultData.loading==false && (calulatorApiResultData.responseType=='Failed' || calulatorApiResultData.responseType=='failed')   ){
    return(
      <div className='col-12' style={{textAlign:'center'}}>
        <p style={{color:'red'}}>Failed</p>
        <p>{calulatorApiResultData.message}</p>
      </div>
      
      
  )
  }
}
const renderCalculatorRefrenceData=()=>{
 if(calulatorApiResultData.loading==false && calulatorApiResultData.responseType=='success'){
    if(calulatorApiResultData.data.referenceData!==undefined  && calulatorApiResultData.data.referenceData.length>0){       
        return(<>
          <p>Refrence Data</p>          
            <table className="table table-bordered table-sm labVantageFieldsTable">
              <thead>
                <tr>    
                  <th scope="col">Property  </th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                {renderTableRows(calulatorApiResultData.data.referenceData)}
              </tbody>
            </table> 
          </>            
        )
      
    }
  }
}
const renderCalculatorResultGraph=()=>{
 if(calulatorApiResultData.loading==false && calulatorApiResultData.responseType=='success'){
   if(calulatorApiResultData.data.outputChart!==undefined && Object.keys(calulatorApiResultData.data.outputChart).length>0){ 
     
      return(<>
        {<BarChart data={calulatorApiResultData.data} />}
        </>
      )
    }
  }  
}
const renderHelpContent=()=>{
  if(tableData.loading==false && tableData.responseType=='success' && selectedRowKeys.length>=0 && selectedRowKeys.length<2){
    return(    
      <div className='col-12'>
          <p>
            <b>Average Fiber</b><br />
            <small className='textNormal'>Select minimum two and maximum three rows from the above table for average fiber calulation. </small>
          </p>
      </div>
      
      )
  }
  else if(selectedRowKeys.length>1 && selectedRowKeys.length<=3){
    return(<div className='col-12'><b>Average Fiber</b><br /><small className='textNormal'>Add Mass % for the selected fibers and click on Calculate Average button </small></div>)
  }
}
const renderAverageFiberTable=()=>{
  if(searchType=='fiber' && fiberAvgError.type==='success' && fiberAvgError.response!==''){
    return(
      <DataGrid  tableData = {{data:fiberAvgError.response}}  path ='material-details' enableRowSelection={false} />
    )
  }
  else if(searchType=='fiber' && fiberAvgError.type==='error' ){
    return(<div className='col-12'><p style={{color:'red'}}><b>Error</b><br />{fiberAvgError.response}</p></div>)
  }
   
}
const renderAverageFiber=()=>{
  if(selectedRowKeys.length>1 && selectedRowKeys.length<=3){
    // let classValue = (selectedRowKeys.length==3)?'fiberMP col-4':'fiberMP col-6';
    let classValue = 'fiberMP col-12';

    return tableData.data.rows.map((filteredRow)=>{
      if(selectedRowKeys.indexOf(filteredRow.key)!== -1){
          return(
            <div className={classValue}>
              <div className="form-group">
                  <label className="col-5">{filteredRow.key} - {filteredRow.fiberName}</label>
                  <input style={{width: '100px'}} type="number" id={`fiber_mass_${filteredRow.key}`} key={`fiber_mass_${filteredRow.key}`} class="form-control" 
                  value={(selectedColumns[filteredRow.key]!==undefined)?(selectedColumns[filteredRow.key]['percentage']):null} onChange={(e)=>handleFiberMassPercentChangeEvent(e, filteredRow.key)}></input>
                  <label className='col-1'>%</label>

                </div>
            </div>
          )
      }
    })
  }
}
return (
  
  <div id="defaultAPICall" style={{display:'block'}}>
   <div className="backShade"></div>	
       
	{/* <div className={searchType=='material'?'mysidebar larger':'mysidebar'}> */}
  <div className='mysidebar larger paddingBottom'>
			<div className="row">
					<div className="col-12 sidebarHeader">
            <div className='sidebarHeaderContainer'>
                <h5><i className={props.modal.icon}></i>&nbsp;{props.modal.title}</h5>
                <button type="button" class="close"   onClick={(e)=>props.closeAdvancedSearchModal(false)}>
                  <span aria-hidden="true">×</span>
                </button>
            </div>  
          </div>
			</div>	
      
			<div className="row sideBarContent">		
           {renderSearchType()}	        
			  	 {renderSearchContent()} 	          
					 {renderSearchActionButtonContainer()}  
			</div>	

      {renderGridContent()}       
      {searchType=='fiber' ?(
        <div className='row pl-3 pr-3'>
          {renderHelpContent()}
          {renderAverageFiber()}
          {renderAverageFiberTable()}
        </div>):null}     
        {(searchType=='labvantage' ||  searchType=='compression') && props.modal.calulatorForm===true && showLabvantageInputs===false ?(
        <div className='row pl-3 pr-3'>
          {renderHelpContentForLabvantage()}          
          {rendererrorMessage()}
          <div className='col-5'>  
             {renderCalculatorRsult()}
             {renderCalculatorRefrenceData()}
          </div>
          <div className='col-7'>   
             {renderCalculatorResultGraph()}
          </div>
         
          {/* {renderCalculatorGraph()}   */}
        </div>):null}     
      {renderFooterButtons()}	
    
	  
	  </div>
  
</div>

);
}


export default AdvanceSearch;
