import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getPartCategories, resetSvgPartDataLoading } from "../../axios/index";
import Header from "./Header";
import ActionButtons from "./ActionButtons";
import SaveConfigPopup from "./saveConfigPopup";
import LoadContainer from "./LoadContainer";
import "./index.css";

function SVG(props) {
  const { configId, moduleName } = useParams();
  const dispatch = useDispatch();
  const [saveEvent, setSaveEvent] = React.useState(false);
  const [moduleFieldDataSet, setModuleFieldDataSet] = React.useState({});
  const recordUpdateApiStatus = useSelector((state) => state.recordUpdateApi);
  const parts = useSelector((state) => state.svgViewerPartData);
  const svgSource = useSelector((state) => state.svgViewerData);
  const [preview, setPreview] = React.useState(false);
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    if (
      svgSource?.data?.assemblyData?.metaData?.containerMetaData
        ?.productType !== undefined
    ) {
      dispatch(
        getPartCategories({
          configID:
            svgSource?.data?.assemblyData?.metaData?.containerMetaData
              ?.productType,
        })
      );
    }
  }, [svgSource]);

  const tooglePreview = () => {
    setPreview(!preview);
  };

  const handleSaveEvent = (status) => {
    dispatch(resetSvgPartDataLoading({}));
    setSaveEvent(status);
  };

  const renderSavePopup = () => {
    if (saveEvent) {
      let saveType = "new";
      let isNew = parts.data?.configData?.type;
      if (isNew === "new") {
        saveType = "new";
      } else if (isNew === "existing") {
        saveType = "update";
      }
      // if(moduleName==='assembly-viewer'){
      //     saveType = 'new';
      // }
      let modeldata = {
        module: "Configuration",
        title: `Save Configuration Details`,
        icon: "now-ui-icons arrows-1_cloud-download-93",
        info: `You are about to save all the changes. Please select from the given below options.`,
        urlId: -1,
        saveType: saveType,
      };
      return (
        <>
          <div className="modal-backdrop fade show"></div>
          <SaveConfigPopup
            moduleName={moduleName}
            modal={modeldata}
            saveRecordEvent={(data) => saveRecordEvent(data)}
            recordUpdateApiStatus={recordUpdateApiStatus}
            urlId={-1}
            closeModal={(e) => closeModal(e)}
          />
        </>
      );
    }
  };

  const saveRecordEvent = (data) => {};
  const closeModal = (e) => {
    setSaveEvent(false);
    //setDeleteEvent(false)
    //dispatch(reaetSendRecordDataToBackEndReducer({}))
  };

  return (
    <>
      <div className="row">
        <div className="col-12 header-bar">
          <div className="col-12 content svg" data-module={moduleName}>
            <Header moduleName={props.moduleName} />
            <ActionButtons
              preview={preview}
              moduleName={moduleName}
              handleSaveEvent={(e) => handleSaveEvent(e)}
              previewActive={(e) => tooglePreview()}
              id={configId}
            />
          </div>
        </div>
      </div>
      {renderSavePopup()}
      <LoadContainer preview={preview} />
    </>
  );
}

export default SVG;
