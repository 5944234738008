import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../atom/input";
import TextArea from "../atom/textArea";
import { Tag } from "antd";
import { useDropzone } from "react-dropzone";
import FilesTable from "./filesTable";
import SvgComponent from "../../icons/svgComponent";
import ErrorMsg from "../errors/errorMsg";
import { isValidContainerName } from "../utils/compareUtils";
import { removeSpecialCharacters } from "../utils/stringConvertorUtils";

import "./index.css";

function UploadFile({
  uploadedFiles,
  setUploadedFiles,
  allFields,
  setAllFields,
  fileTypeError,
  setfileTypeError,
  uploadedFilesResponse,
  setUploadedFilesResponse,
}) {
  const containerNameMaxLength = 25;
  const newNamesArr = ["-new", "-copy"];
  const UploadFields = useSelector((state) => {
    let uploadEntries = state?.UploadFileData?.fields?.associatedValues?.map(
      (item) => item
    );
    // Sort the input array based on the "order" key
    // uploadEntries.sort((a, b) => a.order - b.order);

    return uploadEntries;
  });

  // const UploadLoading = useSelector((state) => state?.UploadFileData?.loading);
  const UploadOptions = useSelector((state) => state?.UploadFieldsOptions);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const [selectedProductOrg, setSelectedProductOrg] = useState(null);
  const [containerNameExist, setContainerNameExist] = useState(false);
  const [ProductOptions, setProductOptions] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      let allFiles = [...uploadedFiles, ...acceptedFiles];

      // Check if new entry is dupliate or not
      var filesArr = allFiles.map(function (item) {
        return item.path;
      });
      var isDuplicate = filesArr.some(function (item, idx) {
        return filesArr.indexOf(item) != idx;
      });

      if (isDuplicate) {
        setfileTypeError("Duplicate files are not allowed.");

        return;
      }

      let latestFile = uploadedFiles.length
        ? uploadedFiles[0].path
        : acceptedFiles[0].path;
      let newFileType = latestFile.split(".").pop(); //Get the new file type
      let newFileName = latestFile
        .split(".")[0]
        .slice(0, containerNameMaxLength); // Get the new file name without extension and slice the name to max container name limit
      // newFileName.slice(0, containerNameMaxLength);

      // Check if new uploaded file is of type stl or dxf
      acceptedFiles.forEach((item) => {
        let itemFileType = item.path?.split(".").pop();

        if (!["dxf", "stl"].includes(itemFileType.toLowerCase())) {
          setfileTypeError("Only DXF or STL files allowed.");
          newFileType = false;
          return;
        }
        setfileTypeError("");
      });
      if (!newFileType) {
        return;
      }

      // Add newly added files in the uploadFiles state
      setUploadedFiles(allFiles);

      // New file upload than reponse of previous should be back to zero
      setUploadedFilesResponse({ responseCount: 0, fileResponses: [] });

      // Check if container name already exist or not
      let isDuplicateConatainer = isContainerDuplicateCheck(newFileName);
      if (isDuplicateConatainer) {
        let sameContainerName = true;
        while (sameContainerName) {
          let lastChar = newFileName[newFileName.length - 1];

          if (!isNaN(lastChar)) {
            let version = parseInt(lastChar) + 1;
            newFileName = newFileName.slice(0, -1) + version;
            // newFileName[newFileName.length - 1] = lastChar + 1;
            // newFileName = `${newFileName}-${lastChar + 1}`;
          } else {
            newFileName = `${newFileName}-1`;
          }

          sameContainerName = isContainerDuplicateCheck(newFileName);
        }
      }

      // Update the new container input field to the uploaded[0] filename also the filetype
      setAllFields({
        ...allFields,
        // newContainerSelect: newFileName.toLowerCase().split(" ").join(""),
        newContainerSelect: removeSpecialCharacters(newFileName),
        fileType: newFileType.toLowerCase() == "dxf" ? "DXF" : "STL",
      });

      // FileType updated
      setSelectedFileType(newFileType.toLowerCase() == "dxf" ? "DXF" : "STL");
    },

    // accept: {
    //   "image/jpeg": [],
    //   "image/png": [],
    // },productOrg
  });

  useEffect(() => {
    let productOptions = [];
    UploadOptions?.data?.productOptions?.forEach((value) => {
      if (value.productOrg === selectedProductOrg) {
        productOptions = value.values;
      }
    });
    setProductOptions(productOptions);
  }, [selectedProductOrg]);

  useEffect(() => {
    //Clean states on unmount
    return () => {
      setUploadedFiles([]);
      setAllFields({});
      setfileTypeError("");
      setUploadedFilesResponse({
        responseCount: 0,
        fileResponses: [],
      });
    };
  }, []);

  function isContainerDuplicate(name) {
    const newContainerName = name ? name.toLowerCase() : "";
    const allContainerNames = UploadOptions?.data?.containerFileOptions.map(
      (item) => item?.sourceContainerID
    );
    if (allContainerNames?.includes(newContainerName)) {
      setContainerNameExist(true);
    } else {
      setContainerNameExist(false);
    }
    // console.log(newContainerName, "newww", allContainerNames);
  }

  function isContainerDuplicateCheck(name) {
    const newContainerName = name ? name.toLowerCase() : "";
    const allContainerNames = UploadOptions?.data?.containerFileOptions.map(
      (item) => item?.sourceContainerID
    );
    if (allContainerNames?.includes(newContainerName)) {
      return true;
    } else {
      return false;
    }
    // console.log(newContainerName, "newww", allContainerNames);
  }

  function getSuggestedName(selectedContainerName) {
    // Check if container name already exist or not
    let sameContainerName = isContainerDuplicateCheck(selectedContainerName);
    if (sameContainerName) {
      while (sameContainerName) {
        let lastChar = selectedContainerName[selectedContainerName.length - 1];

        if (!isNaN(lastChar)) {
          let version = parseInt(lastChar) + 1;
          selectedContainerName = selectedContainerName.slice(0, -1) + version;
          // selectedContainerName[selectedContainerName.length - 1] = lastChar + 1;
          // selectedContainerName = `${selectedContainerName}-${lastChar + 1}`;
        } else {
          selectedContainerName = `${selectedContainerName}-1`;
        }

        sameContainerName = isContainerDuplicateCheck(selectedContainerName);
      }
      return selectedContainerName;
    } else {
      return `${selectedContainerName}"-1" `;
    }
  }
  const onChange = (e) => {
    let value = e.target?.value;
    // Functionality for the new container name
    if (e.target?.id === "newContainerSelect") {
      isContainerDuplicate(value);
      value = value.toLowerCase();
      value = value.split(" ").join("");
      // value = removeSpecialCharactersTyped(value);
    }
    setAllFields({
      ...allFields,
      [e.target?.id]: value,
    });
  };

  const onFileContainerChange = (e) => {
    isContainerDuplicate(
      allFields?.newContainerSelect ? allFields?.newContainerSelect : ""
    );
    if (e.target.value === "newContainerSelect") {
      let selectedFileType =
        uploadedFiles.length > 0 ? uploadedFiles[0].path?.split(".").pop() : "";
      // newContainerSelect
      setSelectedFileType("");
      setAllFields({
        ...allFields,
        [e.target.id]: e.target.value,
        description: "",
        project: "",
        productSize: "",
        tier: "",
        brand: "",
        drawingStatus: "",
        region: "",
        containerType: "new",
        fileType: selectedFileType
          ? selectedFileType?.toLowerCase() === "dxf"
            ? "DXF"
            : "STL"
          : "",
      });
    } else {
      // let selectedFileType = "";
      let selectedFileValues = {};
      let selectedFileType =
        uploadedFiles.length > 0 ? uploadedFiles[0].path?.split(".").pop() : "";

      UploadOptions?.data?.containerFileOptions?.map((value) => {
        if (value?.sourceContainerID === e.target.value) {
          selectedFileType = value?.fileType || selectedFileType;
          selectedFileValues = value;
        }
      });

      setSelectedProductOrg(
        selectedFileValues?.productOrg ? selectedFileValues?.productOrg : ""
      );
      setSelectedFileType(selectedFileType);
      setAllFields({
        ...allFields,
        [e.target.id]: e.target.value,
        // ...selectedFileValues,
        description: selectedFileValues?.containerDescription,
        project: selectedFileValues?.project ? selectedFileValues?.project : "",
        productSize: selectedFileValues?.productSize
          ? selectedFileValues?.productSize
          : "",
        tier: selectedFileValues?.tier ? selectedFileValues?.tier : "",
        brand: selectedFileValues?.brand ? selectedFileValues?.brand : "",
        drawingStatus: selectedFileValues?.drawingStatus
          ? selectedFileValues?.drawingStatus
          : "",
        region: selectedFileValues?.region ? selectedFileValues?.region : "",
        productType: selectedFileValues?.productType
          ? selectedFileValues?.productType
          : "",
        productCategory: selectedFileValues?.productCategory
          ? selectedFileValues?.productCategory
          : "",
        productOrg: selectedFileValues?.productOrg
          ? selectedFileValues?.productOrg
          : "",
        fileType: selectedFileType
          ? selectedFileType.toLowerCase() === "dxf"
            ? "DXF"
            : "STL"
          : "",
        containerType: "existing",
      });
    }

    // Changing the status of responseCount so the isDuplicates and all are changed
    setUploadedFilesResponse({
      responseCount: 0,
      fileResponses: [],
    });
  };

  const onProductTypeChange = (e) => {
    setAllFields({
      ...allFields,
      [e.target?.id]: e.target?.value,
      productCategory: e.target?.value,
    });
  };

  const handleFileTypeChange = (e) => {
    setSelectedFileType(e.target.value);
    setAllFields({
      ...allFields,
      [e.target?.id]: e.target.value,
    });
  };

  const handleProductOrgChange = (e) => {
    setSelectedProductOrg(e.target?.value);
    setProductOptions([]);
    setAllFields({
      ...allFields,
      [e.target?.id]: e.target.value,
      productType: "",
      productCategory: "",
    });
  };

  function infoComponent() {
    return (
      <div
        className="info-icon"
        data-toggle="tooltip"
        title="INFO about the Project field"
      >
        <SvgComponent
          type={"info"}
          width={"20px"}
          customClass={"headerIcon"}
          height={"20px"}
          background={"transprent"}
        />
      </div>
    );
  }

  function containerNameInfo() {
    return (
      <div
        className="info-icon"
        data-toggle="tooltip"
        title="-> Container names must start or end with a letter or number, and can contain only letters, numbers, and the hyphen/minus (-) character.

      -> Every hyphen/minus (-) character must be immediately preceded and followed by a letter or number; consecutive hyphens aren't permitted in container names.
        
      -> All letters in a container name must be lowercase.
        
      -> Container names must be from 3 through 25 characters long.

      -> Spaces are not allowed"
      >
        <SvgComponent
          type={"info"}
          width={"20px"}
          customClass={"headerIcon"}
          height={"20px"}
          background={"transprent"}
        />
      </div>
    );
  }

  const renderFields = (field) => {
    switch (field?.dataType.toLowerCase()) {
      case "text":
        if (field?.attributeName === "project") {
          return (
            <Input
              id={field.attributeName}
              label={field?.displayName}
              onChange={onChange}
              infoComponent={infoComponent}
              required={field?.mandatory}
              value={allFields[field?.attributeName]}
            />
          );
        }

        return (
          <Input
            id={field.attributeName}
            label={field?.displayName}
            onChange={onChange}
            required={field?.mandatory}
            value={allFields[field?.attributeName]}
          />
        );
      case "text area":
        return (
          <TextArea
            id={field.attributeName}
            label={field?.displayName}
            onChange={onChange}
            required={field?.mandatory}
            value={allFields[field?.attributeName]}
          />
        );
      case "dropdown":
        return renderDropdowns(field);
      default:
        return null;
    }
  };

  function handleNewContainerNameSelect(name) {
    setAllFields({
      ...allFields,
      newContainerSelect: name,
    });
    setContainerNameExist(false);
  }
  const renderDropdowns = (field) => {
    switch (field?.attributeName) {
      case "containerName":
        return (
          <>
            <div className="textLabel">
              <span>{"File Container"}</span>
              {field?.mandatory && <span className="required">*</span>}
            </div>

            <div className="dropdown">
              <select
                class="custom-select"
                id={"containerName"}
                key={"containerName"}
                value={allFields["containerName"]}
                onChange={onFileContainerChange}
              >
                <option selected="" disabled="" value="">
                  Choose...
                </option>

                <option
                  value={"newContainerSelect"}
                  // value={JSON.stringify({
                  //   sourceContainerID: "newContainerSelect",
                  //   fileType: null,
                  // })}
                >
                  {"Create a new Container"}
                </option>
                {UploadOptions?.data?.containerFileOptions?.map((value) => (
                  <option
                    value={value?.sourceContainerID}
                    // value={JSON.stringify(value)}
                  >
                    {value?.sourceContainerID} {"("}
                    {value?.fileType || "NA"}
                    {")"}
                  </option>
                ))}
              </select>
            </div>

            {allFields["containerType"] === "new" && (
              <div>
                <Input
                  id={"newContainerSelect"}
                  label={"New Container Name"}
                  onChange={onChange}
                  value={allFields["newContainerSelect"]}
                  required={true}
                  maxlength={containerNameMaxLength}
                  infoComponent={containerNameInfo}
                />

                {allFields["newContainerSelect"] &&
                  !isValidContainerName(
                    allFields["newContainerSelect"] || ""
                  ) && (
                    <span className="error-msg">Invalid Container Name.</span>
                  )}
                {containerNameExist && (
                  <div>
                    <span className="error-msg">
                      Container name already exist. You can choose from below
                      options..
                    </span>
                    {newNamesArr.map((item) => {
                      const overwrittenName =
                        allFields["newContainerSelect"] + item;
                      const duplicateEntry =
                        isContainerDuplicateCheck(overwrittenName);
                      return !duplicateEntry ? (
                        <Tag
                          color={"#108ee9"}
                          onClick={() =>
                            handleNewContainerNameSelect(overwrittenName)
                          }
                        >
                          {overwrittenName}
                        </Tag>
                      ) : null;
                    })}

                    <Tag
                      color={"#108ee9"}
                      onClick={() =>
                        handleNewContainerNameSelect(
                          getSuggestedName(allFields["newContainerSelect"])
                        )
                      }
                    >
                      {getSuggestedName(allFields["newContainerSelect"])}
                    </Tag>
                  </div>
                )}
              </div>
            )}
          </>
        );
      case "fileType":
        return (
          <>
            <div className="textLabel">
              <span>{"File Type"}</span>
              {field?.mandatory && <span className="required">*</span>}
            </div>

            <div className="dropdown">
              <select
                class="custom-select"
                id={"fileType"}
                key={"fileType"}
                value={allFields["fileType"]}
                onChange={handleFileTypeChange}
                disabled={true}
              >
                <option selected="" disabled="" value="">
                  Choose...
                </option>

                <option value="DXF">{"DXF"}</option>
                <option value="STL">STL</option>
              </select>
            </div>
          </>
        );
      case "region":
        return (
          <>
            <div className="textLabel">
              <span>{"Region"}</span>
              {field?.mandatory && <span className="required">*</span>}
            </div>

            <div className="dropdown">
              <select
                class="custom-select"
                id={"region"}
                key={"region"}
                value={allFields["region"]}
                onChange={onChange}
              >
                <option selected="" disabled="" value="">
                  Choose...
                </option>

                {UploadOptions?.data?.regionOptions?.map((value) => (
                  <option value={value?.id}>{value?.regionName}</option>
                ))}
              </select>
            </div>
          </>
        );

      case "productOrg":
        return (
          <>
            <div className="textLabel">
              <span>{"Product Category"}</span>
              {field?.mandatory && <span className="required">*</span>}
            </div>

            <div className="dropdown">
              <select
                class="custom-select"
                id={"productOrg"}
                key={"productOrg"}
                value={allFields["productOrg"]}
                onChange={handleProductOrgChange}
              >
                <option selected="" disabled="" value="">
                  Choose...
                </option>
                {UploadOptions?.data?.productOptions?.map((value) => (
                  <option value={value?.productOrg}>{value?.productOrg}</option>
                ))}
              </select>
            </div>
          </>
        );
      case "productType":
        return (
          <>
            <div className="textLabel">
              <span>{"Product Label"}</span>
              {field?.mandatory && <span className="required">*</span>}
            </div>

            <div className="dropdown">
              <select
                class="custom-select"
                id={"productType"}
                key={"productType"}
                value={allFields["productType"]}
                onChange={onProductTypeChange}
              >
                <option selected="" disabled="" value="">
                  Choose...
                </option>
                {ProductOptions?.map((value) => (
                  <option value={value?.id}>{value?.productType}</option>
                ))}
              </select>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-5">
          {UploadFields &&
            UploadFields?.map((value) => {
              return renderFields(value);
            })}
        </div>
        <div className="col-7">
          <div className="upload-section" {...getRootProps()}>
            <input {...getInputProps()} />
            <h6>Drag and drop files here or click to browse.</h6>

            {uploadedFiles.length > 0 && (
              <FilesTable
                files={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
                uploadedFilesResponse={uploadedFilesResponse}
                setUploadedFilesResponse={setUploadedFilesResponse}
              />
            )}
          </div>
        </div>
      </div>
      {fileTypeError && (
        <div className="mt-2">
          <ErrorMsg message={fileTypeError} />
        </div>
      )}
    </div>
  );
}

export default UploadFile;
