import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, Spin, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { uploadCurationMetadata } from "../../axios";

const { Search } = Input;

function CurationForm({ onFormSuccess, onSearch }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [fileData, setFileData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    form.setFieldsValue({
      upload: [],
    });
    return () => {
      setFileData(null);
    };
  }, [form]);

  const resetFormFields = () => {
    form.resetFields();
    setFileData(null);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    resetFormFields();
  };

  const handleOk = (values) => {
    setLoading(true);
    form
      .validateFields()
      .then((values) => {
        const formData = new FormData();

        if (fileData) {
          formData.append("file", fileData);
        }

        Object.keys(values).forEach((key) => {
          if (key !== "upload") {
            formData.append(key, values[key]);
          }
        });
        formData.append("created_by", userInfo.name);

        dispatch(uploadCurationMetadata(formData))
          .then(() => {
            message.success("Metadata and file uploaded successfully!");
            onFormSuccess();
            setLoading(false);
            setIsModalVisible(false);
            resetFormFields();
          })
          .catch((error) => {
            console.error("Error uploading data:", error);
            message.error("Failed to upload data.");
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
        setLoading(false);
      });
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    accept: ".csv",

    beforeUpload: (file) => {
      const isCsv = file.type === "text/csv" || file.name.endsWith(".csv");
      if (!isCsv) {
        message.error(`${file.name} is not a CSV file`);
        return Upload.LIST_IGNORE;
      }
      if (fileData) {
        message.error("Only one file can be uploaded at a time.");
        setFileData(file);
        return Upload.LIST_IGNORE;
      }
      setFileData(file);
      return false;
    },
    onChange: (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setFileData(info.file.originFileObj);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      form.setFieldsValue({ upload: fileList });
    },
    onRemove: (file) => {
      setFileData(null);
      form.setFieldsValue({ upload: [] });
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "end", gap: 24 }}>
        <Search
          placeholder="Search by ID, Name, Test Method, Material ID, Created By"
          style={{ width: 460 }}
          allowClear
          onSearch={(value) => onSearch(value)}
        />

        <Button type="primary" onClick={showModal} style={{ backgroundColor: "#06509e" }}>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <UploadOutlined /> <span>Upload CSV</span>
          </div>
        </Button>
      </div>

      <Modal
        title="Add Metadata and Upload CSV Specimen File"
        open={isModalVisible}
        width="80%"
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Upload"
      >
        <Spin spinning={loading}>
          <Form layout="vertical" form={form}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label={<div>Name</div>}
                  rules={[{ required: true, message: "Please input the Name" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: "Please input the Description" }]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item
                  className="custom-form-item"
                  name="testMethod"
                  label="Test Method"
                  rules={[{ required: true, message: "Please select the Test Method" }]}
                >
                  <Select>
                    <Select.Option key="TTM-00199">TTM-00199</Select.Option>
                    <Select.Option key="STM-00194">STM-00194</Select.Option>
                    <Select.Option key="STM-00201">STM-00201</Select.Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  className="custom-form-item"
                  name="material_id"
                  label="Material ID"
                  rules={[
                    { required: true, message: "Please input the Material ID" },
                    {
                      validator: (_, value) =>
                        value < 0 ? Promise.reject(new Error("Material ID must be non-negative!")) : Promise.resolve(),
                      message: "Material ID must be non-negative!",
                    },
                  ]}
                >
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="length"
                  label="Gauge Length"
                  rules={[
                    { required: true, message: "Please input the Gauge Length" },
                    {
                      validator: (_, value) =>
                        value < 0 ? Promise.reject(new Error("Gauge Length must be non-negative!")) : Promise.resolve(),
                      message: "Gauge Length must be non-negative!",
                    },
                  ]}
                >
                  <InputNumber min={0} step={0.01} style={{ width: "100%" }} precision={2} addonAfter="mm" />
                </Form.Item>
                <Form.Item
                  name="width"
                  label="Specimen Width"
                  rules={[
                    { required: true, message: "Please input the Specimen Width" },
                    {
                      validator: (_, value) =>
                        value < 0
                          ? Promise.reject(new Error("Specimen Width must be non-negative!"))
                          : Promise.resolve(),
                      message: "Specimen Width must be non-negative!",
                    },
                  ]}
                >
                  <InputNumber min={0} step={0.01} style={{ width: "100%" }} precision={2} addonAfter="mm" />
                </Form.Item>
                <Form.Item
                  name="thickness"
                  label="Specimen Thickness"
                  rules={[
                    { required: true, message: "Please input the Specimen Thickness" },
                    {
                      validator: (_, value) =>
                        value < 0
                          ? Promise.reject(new Error("Specimen Thickness must be non-negative!"))
                          : Promise.resolve(),
                      message: "Specimen Thickness must be non-negative!",
                    },
                  ]}
                >
                  <InputNumber min={0} step={0.01} style={{ width: "100%" }} precision={2} addonAfter="mm" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <div style={{ height: 644, backgroundColor: "#06509e" }}>
                  <Form.Item
                    name="upload"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[
                      {
                        required: true,
                        message: "Please upload only one CSV File!",
                      },
                    ]}
                  >
                    <div
                      className="curation-upload"
                      style={{
                        height: 300,
                      }}
                    >
                      <Upload.Dragger
                        name="files"
                        listType="picture"
                        {...uploadProps}
                        style={{
                          backgroundColor: "#06509e",
                          border: "none !important;",
                        }}
                      >
                        <p className="ant-upload-drag-icon">
                          <UploadOutlined />
                        </p>
                      </Upload.Dragger>
                    </div>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}

export default CurationForm;
