import React, { useEffect, useState, useCallback } from "react";
import { renderIntoDocument } from "react-dom/test-utils";
import SvgComponent from '../../../icons/svgComponent';
import './index.css';
function PageHeaderTitle(props) {   
  return (
    <div className="header-title-txt">
        {<SvgComponent  type ={props.metaData.iconType} width={'34px'} customClass = {''}height={'34px'} background={'transparent'}/>}
        {props.metaData.pageTitle}
    </div>  
  );
}

export default PageHeaderTitle;

 