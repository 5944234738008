import React, { useEffect, useState, useCallback } from "react";
import {Link} from "react-router-dom";
import './index.css';
 
function DefaultBreadCrumb(props) {  
 const renderBreadCrumb =(data)=>{
    return data.map((item)=>{
        if(item.active && item.active==true){
            return(<li className="breadcrumb-item"><span >{item.title}</span></li>)
        }
        else{
            return(<li className="breadcrumb-item"><Link to={item.path}>{item.title}</Link></li>)
        }
        
    })
 }

  return (
    <div className="row">
        <div className="col-12">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    {renderBreadCrumb(props.data)}					 
                </ol>
                </nav>
            </div>
        </div>
  );
}

export default DefaultBreadCrumb;
