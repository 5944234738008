import React, { useState } from "react";

import { Table } from "antd";

import "./index.css";

function GenericTable(props) {
  const [current, setCurrent] = useState(1);

  const onChange = (page) => {
    setCurrent(page);
  };

  const handleRowSelection = (record, rowIndex) => {
    if (props.enableRowSelection) {
      return {
        onClick: () => {
          props.onSelectRowEvent(record, rowIndex);
        },
      };
    }
  };

  const rowSelection = {
    type: "checkbox",

    // onChange: onCheckboxChange,
    // columnTitle: "Select to Compare",
    // columnWidth: 60,
  };

  return (
    <div className="col-lg-12 gridContainer">
      <div className="card">
        <div className="card-body">
          <Table
            loading={props.isLoading}
            //dataSource={hasData ? data : []}
            // title={() => "Header"}
            // onHeaderRow={() => "Header"}
            footer={props?.footer ? props?.footer : null}
            pagination={{
              defaultPageSize: 10,
              onChange: onChange,
              current: current,
              showSizeChanger: true,
            }}
            rowSelection={props.enableRowSelection ? props.rowSelection : null}
            columns={props?.columns ? props?.columns : []}
            dataSource={
              Array.isArray(props?.data) && props?.data ? props?.data : []
            }
            scroll={{
              x: true,
            }}
            // onRow={(record, rowIndex) => ({
            //   onClick: () => console.log(record, rowIndex),
            // })}
            // onRow={(record, rowIndex) => {
            //   return {
            //     onClick: (event) => {
            //       console.log(event);
            //     }, // click row
            //     onDoubleClick: (event) => {}, // double click row
            //     onContextMenu: (event) => {}, // right button click row
            //     onMouseEnter: (event) => {}, // mouse enter row
            //     onMouseLeave: (event) => {}, // mouse leave row
            //   };
            // }}
          />
          {/* <Pagination current={1}  total={50} /> */}
        </div>
      </div>
    </div>
  );
}

export default GenericTable;
