import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Table, Pagination } from "antd";
import { PaginationProps } from "antd";

import "./index.css";
import { updateSearchKey, resetSearchKey } from "../../axios";
import cloneDeep from "lodash/cloneDeep";

function DataGrid(props) {
  const dispatch = useDispatch();
  console.log(props, "props.tableData");
  // const [moduleName, setModuleName] = React.useState('');
  let mainData = props?.tableData?.data?.rows;

  const [data, setData] = React.useState([]);
  let searchKey = useSelector((state) => state.searchKey);
  let userInfo = useSelector((state) => state.userInfo);
  let myModel = useSelector((state) => state.userModel);
  const [current, setCurrent] = useState(1);

  const onChange = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    if (searchKey != "") {
      searchKey = searchKey.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase();

      let filter = mainData.filter((item) =>
        props.path.includes("material") ||
        props.path.includes("sam") ||
        props.path.includes("fiber")
          ? (!!item["key"] && item["key"].toString().match(searchKey)) ||
            (!!item["author"] &&
              item["author"].toLowerCase().match(searchKey)) ||
            (!!item[`${props.path.split("-")[0]}Description`] &&
              item[`${props.path.split("-")[0]}Description`]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(searchKey)) ||
            (!!item[`${props.path.split("-")[0]}Name`] &&
              item[`${props.path.split("-")[0]}Name`]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(searchKey)) ||
            (!!item[`${props.path.split("-")[0]}Type`] &&
              item[`${props.path.split("-")[0]}Type`]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(searchKey)) ||
            (!!item["status"] &&
              item["status"].toLowerCase().match(searchKey)) ||
            (!!item["vendor"] && item["vendor"].toLowerCase().match(searchKey))
          : (props.path.includes("compression") &&
              !!item["key"] &&
              item["key"].toString().match(searchKey)) ||
            (!!item["materialDescription"] &&
              item["materialDescription"]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(searchKey)) ||
            (!!item["productCategory"] && item["productCategory"])
              .toLowerCase()
              .match(searchKey) ||
            (!!item["requestId"] &&
              item["requestId"].toLowerCase().match(searchKey)) ||
            (!!item["requestorName"] &&
              item["requestorName"].toLowerCase().match(searchKey)) ||
            (!!item["sampleId"] &&
              item["sampleId"]
                .replace(/[^a-zA-Z0-9 ]/g, "")
                .toLowerCase()
                .match(searchKey)) ||
            (!!item["stackSpecimenCount"] &&
              item["stackSpecimenCount"].toString().match(searchKey)) ||
            (!!item["testState"] &&
              item["testState"].toLowerCase().match(searchKey))
      );
      setData(filter);
      setCurrent(1);
      return;
    } else {
      setData(mainData);
      return;
    }
  }, [props, searchKey]);

  const renderColumns = () => {
    let columnList = cloneDeep(props.tableData.data.columns);
    if (
      props.path !== "calculator-details" &&
      props.path !== "labvantage-details"
    ) {
      let tempColumns = [];
      if (columnList?.length > 0) {
        columnList.map((item) => {
          let tempItem = item;
          let tempKey = item["key"];

          if (item.type == "number") {
            tempItem.sorter = (a, b) => a[`${tempKey}`] - b[`${tempKey}`];
          } else if (item.type == "text" || item.type == "link") {
            tempItem.sorter = (a, b) =>
              a[`${tempKey}`].localeCompare(b[`${tempKey}`]);
          } else if (item.type == "date") {
            tempItem.sorter = (a, b) =>
              new Date(a[`${tempKey}`]).getTime() -
              new Date(b[`${tempKey}`]).getTime();
          }
          //  tem?pItem.onFilter = (value, record) => record.includes(value);
          //  sorter: (a, b) => a.name.localeCompare(b.name)
          tempItem.sortDirections = ["descend", "ascend"];
          if (
            item.type == "link" &&
            props.enableLink &&
            props.enableLink === true
          ) {
            if (props.path.includes("compression"))
              tempItem.render = (a, b) => (
                <a
                  href={`${process.env.REACT_APP_BACKEND_API_URL}/api/download/compression?id=${b.key}`}
                  target="_blank"
                >
                  <i className="now-ui-icons arrows-1_cloud-download-93"></i>{" "}
                  {a}
                </a>
              );
            else
              tempItem.render = (a, b) => (
                <Link to={`${props.path}/${b.key}`}>{a}</Link>
              );
          }
          tempColumns.push(tempItem);
        });

        return tempColumns;
      } else return columnList;
    } else {
      return columnList;
    }
  };

  const columns = renderColumns();

  //  const materials = useSelector((state)=> state.materialsData);
  // columns={props.data.columns}
  // dataSource={props.data.rows}
  // const columns = [
  //   {
  //     title: 'Full Name',
  //     dataIndex: 'name',
  //     key: 'name',
  //     onFilter: (value, record) => record.name.includes(value),
  //     sorter: (a, b) => a.name.length - b.name.length,
  //   },
  //   {
  //     title: 'Age',
  //     width: 100,
  //     dataIndex: 'age',
  //     key: 'age',
  //     defaultSortOrder: 'descend',
  //     sorter: (a, b) => a.age - b.age,

  //   },
  //   {
  //     title: 'Column 1',
  //     dataIndex: 'address',
  //     key: '1',
  //     sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: 'Column 2',
  //     dataIndex: 'address',
  //     key: '2',
  //     sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: 'Column 3',
  //     dataIndex: 'address',
  //     key: '3',
  //     sortDirections: ['descend', 'ascend'],
  //   },
  //   {
  //     title: 'Column 4',
  //     dataIndex: 'address',
  //     key: '4',
  //   },
  //   {
  //     title: 'Column 5',
  //     dataIndex: 'address',
  //     key: '5',
  //   },
  //   {
  //     title: 'Column 6',
  //     dataIndex: 'address',
  //     key: '6',
  //   },
  //   {
  //     title: 'Column 7',
  //     dataIndex: 'address',
  //     key: '7',
  //   },
  //   {
  //     title: 'Column 8',
  //     dataIndex: 'address',
  //     key: '8',
  //   },
  //   {
  //     title: 'Action',
  //     key: 'operation',
  //     width: 100,
  //     render: () => <a>action</a>,
  //   },
  // ];
  // const data = [
  //   {
  //     key: '1',
  //     name: 'MANOJ Brown',
  //     age: 39,
  //     address: 'New York Park',
  //   },
  //   {
  //     key: '2',
  //     name: 'Jim Green',
  //     age: 40,
  //     address: 'London Park',
  //   },

  // ];

  const onSelectChange = (newSelectedRowKeys) => {
    //setSelectedRowKeys(newSelectedRowKeys);
  };
  const handleRowSelection = (record, rowIndex) => {
    if (props.enableRowSelection) {
      return {
        onClick: () => {
          props.onSelectRowEvent(record, rowIndex);
        },
      };
    }
  };

  return (
    <div className="col-lg-12 gridContainer">
      <div className="card">
        <div className="card-body">
          <Table
            // loading={props.loading}
            //dataSource={hasData ? data : []}

            pagination={{
              defaultPageSize: 10,
              onChange: onChange,
              current: current,
              showSizeChanger: true,
            }}
            rowSelection={
              props.enableRowSelection ? props.handleRowSelection : null
            }
            columns={columns}
            dataSource={data}
            scroll={{
              x: true,
            }}
            onRow={(record, rowIndex) => handleRowSelection(record, rowIndex)}
          />
          {/* <Pagination current={1}  total={50} /> */}
        </div>
      </div>
    </div>
  );
}

export default DataGrid;
