import React, { useEffect, useState, useCallback } from "react";
import {Link} from "react-router-dom";
import loader from '../../loader.gif';
import './index.css';
 
function Header({moduleName, moduleFieldDataSet, editStatus, handleHeaderDataUpdate}) {  
  
   
  // const handleEvent = (status)=>{
 
  const renderHeaderBar = () =>{
    if(moduleFieldDataSet[moduleName]!== undefined && moduleFieldDataSet[moduleName]['header'] && editStatus==false){
    return(
      <>
        <div className='header-title-txt-container'><small><span>{moduleName}</span> Id - </small> <b   style={{'paddingRight':'20px'}}>{moduleFieldDataSet[moduleName]['header']['key']}</b></div>					
        <div className='header-title-txt-container'><small><span>{moduleName}</span> Name - </small> <b style={{'paddingRight':'20px'}}>{moduleFieldDataSet[moduleName]['header']['name']}</b></div>
        {moduleName ==  'material' ? ( <> <div className='header-title-txt-container'> <small>{moduleName} Type - </small> <b style={{'paddingRight':'20px'}}>{moduleFieldDataSet[moduleName]['header']['type']}</b> </div></> ) : null}
      </> 
    )
    }
    else if(moduleFieldDataSet[moduleName]!== undefined && moduleFieldDataSet[moduleName]['header'] && editStatus==true){
      return(
        <>
          <small><span>{moduleName}</span> Id - </small> <b   style={{'paddingRight':'20px'}}>{moduleFieldDataSet[moduleName]['header']['key']}
          </b>					
            <small><span>{moduleName}</span> Name - </small> <b style={{'paddingRight':'20px'}}>                     
            <input type='text' value={moduleFieldDataSet[moduleName]['header']['name']} className='form-control edit-input' onChange = {(e)=>handleHeaderDataUpdate({e:e.target.value,key:'name',  module:moduleName})}/>
          </b>
          {moduleName ==  'material' ? 
          ( <> <small><span>{moduleName}</span> Type - </small> <b style={{'paddingRight':'20px'}}>
                        <input type='text' value={moduleFieldDataSet[moduleName]['header']['type']} className='edit-input form-control' onChange = {(e)=>handleHeaderDataUpdate({e:e.target.value,key:'type',  module:moduleName})}/>              
            </b> </> )
           : null}
        </> 
      )
      }
  }

  return (
    <div className="header-title-txt">
        {renderHeaderBar()}
    </div>
 
  );
}

export default Header;
