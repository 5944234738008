import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Popover } from "antd";
import { setSvgPartDataLoading } from "../../axios/index";

import fullscreen from "./fullscreen.svg";
import "./index.css";
function ActionButtons(props) {
  const dispatch = useDispatch();
  const [isPopover, setIsPopover] = useState(false);
  const svgSource = useSelector((state) => state.svgViewerData);
  const parts = useSelector((state) => state.svgViewerPartData);

  const renderStatus = () => {
    let status = parts.data?.configData?.metaData?.configStatus;
    return (
      <div className="form-group search-box" style={{ marginRight: "8px" }}>
        <label for="" style={{ minWidth: "70px", textTransform: "capitalize" }}>
          {status !== undefined ? status : "--"}
        </label>
      </div>
    );
  };

  const handlePopoverChange = (event) => {
    setIsPopover(event);
  };
  const handleDownload = (type) => {
    let configId = parts.data.configData.metaData.configId;
    let assemblyId = svgSource.data.assemblyData.metaData.assemblyID;
   let url ='';
    if(type==='assembly'){
      url = `assembly?assemblyID=${assemblyId}`
    }
    else if(type === 'configuration'){
      url = `config?configId=${configId}`;
    }
     
    var win = window.open(
      `${process.env.REACT_APP_BACKEND_API_URL}/api/download/${url}`,
      "_blank"
    );
    win.focus();
  };

  const downloadPopover = (props) => {
    let configStatus = false;
    if(parts?.data?.configData?.metaData?.configId!==undefined && parts.data.configData.metaData.configId!==''){
      configStatus = true;
    }
    return (
      <span style={{ display: "flex" }}>
        <Button
          onClick={(e) => handleDownload("assembly")}
          size={"default"}
          style={{ padding: "10px", margin: "5px", height: "auto" }}
        >
          {/* <p style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 500 }}>Assembly</p> */}
          {/* <i className="now-ui-icons arrows-1_cloud-download-93"></i> */}
          <p style={{ marginBottom: "0px" }}>Assembly</p>
        </Button>
       
        <Button
          disabled={configStatus === false ? true : false}
          onClick={(e) => handleDownload("configuration")}
          size={"default"}
          style={{ padding: "10px", margin: "5px", height: "auto" }}
        >
          {/* <p style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 500, letterSpacing: '2.5px' }}>{`</>`}</p> */}
          {/* <i className="now-ui-icons arrows-1_cloud-download-93"></i> */}
          <p style={{ marginBottom: "0px" }}>Configuration</p>
        </Button>
      </span>
    );
  };

  const handleSave = () => {
    // dispatch(setSvgPartDataLoading({}))
    return props.handleSaveEvent(true);
  };
  const handleEvent = (type) => {};
  const renderPreviewBtnTxt = () => {
    let previewTxt = "Preview";
    if (props.preview === false) {
      previewTxt =
        props.moduleName === "config-viewer" ? "View Source" : "Preview Config";
    } else {
      previewTxt =
        props.moduleName === "config-viewer" ? "View Config" : "View Source";
    }
    return previewTxt;
  };
  const renderActionButtons = () => {
    let status = parts.data?.configData?.partData.length;
    let partModified = parts.data?.configData?.modified;
    let isNew = parts.data?.configData?.type;
    let len =
      parts.data?.configData?.partData === undefined
        ? 0
        : parts.data?.configData?.partData.length;

    // if((status===undefined && status===0) && (partModified==undefined)){
    return (
      <>
        <button
          type="button"
          className="btn btn-outline-info btn23"
          disabled={status === 0 ? true : false}
          data-target="#exampleModal2"
          onClick={(e) => props.previewActive()}
        >
          <i className="now-ui-icons media-2_sound-wave"></i>&nbsp;
          {renderPreviewBtnTxt()}
        </button>
        {/* <button
          type="button"
          className="btn btn-outline-info btn23"
          disabled={status === 0 ? true : false}
          data-target="#exampleModal2"
        >
          <i className="now-ui-icons tech_tablet"></i>&nbsp;
        </button> */}
        {/* <button
          type="button"
          className="btn btn-outline-danger btn23"
          disabled={isNew !== undefined || isNew === "existing" ? true : false}
          data-target="#exampleModal2"
        >
          <i className="now-ui-icons design-2_ruler-pencil"></i>&nbsp;Delete
        </button> */}
        <button
          type="button"
          className="btn btn-primary btn23 btn39"
          disabled={
            partModified === undefined || partModified === false || len === 0
              ? true
              : false
          }
          data-target="#exampleModal2"
          onClick={(e) => handleSave()}
        >
          <i className="now-ui-icons arrows-1_cloud-download-93"></i>&nbsp;Save
        </button>
        {/* <button type="button" className="btn btn-secondary btn23 btn39" data-toggle="modal" data-target="#exampleModal2"  onClick={(e)=> handleEvent(false)}><i className="now-ui-icons ui-1_simple-remove"></i> &nbsp; Cancel</button> */}
      </>
    );
    //}
  };

  return (
    <div className="header-btn-grp">
      {renderStatus()}
      <Popover
        placement="bottom"
        content={downloadPopover}
        trigger="click"
        visible={isPopover}
        onVisibleChange={handlePopoverChange}
        arrowPointAtCenter
      >
        <button
          type="button"
          className="btn btn-outline-info btn23"
          data-toggle="modal"
          data-target="#exampleModal2"
        >
          <i className="now-ui-icons arrows-1_cloud-download-93"></i>
          &nbsp;Download
        </button>
      </Popover>

      {renderActionButtons()}
    </div>
    // <ul>
    //   <li>
    //     <img src={fullscreen} />
    //   </li>
    //   <li>
    //     <span className='label'>Mirror </span>
    //     <select name="" id="mirror" >
    //       <option value="volvo">X Axis </option>
    //       <option value="volvo">Y Axis </option>
    //     </select>
    //   </li>
    //   <li>
    //     <span className='btn'>Preview Config</span>
    //   </li>

    //   <li>
    //     <select name="" id="save" className='blue' onChange={ (e)=>dispatch(saveConfig({configData:svgViewerPartData.data.configData}))}>
    //       {/* <option value="Save">Save Config.</option> */}
    //       <option value="Save">Save..</option>
    //       <option value="Save">Save As New Config.</option>
    //       {/* <option value="Save">Update Config.</option> */}
    //     </select>
    //   </li>
    // </ul>
  );
}

export default ActionButtons;
