import React, { useEffect, useState, useCallback } from "react";
import PageHeaderTitle from "./pageHeaderTtile";
import PageHeadeActionButtons from "./pageHeaderActionButtons";
import './index.css';

function PageHeader(props) {  
  const metaData = {
    iconType:props.iconType,
    pageTitle:props.title,
    path:props.path,
    type:props.type,
    userRole:props.userRole
  }   
  return (
    <div className="col-12  header-bar">  
        <div className="content">
            <PageHeaderTitle metaData = {metaData}/>
             <PageHeadeActionButtons metaData = {metaData} />
        </div>   
    </div>    
  );
}

export default PageHeader;

 