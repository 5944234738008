import React, { useEffect, useState, useCallback } from "react";
import {Link} from "react-router-dom";
import loader from '../../loader.gif';
import SvgComponent from '../../icons/svgComponent';
import './index.css';
 
function AdminCard(props) {  
  
console.log("props fff", props.data)
  const renderCount = (items) =>{   
      
      if(items.loading==true){
        return(<span id="totalM"><img src={loader} className='loaderr' /></span>)
      }
      else return(
        <span>{items.data.rows.length}</span>
      )
  }
   


  return (
    
    <div className="col-lg-4">
      <Link to={props.data.path}>
      <div className="card  card-custom ">        
        <div className="card-header">
        <SvgComponent  type ={props.data.icon} width={'55px'} customClass ={'custom-tile'} height={'55px'} background={props.data.bgcolor}/>
        
        <h4 className="card-title">{props.data.title}<br/> {props.data.cardType}</h4>                
        </div>
        <div className="card-body">
        <div className="chart-area">
          <p><span className="adminKpiText"><b>{renderCount(props.data.kpiData)}</b></span><br />{props.data.desc}</p>				   
        </div>
        </div>        
      </div>
      </Link>
    </div>
 
  );
}

export default AdminCard;
