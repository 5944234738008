
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link,    useParams, useSearchParams  } from "react-router-dom";
import {getSamlData, getMaterialPropertyDetai, getFiberPropertyDetai, getSamPropertyDetai,resetDataSetToInital, resetDeleteProperty} from '../axios/index'  

import Details from "../components/details"
import cloneDeep from 'lodash/cloneDeep';
import loader from '../loader.gif';

const ViewDetails = () => {
    
   const {moduleName, id} = useParams();
    const dispatch = useDispatch();
    const defaultMaterialDataSet = useSelector((state)=> state.MaterailsDetail);
    

   // const defaultDataSet = useSelector((state)=> state.MaterailsDetail.default);
    
   // const data = useSelector((state)=> state.sam);
    const [customModuleName, setCustomModuleName] = React.useState('');
    const [editStatus, setEditStatus] = React.useState(false);
    const [breadCrumbData, setBreadCrumbData] = React.useState([]);
    const [moduleFieldDataSet, setModuleFieldDataSet] = React.useState({});
    const [fields, setFields] = React.useState({}) 
    const [resetAlldata, setResetAlldata] = React.useState(false) 
    const [defaultKey, setDefaultKey] =  React.useState(1) 
    const [urlId, setUrlId] = React.useState(id)
    //const oktaAuth = useSelector((state) => state.oktaAuth);

    useEffect(() => {
        console.log("saveRecordEvent -urlParams -1 -",moduleName, id)
        dispatch(resetDataSetToInital())
        dispatch(resetDeleteProperty())
          //dispatch(resetDataSetToInital())
         if(moduleName==='material-details'){              
            setCustomModuleName('material');
             setBreadCrumbData([{'title':'Home',path:'/'}, {'title':'Materials',path:'/materials', active:false }, {'title':'Details',path:'/materials-details', active:true }])
             dispatch(getMaterialPropertyDetai({"id" : id}));
             
            // api/detailspage/getMaterialPropertyDetai
         }
         else if(moduleName==='sam-details'){
            setCustomModuleName('sam');
          setBreadCrumbData([{'title':'Home',path:'/'}, {'title':'SAM',path:'/sam', active:false }, {'title':'Details',path:'/sam-details', active:true }])
          dispatch(getSamPropertyDetai({"id" : id}));
           
         }
         else if(moduleName==='fiber-details'){
            console.log("*********CCCCCCCCCCCCC*********", id)
            setCustomModuleName('fiber');
          setBreadCrumbData([{'title':'Home',path:'/'}, {'title':'Fibers',path:'/fibers', active:false }, {'title':'Details',path:'/fiber-details', active:true }])
          dispatch(getFiberPropertyDetai({"id" : id}));
          
         }
         else if(moduleName==='new-material'){
            setCustomModuleName('material');   
            setUrlId(id);
            setBreadCrumbData([{'title':'Home',path:'/'}, {'title':'Materials',path:'/materials', active:false }, {'title':'New Materials ',path:'/new-materials-details', active:true }]) 
            dispatch(getMaterialPropertyDetai({"id" : id}));
            
         }
         else if(moduleName==='new-sam'){
            setCustomModuleName('sam');
            setUrlId(id);
            setBreadCrumbData([{'title':'Home',path:'/'}, {'title':'SAM',path:'/sam', active:false }, {'title':'New Sam',path:'/new-sam-details', active:true }])
            dispatch(getSamPropertyDetai({"id" : id}));
             
             
           }
           else if(moduleName==='new-fiber'){
            setCustomModuleName('fiber');
            setUrlId(id);
            setBreadCrumbData([{'title':'Home',path:'/'}, {'title':'Fibers',path:'/fibers', active:false }, {'title':'New Fiber',path:'/new-fiber-details', active:true }])
            dispatch(getFiberPropertyDetai({"id" : id}));
            
           }

         //setModuleFieldDataSet({...defaultMaterialDataSet});
         //console.log("saveRecordEvent -urlParams -2-",urlParams)
 
    }, [moduleName, id]);   

    useEffect(() => {
        let newMaterialDataSet = cloneDeep(defaultMaterialDataSet.data);
        let tempCalulcatedFieldSet = getCalulcatedFieldSet(newMaterialDataSet)
        setFields(tempCalulcatedFieldSet);
        setModuleFieldDataSet(newMaterialDataSet)
    },[defaultMaterialDataSet])


    const getCalulcatedFieldSet =(dataSet)=>{
        let tempCalulcatedFieldsMetaData = {fields:{}, calc:{}}
        let loopModule = ['material', 'sam', 'fiber'];
         loopModule.map((module)=>{
                if(dataSet[module]!==undefined && dataSet[module]['propertyData']!==undefined && dataSet[module]['propertyData'].length>0){
                    dataSet[module]['propertyData'].map((item, index)=>{
                        Object.keys(item).map((key, propIndex)=>{
                            if(tempCalulcatedFieldsMetaData['fields'][key]!==undefined){
                                tempCalulcatedFieldsMetaData['fields'][key]['index'] = index;
                                tempCalulcatedFieldsMetaData['fields'][key]['propIndex'] = propIndex;
                                tempCalulcatedFieldsMetaData['fields'][key]['module'] = module;                                   
                            }
                            else{
                                tempCalulcatedFieldsMetaData['fields'][key]= {
                                    index: index, 
                                    propIndex:propIndex, 
                                    module:module,
                                    targetCalculatorField:[]
                                }
                            }
                              
                        if(item[key]['propertyMetaData']['calculatedField']===true && item[key]['propertyMetaData']['formulaUsed']!==null){
                            let targetCalculationColumn = JSON.parse(item[key]['propertyMetaData']['targetCalculationColumn']);
                            tempCalulcatedFieldsMetaData['calc'][key]= 
                                {
                                    index: index, 
                                    propIndex:propIndex, 
                                    module:module,
                                    formulaUsed: item[key]['propertyMetaData']['formulaUsed'],
                                    targetCalculationColumn:targetCalculationColumn,
                                }
                                if(targetCalculationColumn.length>0){
                                    //update the refrence to all the fileds that are involved in calulation
                                    targetCalculationColumn.map((item, indexCount)=>{
                                        //if field is not undefined, key exists in fields
                                        if(tempCalulcatedFieldsMetaData['fields'][item]!==undefined){
                                                tempCalulcatedFieldsMetaData['fields'][item]['targetCalculatorField'].push(key)
                                        }
                                        //create a key with unknown fileds as null, these nulled fields will be updated 
                                        //onces the key is encountered in the loop
                                        else{
                                            tempCalulcatedFieldsMetaData['fields'][item]= {
                                                index: null, 
                                                propIndex:null, 
                                                module:null,
                                                targetCalculatorField:[key]
                                            }    
                                        }        
                                    })     
                                }    

                               // tempCalulcatedFieldsMetaData['fields'][key]['targetCalculatorField'].push(key) 
                            // if((JSON.parse(item[key]['propertyMetaData']['targetCalculationColumn'])).length>0){
                            //     JSON.parse(item[key]['propertyMetaData']['targetCalculationColumn']).map((fieldKey, fieldIndex)=>{
                            //        // JSON.parse(item[key]['propertyMetaData']['targetCalculationColumn'])     
                            //     })
                            // }    

                        }
                        })          
                    })
                
                }
        })    
        return tempCalulcatedFieldsMetaData;
    }
    const renderDetailsComponent =()=>{
        if(customModuleName!==''){
            return(
                <Details 
                key={defaultKey} 
                breadCrumbData = {breadCrumbData} 
                moduleName={customModuleName} 
                defaultMaterialDataSet = {moduleFieldDataSet} 
                editStatusCustom={editStatus} 
                fields={fields} 
                urlId ={urlId}
                urlParams = {{'moduleName':moduleName, 'id':id}}
            /> 
            )
        }
        else{
            return(
                <div className="details-empty">
                    <img src={loader} className='details-preloader' />
                </div>
            )
        }
    }
    return (
       <div className="main-panel ps">
           {renderDetailsComponent()}
       </div>
    );
}

export default ViewDetails;