import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { deleteSourceFile, getSourceDataById } from "../../axios";
import { useDispatch } from "react-redux";
import loader from "../../loader.gif";
import "./index.css";

function DeleteSourceFileModal({
  isModalOpen,
  setIsModalOpen,
  selectedDeleteItem,
  refetchApi,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState("");
  const { sourceId } = useParams();

  const handleOnDelete = () => {
    let payload = {
      sourceFileID: [selectedDeleteItem?.sourceFileID],
    };
    setLoading(true);
    dispatch(deleteSourceFile(payload))
      .then((result) => {
        refetchApi();
        setLoading(false);
        setResponse(result);
      })
      .catch((err) => {
        setLoading(false);
        setResponse(err?.response);
      });
  };

  useEffect(() => {
    return () => {
      setLoading(false);
      setResponse("");
    };
  }, []);

  const RenderDeleteMsg = () => {
    return (
      <div className="deleteWanringMsg">
        <p>
          <span>Are you sure </span> you want to delete{" "}
          {selectedDeleteItem?.sourceFileName} <b></b>{" "}
        </p>
        <p>You can't undo this action.</p>
      </div>
    );
  };

  const RenderLoading = () => {
    return (
      <>
        <div className="fixedLoader">
          <img src={loader} className="loaderr" />
        </div>
      </>
    );
  };

  const RenderResponse = () => {
    return (
      <>
        {response?.status === 200 ? (
          <div ClassName="failedMsg">
            <div className="successMsg">
              <i className="now-ui-icons ui-1_check"></i>&nbsp; Success
            </div>
            <div className="successTxt">Source File Deleted Successfully</div>
          </div>
        ) : (
          <div>
            <div ClassName="failedMsg">
              <div className="failed">
                <i className="now-ui-icons ui-1_simple-remove"></i>&nbsp; Failed
              </div>
              <div className="failedTxt">
                {response?.data?.responseDesc || "Source File Delete Failed"}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const RenderFailed = () => {
    return <></>;
  };
  return (
    <Modal
      title={<h3>Delete Source File</h3>}
      visible={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        setLoading(false);
        setResponse("");
      }}
      onOk={() => {
        if (response) {
          setIsModalOpen(false);
          setLoading(false);
          setResponse("");
        } else {
          handleOnDelete();
        }
      }}
      okText={response ? "OK" : "Delete"}
      destroyOnClose
      width={800}
      confirmLoading={loading}
      // size={"large"}
    >
      {response ? <RenderResponse /> : <RenderDeleteMsg />}
      {/* {loading ? <RenderLoading /> : <RenderDeleteMsg />} */}
    </Modal>
  );
}

export default DeleteSourceFileModal;
