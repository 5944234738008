import React from "react";
import { useSelector } from "react-redux";

const withPermission = (
  Component,
  requiredPermissions,
  permissionsAlgorithm = "every"
) => {
  const ComponentParent = (props) => {
    // const permissions = useSelector(
    //   ({ settings }) => settings?.permissions || []
    // );
    const permissions = useSelector(({ userInfo }) => userInfo?.Roles || []);

    if (["every", "some"].indexOf(permissionsAlgorithm) === -1) {
      throw new Error("Only Every/some permissionsAlgorithm supported");
    }

    const hasPermission = requiredPermissions[permissionsAlgorithm]((r) =>
      permissions.includes(r)
    );

    return hasPermission ? <Component {...props} /> : null;
  };

  return ComponentParent;
};

export default withPermission;
