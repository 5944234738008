

import React, { useEffect, useState, useCallback } from "react";
import './index.css';
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import {updateSearchKey, updateUserInfo, updateUserModel, resetSearchKey, resetUserModel, updateLatestVersion, resetLatestVersion} from  '../../../axios/index'

function PageHeadeActionButtons(props) {  
  console.log('@@PPPPP________', props)
  const dispatch = useDispatch();
  const myModel = useSelector((state) => state.userModel);
  const latestVersion = useSelector((state) => state.latestVersion)
  const searchKey = useSelector((state)=> state.searchKey);
  const type = props.metaData.type ? props.metaData.type.toLowerCase() : props.metaData.type
  const [checked, setChecked] = React.useState(false);
  const [latestVersionChecked, setLatestVersionChecked] = React.useState(false);

  const metaData = {
    pageIcon:'now-ui-icons education_atom',
    pageTitle:'Manage Materials Models'
  }   

  const handleSearchKey = (e)=>{
    console.log('from e', e.target.value)
    let val = e.target.value;
    localStorage.setItem('searchKey', val)
    dispatch(updateSearchKey(val))
  }

  
  const handleMyModel = (e) => {
    const param = { isChecked: e.target.checked }
    dispatch(updateUserModel(param));
    setChecked(e.target.checked);
  }

  const handleLatestVersion = (e) => {
    const param = { isLatestVersionChecked: e.target.checked }
    dispatch(updateLatestVersion(param));
    setLatestVersionChecked(e.target.checked);
  }

  const handleClear = (e) => {
    dispatch(resetSearchKey());
    dispatch(resetUserModel());
    dispatch(resetLatestVersion());

  }

  return (
    


<div className="header-btn-grp">
  { (type == 'fiber' || type == 'material' || type == 'sam') &&
  <div className="d-flex">
    <div className="form-check">
        <input className="form-check-input" onChange={(e)=>handleLatestVersion(e)} checked = {latestVersion.isLatestVersionChecked} type="checkbox"  id="defaultCheck1" style={{visibility:'visible', opacity:1}} />
        <label className="form-check-label" for="defaultCheck1" style={{paddingLeft: '0px',    paddingRight: '73px' }}>
        Latest Versions
        </label>
    </div> 
    <div className="form-check">
        <input className="form-check-input" onChange={(e)=>handleMyModel(e)} checked = {myModel.isChecked}type="checkbox" id="defaultCheck2" style={{visibility:'visible', opacity:1}} />
        <label className="form-check-label" for="defaultCheck2" style={{paddingLeft: '0px',    paddingRight: '73px' }}>
        My  Models
        </label>
    </div> 
  </div>
}
{ (type == 'fiber' || type == 'material' || type == 'sam' || type == 'compression') &&

    <div className="form-group search-box" style={{marginRight:'10px'}}>
        <label for="">Search</label>
        <input type="email" value = {searchKey} className="form-control" id="" onChange={(e)=> handleSearchKey(e)} />
    </div> 
}
        {
          (searchKey || myModel.isChecked || latestVersion.isLatestVersionChecked) &&   <button onClick={() => handleClear()} type="button" className="btn btn-outline btnBlue" data-toggle="modal" data-target="#exampleModal">
         <i className="now-ui-icons  ui-1_simple-remove"></i>  Clear 


          </button>
        }
        {/* <button type="button" className="btn btn-outline btnBlue" data-toggle="modal" data-target="#exampleModal">
        <i className="now-ui-icons  ui-1_zoom-bold"></i>  &nbsp; &nbsp;  Advance Search
        </button> */}
        {(props.metaData.userRole == 'NL_ADMIN' || props.metaData.userRole == 'NL_EDIT') &&
        <button type="button" className="btn btn-outline-info"><Link to={`${props.metaData.path}/-1`} class="link1"><i class="now-ui-icons ui-1_simple-add"></i>  New {props.metaData.type}</Link></button>  
        }
</div>
  );
}

export default PageHeadeActionButtons;

 












