import cloneDeep from 'lodash/cloneDeep';

const intinalState = {
  loading: true,
  error: false,
  responseType: "",
  message: "",
  data:{ }
}



const SvgSelectedLayers = (
  state =  intinalState,
  action
) => {
  switch (action.type) {
    case "SVG_RESET_SELECTED_LAYERS":
      return intinalState;

    case "SVG_UPDATE_SELECTED_LAYERS":
      const  selectedlayers = cloneDeep(state)
      if(action.payload.keyboardKey==='ctrlKey'){
         selectedlayers.data[action.payload.key] = action.payload.data
      }
      else{
        selectedlayers.data={};
        selectedlayers.data[action.payload.key] = action.payload.data
     }

      return selectedlayers;
      case "SVG_DELETE_SELECTED_LAYERS":
        const  deleteLayers = cloneDeep(state)  
        if(action.payload.keyboardKey==='ctrlKey'){
          delete deleteLayers.data[action.payload.key]
        }  
       
        else{
          let templayer =  cloneDeep(deleteLayers.data[action.payload.key])
          if(Object.keys(deleteLayers.data).length>=2){
            deleteLayers.data={};
            deleteLayers.data[action.payload.key] =  templayer
          }
          else{
            deleteLayers.data={};
          }
          
        
         
        }     
        
        return deleteLayers;
        
    case "SVG_UPDATE_SELECTED_LAYERS_FAILED":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
        error: action.payload.data.responseData,
      };

    default:
      return state;
  }
};

export default SvgSelectedLayers;
