import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAssemblyByID, getConfigByID, resetSvgSelectedLayers, updateSvgSelectedLayers, deleteSvgSelectedLayer, updateSvgPartsMetaData,updateSvgParts } from '../../axios/index'

import "./index.css";
function Header(props) { 
  const dispatch = useDispatch();
  const svgSource = useSelector((state) => state.svgViewerData)
  const svgViewerPartData = useSelector((state) => state.svgViewerPartData)
  const updateConfigMetaData = (key, val) =>{
      dispatch(updateSvgPartsMetaData({key:key, val:val}))
  }

const renderHeaderBar= () =>{

  if(1>0){
    return(
      <>
          <small><span>Assembly </span> Id -</small> <b   style={{'paddingRight':'20px'}}>{svgSource?.data?.assemblyData?.metaData?.assemblyID}</b>		
          <small><span>Container  </span>  -</small> <b   style={{'paddingRight':'20px'}}>{svgSource?.data?.assemblyData?.metaData?.containerMetaData?.containerName}</b>								
          <small><span>Confiuration </span> Name - </small><b style={{'paddingRight':'20px'}}>                     
          <input type='text' value={svgViewerPartData?.data?.configData?.metaData?.configName} className='form-control edit-input' onChange={(e)=>{updateConfigMetaData('configName',e.target.value )}} /></b>
          <small><span></span> Description - </small><b style={{'paddingRight':'20px'}}>                     
          <input type='text' value={svgViewerPartData?.data?.configData?.metaData?.configDescription} className='form-control edit-input' onChange={(e)=>{updateConfigMetaData('configDescription',e.target.value )}} /></b>
        </> 
    )
  }  
}

  return (
    <div className="header-title-txt">
        {renderHeaderBar()}
    </div>
  );
};

export default Header;
