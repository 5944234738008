
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Link, useParams} from "react-router-dom";
import loader from '../../loader.gif';
import './index.css';
import { Button, Popover} from 'antd';
import { deleteProperty, getGraphData } from '../../axios/index'
import {userRoles} from '../utils/userRoles'
function ActionButtons(props) {  
  const dispatch = useDispatch();
  
  let urlParams = useParams();
  
  const [isPopover, setIsPopover] = useState(false);
  const userInfo = useSelector((state) => state.userInfo)
  const [userRole, setUserRole] = useState();
    
  useEffect(() => {
    if (userInfo.sAMAccountName) {
     // Roles = ['']
     setUserRole(userRoles(userInfo.Roles))
    }
    dispatch(getGraphData())

  }, [userInfo]);
  const handleDeletEvent = (status) => {
    return props.handleDeletEvent(status)
    //dispatch(deleteProperty({id: urlParams.id, type: props.moduleName}))
  }

  const handleEvent = (status)=>{
    return props.handleEditStatus(status)
    // console.log('moduleFieldDataSet', defaultDataSet)
    // let tempDataSet = {};
    // let loopModule = ['material', 'sam', 'fiber'];
    //  return loopModule.map((module,index)=>{
    //   if(defaultDataSet[module]!== undefined && defaultDataSet[module]['propertyData']){ 
    //     tempDataSet[module] = {...defaultDataSet[module]}

    //   }})
    // //let tempDataSet = {...defaultMaterialDataSet};
    // console.log("tempDataSet", tempDataSet)
    // setModuleFieldDataSet({...tempDataSet})  
    // return;
    //  //setModuleFieldDataSet({});
    // // setModuleFieldDataSet({...defaultMaterialDataSet.data});
   
    //  //handleUniqueColumnValues(defaultMaterialDataSet)
  }
  const handleSave = (e) =>{
    return props.handleSaveEvent(true)
  }
  const handleAdvancedSearchEvent =(e)=>{
    return props.handleAdvancedSearchStatus('search', true);
  }

  const handleGraphVisualizer = (e) => {
    return props.handleAdvancedSearchStatus('graph', true);

  }

  const handlePopoverChange = (event) => {
    setIsPopover(event);
  };

  const renderActionButtons = () =>{
  if(props.editStatus){
    return(
       <>
        {props.moduleName == 'material'  ? 
                <button type="button" className="btn btn-outline-info btn23"   data-target="#exampleModal2"  onClick={()=> handleGraphVisualizer(true)}><i className="now-ui-icons media-2_sound-wave"></i>&nbsp;Visualizer</button>
      : null}
        <button type="button" className="btn btn-outline-info btn23"   onClick={(e)=>handleAdvancedSearchEvent(e)}><i className="now-ui-icons  ui-1_zoom-bold"></i>&nbsp;Search & Import</button>
       <button type="button" className="btn btn-primary btn23 btn39"   data-target="#exampleModal2"  onClick = {(e)=>handleSave()}><i className="now-ui-icons arrows-1_cloud-download-93"></i>&nbsp;Save</button>
       <button type="button" className="btn btn-secondary btn23 btn39" data-toggle="modal" data-target="#exampleModal2"  onClick={(e)=> handleEvent(false)}><i className="now-ui-icons ui-1_simple-remove"></i> &nbsp; Cancel</button>
       </>
    )
  }
  else{
    return(
      <>
      {
        (userRole == 'NL_ADMIN' || userRole == 'NL_EDIT') &&
        <>
        {props.moduleName == 'material' && urlParams.id != -1 ? 
                <button type="button" className="btn btn-outline-info btn23"   data-target="#exampleModal2"  onClick={()=> handleGraphVisualizer(true)}><i className="now-ui-icons media-2_sound-wave"></i>&nbsp;Visualizer</button>
      : null}
        <button type="button" className="btn btn-outline-info btn23"   data-target="#exampleModal2"  onClick={(e)=> handleEvent(true)}><i className="now-ui-icons design-2_ruler-pencil"></i>&nbsp;Edit {props.moduleName == 'sam' ? 'SAM' : props.moduleName}</button>
        <button type="button" className="btn btn-outline-danger btn23"   data-target="#exampleModal2"  onClick={()=> handleDeletEvent(true)}><i className="now-ui-icons design-2_ruler-pencil"></i>&nbsp;Delete {props.moduleName == 'sam' ? 'SAM' : props.moduleName}</button>
     
</>
      }
      </>
    )
  }
}
const handleDownload = (type) => {
  var win = window.open(`${process.env.REACT_APP_BACKEND_API_URL}/api/download/material?moduleName=${props.moduleName}&downloadType=${type}&id=${urlParams.id}`, '_blank');
  win.focus();
}

const renderStatus=()=>{
  if(props.moduleName && props.moduleFieldDataSet[props.moduleName]!==undefined && props.moduleFieldDataSet[props.moduleName]['header']!==undefined){
    return(<div className="form-group search-box" style={{"marginRight":"8px"}}>
      <label for="" style={{"minWidth": "70px"}}>
        {props.moduleFieldDataSet[props.moduleName]['header']['status']}
      </label>
    </div> )  
  }  
}
const downloadPopover = () => {
  return (
    <>
      <Button onClick={(e) => handleDownload('json')} size={"default"} style={{ padding: '10px', margin: '5px', height: 'auto' }}>
        <p style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 500 }}>{`{ }`}</p>
        {/* <i className="now-ui-icons arrows-1_cloud-download-93"></i> */}
        <p style={{ marginBottom: '0px' }}>JSON</p>
      </Button>

      {props.moduleName == 'material' ?
      <Button onClick={(e) => handleDownload('xml')} size={"default"} style={{ padding: '10px', margin: '5px', height: 'auto' }}>
        <p style={{ marginBottom: '0px', fontSize: '14px', fontWeight: 500, letterSpacing: '2.5px' }}>{`</>`}</p>
        {/* <i className="now-ui-icons arrows-1_cloud-download-93"></i> */}
        <p style={{ marginBottom: '0px' }}>XML</p>
      </Button>
      : ""}
    </>
  )
}
  return (
    <div className="header-btn-grp">
    {renderStatus()}
    <Popover
      placement="bottom"
      content={downloadPopover}
      trigger="click"
      visible={isPopover}
      onVisibleChange={handlePopoverChange}
      arrowPointAtCenter
    >
      {(['material', 'sam', 'fiber'].includes(props.moduleName) && urlParams.id != -1 ) ?
      <button type="button" className="btn btn-outline-info btn23" data-toggle="modal" data-target="#exampleModal2"  >
        <i className="now-ui-icons arrows-1_cloud-download-93"></i>
        &nbsp;Download
      </button> : null}
    </Popover>
    {renderActionButtons()}
  </div>
 
  );
}

export default ActionButtons;
