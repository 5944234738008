import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import {
  uploadFiles,
  getUploadScreenElements,
  getFileUploadOptions,
} from "../../axios";
import { useDispatch, useSelector } from "react-redux";
import UploadFile from "../uploadFile";
import { isValidContainerName, haveSameFiletype } from "../utils/compareUtils";

import "./index.css";

function UploadFileModal({ isModalOpen, setIsModalOpen, refetchApi }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [allFields, setAllFields] = useState({});
  const [fileTypeError, setfileTypeError] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [uploadedFilesResponse, setUploadedFilesResponse] = useState({
    responseCount: 0,
    fileResponses: [],
  });
  const UploadOptions = useSelector((state) => state?.UploadFieldsOptions);

  const UploadFields = useSelector(
    (state) => state?.UploadFileData?.fields?.associatedValues
  );

  useEffect(() => {
    dispatch(getUploadScreenElements());
    dispatch(getFileUploadOptions());
  }, []);

  function checkFileType(latestFile) {
    let newFileType = latestFile?.path
      ? latestFile?.path?.split(".").pop()
      : ""; //Get the new file type

    const allContainer = {};
    UploadOptions?.data?.containerFileOptions.map((item) => {
      allContainer[item?.sourceContainerID] = item?.fileType;
    });

    if (
      allFields?.containerType === "existing" &&
      newFileType?.toLowerCase() ===
        allContainer[allFields?.containerName]?.toLowerCase()
    ) {
      return true;
    } else if (allFields?.containerType === "new") {
      return true;
    } else {
      return false;
    }
  }

  const handleOk = async () => {
    /////////////// Validations ////////////////

    // No File choose
    if (uploadedFiles.length <= 0) {
      setfileTypeError("Please choose any File to upload.");
      return;
    }

    // Check all files have same file types
    if (!haveSameFiletype(uploadedFiles?.map((item) => item?.path))) {
      setfileTypeError("Selected files have both dxf and stl filetype.");
      return;
    }

    // Check all mandatory files
    let mandatoryFieldMissing = false;
    UploadFields?.forEach((item) => {
      if (item?.mandatory && !allFields[item?.attributeName]) {
        setfileTypeError(`${item?.displayName} is mandatory.`);
        mandatoryFieldMissing = true;
        return;
      }
    });

    if (mandatoryFieldMissing) {
      return;
    }
    // Conatiner name not appropriate.
    if (
      allFields?.containerType === "new" &&
      !isValidContainerName(allFields["newContainerSelect"] || "")
    ) {
      setfileTypeError(`Invalid Container Name.`);
      return;
    }

    //Filetype is equal to uploaded file types
    if (!checkFileType(uploadedFiles[0])) {
      setfileTypeError(`Filetype mismatch with container file type.`);
      return;
    }
    // Formdata for upload
    const formData = new FormData();

    // uploadDetails contains all the details about upload like contrainer name, filetype, category etc.
    let uploadDetails = {
      ...allFields,
      containerName:
        allFields?.containerType === "new"
          ? allFields?.newContainerSelect.toLowerCase()
          : allFields?.containerName.toLowerCase(),
    };

    // Check the MetaData like remove dependencies on basis of existing or new container
    let removedDependenciesFiles = [];
    let fileMetaData =
      uploadedFilesResponse?.responseCount > 0
        ? uploadedFilesResponse?.fileResponses.filter((value) => {
            if (value?.dependency?.removeAllDependency) {
              removedDependenciesFiles.push(value?.originalname);
              return value;
            }
            return null;
          })
        : uploadedFiles.map((value) => {
            return {
              originalname: value.path,
              isDuplicate: null,
              dependency: {
                assemblyFileID: [],
                configFileID: [],
                removeAllDependency: null,
                isPublished: null,
              },
              status: null,
            };
          });

    // No file action chosen
    if (fileMetaData.length === 0) {
      setfileTypeError(`Files don't have any action to upload.`);
      return;
    }

    uploadedFiles.forEach((file, index) => {
      if (
        uploadedFilesResponse?.responseCount === 0 ||
        removedDependenciesFiles.includes(file.path)
      )
        formData.append(`file`, file);
    });
    // formData.append(`files`, uploadedFiles[0]);
    formData.append(`uploadDetails`, JSON.stringify(uploadDetails));
    formData.append(`fileMetaData`, JSON.stringify(fileMetaData));

    setIsLoading(true);
    const config = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        // console.log(`${loaded}kb of ${total}kb | ${percent}%`); // just to see whats happening in the console

        if (percent <= 95) {
          setPercentage(percent); // hook to set the value of current level that needs to be passed to the progressbar
        }
      },
    };

    dispatch(uploadFiles(formData, config))
      .then((result) => {
        let isUploadPass = result.data?.responseData.every(
          (item) => item.status === "Uploaded"
        );

        if (result?.status === 200 && isUploadPass) {
          setIsLoading(false);
          setIsModalOpen(false);
          setPercentage(100);
          refetchApi();
        } else {
          // When duplicates or something came up
          setIsLoading(false);
          setPercentage(100);
          setUploadedFilesResponse((prevState) => {
            return {
              responseCount: prevState?.responseCount + 1,
              fileResponses: result.data?.responseData,
            };
          });

          // console.log(result, "result");
          setfileTypeError(`Files Upload Failed`);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setPercentage(100);
        // setAllFields({
        //   ...allFields,
        //   filesResponse: result.data?.responseData,
        // });
        setfileTypeError(
          err?.response?.data?.responseDesc || err?.message || "Error in Upload"
        );
      });
  };

  return (
    <Modal
      title={<h3>Upload File</h3>}
      visible={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        setPercentage(0);
      }}
      onOk={handleOk}
      okText={"Upload Files"}
      destroyOnClose={true}
      width={1170}
      confirmLoading={isLoading}
    >
      <UploadFile
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        allFields={allFields}
        setAllFields={setAllFields}
        fileTypeError={fileTypeError}
        setfileTypeError={setfileTypeError}
        uploadedFilesResponse={uploadedFilesResponse}
        setUploadedFilesResponse={setUploadedFilesResponse}
      />
      {percentage > 0 && percentage < 100 && (
        <div class="progress mt-4">
          <div
            class="progress-bar"
            role="progressbar"
            style={{ width: `${percentage}%` }}
            aria-valuenow={percentage}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {percentage} %
          </div>
        </div>
      )}
    </Modal>
  );
}

export default UploadFileModal;
